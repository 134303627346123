import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BinDataService {
  private gridDataSource = new BehaviorSubject<any[]>([]); // This will hold the grid data
  gridData$ = this.gridDataSource.asObservable(); // Observable to be used in components

  constructor() {}

  addDataToGrid(newData: any) {
    // Get current data and add the new data
    const currentData = this.gridDataSource.value;
    this.gridDataSource.next([...currentData, newData]);
  }
}
