import { Component, OnInit, AfterViewInit, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
// import { ExportExcelService } from '../../services/export-excel.service';
import { Platform, } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { ModalController, AlertController } from '@ionic/angular';
// import { OperatorformComponent } from '../operator/operatorform/operatorform.component';
import { Plugins, FilesystemDirectory } from '@capacitor/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { TranslateService } from '@ngx-translate/core';
import { WmsRouteFormPage } from '../wms-route-form/wms-route-form.page';
import { languageInitializer } from 'src/environments/environment';
import { serverUrl } from "src/environments/environment";
import { ExportExcelService } from '../services/export-excel.service';
import { AddBinComponent } from '../components/bin-details/add-bin/add-bin.component';
@Component({
  selector: 'app-wms-route',
  templateUrl: './wms-route.page.html',
  styleUrls: ['./wms-route.page.scss'],
})
export class WmsRoutePage implements OnInit {
  @ViewChild("myGrid", { static: false }) myGrid: jqxGridComponent;
  directory = languageInitializer.directory
  selectedRow: any;
  constructor(private modalController: ModalController, private ete: ExportExcelService, private ajaxService: AjaxService, private commonService: CommonService, private alertController: AlertController,) { }
  head = [
    "Route Id",
    "Route Name",
    "Description",
    "Start Location",
    "Start Lat/Long",
    "End Location",
    "End Lat/Long",
  ];
  exportTitle = "Route report";
  obj = [];
  pdfdatas = [];
  renderer: (row: number, column: any, value: string) => string;
  source: any;
  dataAdapter: any;
  columns: any;
  ngOnInit() {
    this.getDatas();
  }
  getDatas() {
    const companyDetail = {
      companyId: "mobiserve",
      userName: "mobiserve-ca"
    };

    var url =
      serverUrl.web +
      `/site/getrouteid?companyId=${companyDetail.companyId}&userName=${companyDetail.userName}`;

    this.ajaxService.ajaxGet(url).subscribe((res) => {
      this.obj = res;
      var detail = res;
      this.pdfdatas = [];
      for (var i = 0; i < detail.length; i++) {
        this.pdfdatas.push([
          detail[i].routeId,
          detail[i].routeName,
          detail[i].description,
          detail[i].startLocation,
          detail[i].startLatLng,
          detail[i].endLocation,
          detail[i].endLatLng,
        ]);
      }


      this.renderer = (row: number, column: any, value: string) => {
        if (value == "" || null || undefined) {
          return "---";
        } else {
          return (
            '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto"  >' +
            value +
            "</span>"
          );
        }
      };

      this.source = { localdata: this.obj };
      this.dataAdapter = new jqx.dataAdapter(this.source);
      this.columns = [
        {
          text: "Route Id",
          datafield: "routeId",
          cellsrenderer: this.renderer,
          cellsalign: "center",
          align: "center",
          width: "8%",
        },
        {
          text: "Route Name",
          datafield: "routeName",
          cellsrenderer: this.renderer,
          cellsalign: "center",
          align: "center",
          width: "12%",
        },
        {
          text: "Description",
          datafield: "description",
          cellsrenderer: this.renderer,
          cellsalign: "center",
          align: "center",
          width: "12%",
        },
        {
          text: "Start Location",
          datafield: "startLocation",
          cellsrenderer: this.renderer,
          cellsalign: "center",
          align: "center",
          width: "14%",
        },
        {
          text: "Start Lat/Long",
          datafield: "startLatLng",
          cellsrenderer: this.renderer,
          cellsalign: "center",
          align: "center",
          width: "15%",
        },
        {
          text: "End Location",
          datafield: "endLocation",
          cellsrenderer: this.renderer,
          cellsalign: "center",
          align: "center",
          width: "14%",
        },
        {
          text: "End Lat/Long",
          datafield: "endLatLng",
          cellsrenderer: this.renderer,
          cellsalign: "center",
          align: "center",
          width: "15%",
        },
        {
          text: "Action",
          datafield: "action",
          columntype: "button",
          cellsalign: "center",
          align: "center",
          width: "10%",
          cellsrenderer: (): string => {
            return "Add Bin";
          },
          buttonclick: (row: number): void => {
            this.AddBinComponent();
          },
        },
      ];
    }
    );
  }
  async openRouteModel() {
    const modal = await this.modalController.create({
      component: WmsRouteFormPage,
      cssClass: "wms-route-css"
    });
    modal.onDidDismiss().then(() => {

      this.getDatas();
    })
    return await modal.present();
  }
  async editRouteModel() {
    if (this.selectedRow) {
      this.selectedRow["submit"] = "available";
      const modal = await this.modalController.create({
        component: WmsRouteFormPage,
        cssClass: "wms-route-css",

        componentProps: {
          value: this.selectedRow,
        }
      });
      modal.onDidDismiss().then(() => {
        this.myGrid.clearselection();
        this.selectedRow = "";
        this.getDatas();
      })
      return await modal.present();
    }
    else {
      this.commonService.presentToast("Please select a row to edit");
      return "";
    }
  }

  exportToExcel() {
    let reportData = {
      title: "Route report",
      data: this.pdfdatas,
      headers: this.head,
    };
    this.ete.exportExcel(reportData);
  }
  createPdf() {
    this.commonService.createPdf(
      this.head,
      this.pdfdatas,
      null,
      this.exportTitle,
      "",
      "Route report",
      null
    );
  }
  async deletebtn() {
    if (this.selectedRow) {
      const alert = await this.alertController.create({
        header: "Delete ",
        backdropDismiss: false,
        message: "Are you sure you want to delete?",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: (data) => { },
          },
          {
            text: "Ok",
            handler: (data) => {
              const url =
                serverUrl.web +
                "/site/deletewmsrtid?routeId=" +
                this.selectedRow.routeId;
              this.ajaxService.ajaxDeleteWithString(url).subscribe((res) => {
                if (res.message == "Deleted successfully") {
                  this.commonService.presentToast("Deleted successfully");
                  this.getDatas();
                  this.myGrid.clearselection();

                  this.selectedRow = "";
                } else {
                  this.commonService.presentToast("Try again");
                }
              });
            },
          },
        ],
      });
      await alert.present();
    } else {
      this.commonService.presentToast("Please select a row to delete");
    }
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   this.getDatas()
  //  }
  async AddBinComponent() {
    const modal = await this.modalController.create({
      component: AddBinComponent,
      cssClass: "bus-route-css",
      componentProps: {
        type: "edit",
        value: this.selectedRow,
      },
    });
    return await modal.present();
  }

  myGridOnRowSelect(event: any): void {
    this.selectedRow = event.args.row;
  }
  ngAfterViewInit() {
    this.getDatas();
    this.myGrid.showloadelement();
  }

}
