import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthMapService } from "../services/auth-map.service";
import { GoogleMapService } from "../services/google-map.service";
import { OpenlayerMapService } from "../services/openlayer-map.service";
import { AjaxService } from "../services/ajax.service";
import { CommonService } from "../services/common.service";
import { serverUrl } from 'src/environments/environment';
import { LatlongComponent } from "src/app/components/latlong/latlong.component";
import { WmsLatlongComponent } from '../wms-latlong/wms-latlong.component';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { AddBusStopComponent } from '../components/add-bus-stop/add-bus-stop.component';
import { BusStopComponent } from '../skt/route/bus-stop/bus-stop.component';
import { AddBinComponent } from '../components/bin-details/add-bin/add-bin.component';

@Component({
  selector: 'app-wms-route-form',
  templateUrl: './wms-route-form.page.html',
  styleUrls: ['./wms-route-form.page.scss'],
})
export class WmsRouteFormPage implements OnInit {
  @Input() value;
  editonly: boolean = false
  addonly: boolean = true
  onlyshowadd: boolean = false
  serviceName: any;
  routeForm: FormGroup;
  searchMaplatLng: any = "";
  map;
  mode: any;
  update: string;
  geoFenceForRef: any;
  // @Input() mode: string;
  range = 500;
  shape: string = "Circle";
  @ViewChild("mapElement", { static: false }) mapElement;
  selectValue = "Preferred";
  setTempDataRefBin = []
  constructor(private formBuilder: FormBuilder,
    private modalController: ModalController,
    public gMapService: GoogleMapService,
    public olMapService: OpenlayerMapService,
    public mapService: AuthMapService,
    public ajaxService: AjaxService,
    public commonService: CommonService,
    private geolocation: Geolocation,) {

    this.routeForm = this.formBuilder.group({
      routeName: ['', Validators.required],
      description: ['', Validators.required],
      startLocation: ['', Validators.required],
      startlatlng: ['', Validators.required],
      endLocation: ['', Validators.required],
      endlatlng: ['', Validators.required],
      routeId: [{ value: '', disabled: true }],
      // latlng: ['', Validators.required],

    });
    this.mapService = new OpenlayerMapService();
  }

  ngOnInit() {
    if (this.value)
      this.editForm();
  }
  onSubmit() {
    if (this.serviceName != "available") {
      var data = {
        routeName: this.routeForm.value.routeName,
        description: this.routeForm.value.description,
        startLatLang: this.routeForm.value.startlatlng,
        startLocation: this.routeForm.value.startLocation,
        endLocation: this.routeForm.value.endLocation,
        endLatLang: this.routeForm.value.endlatlng,
        userName: localStorage.getItem("userName"),
        companyId: localStorage.getItem("corpId")
      };
      Object.keys(data).forEach(
        (key) => (data[key] == null || data[key] == "") && delete data[key]
      );
      // data["tripId"] = this.value;
      const url = serverUrl.web + "/site/addwmsroute";
      this.ajaxService.ajaxPostMethod(url, data).subscribe((res) => {
        if (res.id && !isNaN(res.id)) {
          // const routeId = res.id;
          this.setTempDataRefBin.map((aa, i) => {
            this.setTempDataRefBin[i]["routeId"] = res.id
          });
          let secondApiData = { id: this.setTempDataRefBin }
          console.log(this.setTempDataRefBin, secondApiData)

          const url = serverUrl.web + "/site/addwmsbin";
          this.ajaxService.ajaxPostMethod(url, secondApiData).subscribe((res) => {
            if (res.message == "The Bin Added Successfull") {
              this.commonService.presentToast("Route and Bin Details Added successfully.");
              this.modalController.dismiss();
              // this.loadMark();
              // this.getLiveTrackIcon;
            } else {
              this.commonService.presentToast("The RFID is already used so create new Route & Bin Details")
              this.modalController.dismiss();
            }
          })
        } else {
          this.commonService.presentToast("Please check the values.");
          this.modalController.dismiss();
        }
      });
    } else if (this.serviceName == "available") {
      var datas = {
        routeName: this.routeForm.value.routeName,
        description: this.routeForm.value.description,
        startLatLang: this.routeForm.value.startlatlng,
        startLocation: this.routeForm.value.startLocation,
        endLocation: this.routeForm.value.endLocation,
        endLatLang: this.routeForm.value.endlatlng,
        routeId: this.value.routeId,
        userName: localStorage.getItem("userName"),
        companyId: localStorage.getItem("corpId")
      };

      Object.keys(datas).forEach(
        (key) => (datas[key] == null || datas[key] == "") && delete datas[key]
      );
      const url = serverUrl.web + "/site/editwmsrtid";
      this.ajaxService.ajaxPutMethod(url, datas).subscribe((res) => {
        if (JSON.parse(res).message == "Update Successfully") {
          this.commonService.presentToast("Updated succesfully");
          this.routeForm.reset();
          this.modalController.dismiss();
        } else {
          this.commonService.presentToast("Try again later.");
        }
      });
    }
  }

  editForm() {
    // this.addshow = false;
    this.editonly = true;
    this.addonly = false;
    if (this.value.submit == "available") {
      this.serviceName = "available";
      // this.show = true;
      // this.getVehiclename(this.value.trip);
      this.routeForm.patchValue({
        routeName: this.value.routeName,
        description: this.value.description,
        startlatlng: this.value.startLatLng,
        startLocation: this.value.startLocation,
        endLocation: this.value.endLocation,
        endlatlng: this.value.endLatLng,
        routeId: this.value.routeId

      });
    }
  }
  
  async closeModal() {
    this.modalController.dismiss();
  }



  currentLocation(mode) {
    this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        this.routeForm.patchValue({
          startlatlng: resp.coords.latitude + "," + resp.coords.longitude,
          endtlatlng: resp.coords.latitude + "," + resp.coords.longitude,
        });
      })
      .catch((error) => { });
  }
  async startmapLocation(mode) {
    const modal = await this.modalController.create({
      component: WmsLatlongComponent,
      componentProps: {
        currentLocation:
          this.routeForm.value.startlatlng != undefined ||
            (this.routeForm.value.startlatlng != undefined) != null
            ? this.routeForm.value.startlatlng != undefined
            : "",
      },
    });
    modal.onDidDismiss().then(() => this.mapReturnFunction(mode));
    return await modal.present();
  }
  async endmapLocation(mode) {
    const modal = await this.modalController.create({
      component: WmsLatlongComponent,
      componentProps: {
        currentLocation:
          this.routeForm.value.endlatlng != undefined ||
            (this.routeForm.value.endlatlng != undefined) != null
            ? this.routeForm.value.endlatlng != undefined
            : "",
      },
    });
    modal.onDidDismiss().then(() => this.emapReturnFunction(mode));
    return await modal.present();
  }
  mapReturnFunction(mode) {
    this.routeForm.patchValue({
      startlatlng: localStorage.getItem("mapLocationPicker"),
    });
    this.geoFenceForRef = localStorage.latLongPickerGeoFence;
  }
  emapReturnFunction(mode) {
    this.routeForm.patchValue({
      endlatlng: localStorage.getItem("mapLocationPicker"),
    });
    this.geoFenceForRef = localStorage.latLongPickerGeoFence;
  }
  async openBin() {
    const modal = await this.modalController.create({
      component: AddBinComponent,
      cssClass: "bus-route-css",
      componentProps: { type: "Add", setTempDataRefBin: this.setTempDataRefBin }
    });
    modal.onDidDismiss().then((current) => {
      if (current.data.type == "Add") {
        this.setTempDataRefBin = current.data.setTempDataRefBin;
      }
    })
    return await modal.present();
  }

  checkValidation() {
    if (!this.value) {
      if (this.routeForm.valid && this.setTempDataRefBin.length != 0) return false;
      return true;
    }
    return !this.routeForm.valid
  }

}
