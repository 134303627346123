import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import { app, serverUrl } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-userform',
  templateUrl: './userform.component.html',
  styleUrls: ['./userform.component.scss'],
})
export class UserformComponent implements OnInit {
  @Input() myGrid;
  @Input() value;
  roleList = ["Fleet Manager", "Vehicle Admin"]
  selectedData = {
    "Fleet Manager": "Fleet Manager",
    "Vehicle Admin": "Vehicle Admin",
    "VehicleAdmin": "Vehicle Admin",
    "FleetManager": "Fleet Manager"
  }
  levelPriority = ["Level1", "Level2", "Level3"]
  userForm: FormGroup;
  countries: any;
  titleCheck = "User"
  editCountry = 'Select country';
  editrole = "Select role"
  companyDetail: { branchID: string; companyID: string; userId: string; };
  serviceName: string;
  selectedRow: any;
  selectedRowIdx: any;
  countryCode: any;
  hideSerialNo = false;
  isDeleteShow: any = false;
  check: boolean = false;
  entryPoint: any;

  editedDataArray: any = [];
  password_type: string = 'password';
  password: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private modalController: ModalController,

    private ajaxService: AjaxService,
    private commonService: CommonService,
    private alertController: AlertController,
    private router: Router,
    private trans: TranslateService
  ) { }

  createForm() {
    if (this.value) {
      this.userForm = this.formBuilder.group({
        userName: ['', Validators.required],
        password: [''],
        contact: ['', Validators.required],
        firstName: ['', Validators.required],
        email: ['', Validators.required],
        Address1: ['', Validators.required],
        Address2: ['', Validators.required],
        city: ['', Validators.required],
        //  email: ['',],
        roleType: ["", Validators.required],
        userExpire: ["", Validators.required],
        levelPriorityForm: [""],
        country: ['', Validators.required],

      });
    } else {
      this.userForm = this.formBuilder.group({
        userName: ['', Validators.required],
        password: ['', Validators.required],
        contact: ['', Validators.required],
        firstName: ['', Validators.required],
        email: ['', Validators.required],
        Address1: ['', Validators.required],
        Address2: ['', Validators.required],
        city: ['', Validators.required],
        //  email: ['',],
        roleType: ["", Validators.required],
        userExpire: ["", Validators.required],
        levelPriorityForm: [""],
        country: ['', Validators.required],

      });
    }

  }
  checkMail() {
    if (this.commonService.mailValidation.test(this.userForm.value.email)) {
      var jsonMails = {};
      jsonMails["email1"] = this.userForm.value.email;
      jsonMails["data"] = localStorage.corpId;
      let serviceData = JSON.stringify(jsonMails);

      let url: string = serverUrl.web + "/alert/emailvalidation/" + serviceData;
      this.commonService.presentLoader();
      this.ajaxService.ajaxGetWithString(url)
        .subscribe(res => {
          this.commonService.dismissLoader();
          var json = JSON.parse(res);
          if (!Object.values(json).includes(false)) {
            this.check = true;
            this.submit()
          }
          else
            this.commonService.presentToast(this.commonService.mailerror)
        })
    } else {
      this.commonService.presentToast(this.commonService.mailerror)
      this.check = false;
      return "";
    }
  }


  submit() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = (today.getFullYear() + 1)

    var date = yyyy + '-' + mm + '-' + dd;
    const userCountry = "SA"
    if (this.serviceName != 'available' && this.check) {
      const data = {
        "userName": this.userForm.value.userName.trim().toLowerCase(),
        "password": this.userForm.value.password.trim(),
        "firstName": this.userForm.value.firstName.trim(),
        "categoryrole": this.userForm.value.roleType.replace(/ /g, ""),
        "address1": this.userForm.value.Address1.trim(),
        "address2": this.userForm.value.Address2.trim(),
        "userExpiryDate": this.userForm.value.userExpire,
        "userCity": this.userForm.value.city.trim(),
        "contactNo": this.userForm.value.contact.trim().replace(/ /g, ""),
        "userCountry": userCountry,
        "emailId": this.userForm.value.email,
        "userPrivileges": "Level1",
        "companyID": this.companyDetail.companyID,
        "branchID": this.companyDetail.branchID,
        "applicationType": "false"
      };

      const url = serverUrl.web + '/UserDetailsControll/insertNewUser'
      this.ajaxService.ajaxPostMethod(url, data).subscribe(res => {
        console.log(res);
        if (res.status == "persisted") {
          this.commonService.presentToast('User added succesfully');
          this.modalController.dismiss()
          this.userForm.reset();
          this.modalController.dismiss();
        } else {
          this.commonService.presentToast('User already added');
          // this.userForm.reset();
          // this.modalController.dismiss();
        }

      })
    } else {
      if (this.check) {
        var details
        if (this.editedDataArray.includes("userPasswd") && this.userForm.value.password.trim() != '') {
          details = {
            "userName": this.userForm.value.userName.trim().toLowerCase(),
            "password": this.userForm.value.password.trim(),
            "firstName": this.userForm.value.firstName.trim(),
            "categoryrole": this.userForm.value.roleType.replace(/ /g, ""),//
            "address1": this.userForm.value.Address1.trim(),
            "address2": this.userForm.value.Address2.trim(),
            "userExpiryDate": date,
            "userCity": this.userForm.value.city.trim(),
            "contactNo": this.userForm.value.contact.trim().replace(/ /g, ""),
            "userCountry": userCountry,
            "emailId": this.userForm.value.email,
            "userPrivileges": "Level1",
            "companyID": this.value.companyId,
            "branchID": this.value.branchId,
            "applicationType": "false",
            "oldCategoryRole": this.value.roleName,
            "passwordChange": this.editedDataArray.includes("userPasswd"),
            "userId": localStorage.getItem("userName")
          }
        } else {
          details = {
            "userName": this.userForm.value.userName.trim().toLowerCase(),
            //"password": this.userForm.value.password.trim(),
            "firstName": this.userForm.value.firstName.trim(),
            "categoryrole": this.userForm.value.roleType.replace(/ /g, ""),//
            "address1": this.userForm.value.Address1.trim(),
            "address2": this.userForm.value.Address2.trim(),
            "userExpiryDate": date,
            "userCity": this.userForm.value.city.trim(),
            "contactNo": this.userForm.value.contact.trim().replace(/ /g, ""),
            "userCountry": userCountry,
            "emailId": this.userForm.value.email,
            "userPrivileges": "Level1",
            "companyID": this.value.companyId,
            "branchID": this.value.branchId,
            "applicationType": "false",
            "oldCategoryRole": this.value.roleName,
            "passwordChange": this.editedDataArray.includes("userPasswd"),
            "userId": localStorage.getItem("userName")
          }
        }
        details["oldValue"] = JSON.stringify(this.commonService.userAdminControlEditDelete(this.editedDataArray, this.value, {}));
        const url = serverUrl.web + '/UserDetailsControll/editNewUser'
        this.ajaxService.ajaxPostWithString(url, details).subscribe(res => {
          console.log(res);
          var count = 0;
          if (count === 0) {
            count = 1;
            if (res.toString() === "{}") {
              this.commonService.presentToast('successfully updated');
              this.userForm.reset();
              this.modalController.dismiss();
            } else {
              this.commonService.presentToast('Contact support team');
              this.userForm.reset();
              this.modalController.dismiss();
            }
            // else if (res === "o") {
            //   this.commonService.presentToast('User is already associated');
            //   this.userForm.reset();
            //   this.modalController.dismiss();
            // }
          }
          // if(res == ''){
          //   this.commonService.presentToast('Updated Succesfully');
          //   this.userForm.reset();
          //   this.modalController.dismiss();
          // }else {
          //   this.commonService.presentToast('User is already Associated');
          //   this.userForm.reset();
          //   this.modalController.dismiss();

          // }
        })
      } else {
        this.commonService.presentToast(this.commonService.mailerror)
        this.check = false;
        return "";
      }
    }
  }

  getCountries() {
    const url = serverUrl.web + '/login/getPreferences?key=Countries&companyId=' + this.companyDetail.companyID;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.countries = res;
        console.log(res);
      })

  }
  getCountryCode() {
    const url = serverUrl.web + '/login/getPreferences?key=CountryCode&companyId=' + this.companyDetail.companyID;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.countryCode = res;
        console.log(res);
      })

  }
  async closeModal() {
    this.modalController.dismiss();
  }
  async deleteSelectedOperator() {
    console.log(this.value)
    if (this.value) {
      const alert = await this.alertController.create({
        header: this.trans.instant('delete'),
        backdropDismiss: false,
        message: this.trans.instant("Are you sure you want to delete?"),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: this.trans.instant('Ok'),
          handler: data => {
            var details = {
              "companyID": this.value.companyId,
              "branchID": this.value.branchId,
              "userName": this.value.userName,
              "categoryrole": this.value.roleName  //
            };

            console.log(details)
            const url = serverUrl.web + '/UserDetailsControll/deleteUser';

            this.ajaxService.ajaxDeleteWithBody(url, details).subscribe(res => {
              console.log(res);

              if (res.status == "deleted") {
                this.modalController.dismiss();
                this.commonService.presentToast("Deleted successfully")
              } else {
                this.commonService.presentToast("Try again")
              }
            })
          }
        }]
      });
      await alert.present();

    }

  }

  editSaveData(data) {

    this.editedDataArray = this.commonService.userAdminEditArray("edit", data, this.editedDataArray)
  }


  ngOnInit() {
    this.companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    this.entryPoint = app.entryPoint;

    let localMainMenu = JSON.parse(localStorage.mainMenu)
    this.isDeleteShow = localMainMenu.includes("Delete")

    if (app.entryPoint == "WFT")
      this.titleCheck = "Supervisor";
    this.getCountries();
    this.getCountryCode();
    this.createForm();
    if (this.value) {
      if (this.value.submit == "available") {
        this.hideSerialNo = true;
        this.serviceName = "available";
        // this.vin =this.value.vin;
        this.editCountry = this.value.countryName;
        this.userForm.patchValue({
          userName: this.value.email,
          // password: this.value.pwd,
          contact: this.value.contact,
          firstName: this.value.userName,
          lastName: this.value.userName,
          email: this.value.fax,
          country: this.value.countryName,
          Address1: this.value.addressLine1,
          Address2: this.value.addressLine2,
          city: this.value.addressCity,
          roleType: this.selectedData[this.value.roleName]
        });
      }
    }

    setTimeout(() => {
      this.editedDataArray = []
    }, 2000)

  }
}