import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { ExportExcelService } from '../../services/export-excel.service';
import { Platform } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { app, languageInitializer, serverUrl } from '../../../environments/environment';
import { ModalController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserformComponent } from 'src/app/manage-fleet/users/userform/userform.component';
import { AddFeatureComponent } from 'src/app/manage-fleet/add-feature/add-feature.component';
import { Contractor1Component } from './contractor1/contractor1.component';
@Component({
  selector: 'app-contractor',
  templateUrl: './contractor.component.html',
  styleUrls: ['./contractor.component.scss'],
})
export class ContractorComponent implements OnInit {
  companyDetail: { branchID: string; companyID: string; userId: string; };
  renderer;
  columns;
  directory = languageInitializer.directory
  // columns: ({ text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; } | { text: string; datafield: string; cellsrenderer?: undefined; })[];
  app: any = { logo: 'logo.png' };
  source: { localdata: any; };
  dataAdapter: any;
  selectedRowIdx: any;
  selectedRow: any;
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  detail: any;
  newDetail: any;
  isDeleteShow: any = false;
  localizationobj: {};
  pagesizeoption = ["5", "10", "13", "15", "20"];
  selectedCell: any;
  // renderer: (row: number, column: any, value: string) => string;
  constructor(
    private modalController: ModalController,
    private ete: ExportExcelService,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    public platform: Platform,
    private alertController: AlertController,
    private trans: TranslateService
  ) { }
  myGridOnRowSelect(event: any): void {
    this.selectedRow = event.args.row;
    //  event.isTrigger = false
    this.selectedRowIdx = event.args.rowindex;

  }


  async openFeatureModel() {
    const modal = await this.modalController.create({
      component: AddFeatureComponent,
      cssClass: this.directory != 'rtl' ? 'user_feature' : 'user_feature-rtl',
      componentProps: {
        value: this.selectedRow,
        page: "manage"
      }
    });
    modal.onDidDismiss().then(() => {
      this.myGrid.clearselection();
      this.getDatas();
    })
    return await modal.present();
  }

  async suspend() {
    console.log(this.selectedRow);
    console.log(this.selectedCell);

    const alert: any = await this.alertController.create({
      header: ` Are you sure ? `,
      message: `You want to ${(this.selectedCell.additionalInfo == 'false') ? "Suspend" : "Unsuspend"} the User (${this.selectedCell.email})`,
      backdropDismiss: false,
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        handler: data => {
        }
      }, {
        text: 'Ok',
        handler: data => {
          this.commonService.presentLoader()
          const url = serverUrl.web + "/global/getSuspendedCompanys";
          //if (data == 'Suspend' || data == 'UnSuspend') {
          const datas = {
            "companyId": this.selectedCell.companyId,
            "Suspended": this.selectedCell.additionalInfo == "true" ? false : true,
            "isCompany": false,
            "userId": this.selectedCell.email
          }
          this.ajaxService.ajaxPostMethod(url, datas)
            .subscribe(res => {

              //this.commonService.dismissLoader()
              this.getDatas()
              this.commonService.presentToast(`The User ${this.selectedCell.email} has been ${res.error.text}`);
              this.myGrid.clearselection();
              this.selectedCell.additionalInfo = this.selectedCell.additionalInfo == "true" ? 'false' : 'true';
            })
          // }
          // else {
          // this.commonService.presentToast("Please Select the  Option")
          //}
        }
      }]
    });
    await alert.present();
  }




  async openModel() {
    const modal = await this.modalController.create({
      component: Contractor1Component,
      cssClass: this.directory != 'rtl' ? 'custom-modal' : 'custom-modal-rtl'
    });
    modal.onDidDismiss().then(() => {
      this.myGrid.clearselection();

      this.getDatas();
    })
    return await modal.present();
  }
  async deleteMode() {
    if (this.selectedRow) {
      const alert = await this.alertController.create({
        header: this.trans.instant('Delete'),
        backdropDismiss: false,
        message: this.trans.instant("Are you sure you want to delete?"),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: this.trans.instant('Ok'),
          handler: data => {
            var details = {
              "companyId": this.selectedRow.companyId,
              "branchId": this.selectedRow.branchId,
              "userName": this.selectedRow.email,
              "roleName": this.selectedRow.roleName,
              "macIp": "",
              "userId": localStorage.getItem("Contractor")
            }
            console.log(details)
            const url = serverUrl.web + '/user/delete/user';

            this.ajaxService.ajaxDeleteWithBody(url, details).subscribe(res => {
              console.log(res);

              if (res.statusText == "OK") {
                this.commonService.presentToast("Deleted successfully")
                this.myGrid.clearselection();
                this.getDatas();
              } else {
                this.commonService.presentToast("Try again")
              }
            })
          }
        }]
      });
      await alert.present();

    }
    else {
      this.commonService.presentToast('Please select a row to delete');
      return "";

    }

  }





  ngAfterViewInit() {
    this.myGrid.showloadelement();
    this.getDatas();
  }


  onCellclick(event) {
    this.selectedCell = event.args.row.bounddata;
    if (this.selectedRow == undefined)
      this.selectedRow = event.args.row.bounddata;

    let suspend = event.args.column.text
    if (suspend == "Suspend") {
      this.suspend();
    }

  }


  ngOnInit() {
    this.commonService.presentLoader()
    this.companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    let localMainMenu = JSON.parse(localStorage.mainMenu)
    this.isDeleteShow = localMainMenu.includes("Delete")

    //Grid Localization
    this.localizationobj = {
      pagergotopagestring: this.trans.instant("Go to page"),
      pagershowrowsstring: this.trans.instant("Show rows"),
      sortascendingstring: this.trans.instant("Sort Ascending"),
      sortdescendingstring: this.trans.instant("Sort Descending"),
      sortremovestring: this.trans.instant("Remove Sort")
    }


  }
  async editMode(data) {
    if (this.selectedRow) {
      this.selectedRow["submit"] = "available";
      const modal = await this.modalController.create({
        component: Contractor1Component,
        cssClass: this.directory != 'rtl' ? 'custom-modal' : 'custom-modal-rtl',
        componentProps: {
          value: this.selectedRow,
        }
      });
      modal.onDidDismiss().then(() => {
        this.myGrid.clearselection();
        this.selectedRow = "";
        this.getDatas();
      })

      return await modal.present();

    }
    else {
      this.commonService.presentToast('Please select a row to edit');
      return "";

    }

  }

  getDatas() {
    var datas1 = { "companyID": JSON.parse(localStorage.loginData)[1].companyId, "branchID": JSON.parse(localStorage.loginData)[1].companyId, "userID": JSON.parse(localStorage.loginData)[1].branchId }
    var url2 = serverUrl.web + '/UserDetailsControll/getAllUserdetails';
    this.ajaxService.ajaxPostWithBody(url2, datas1).subscribe(res => {
      console.log(res);
      var detail = res.status;

      this.renderer = (row: number, column: any, value: string,) => {
        if (value == "" || null || undefined) {
          return "----"
        }
        else {
          return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
        }
      }
      this.commonService.dismissLoader();
      this.source = { localdata: detail };
      this.dataAdapter = new jqx.dataAdapter(this.source);
      let name = "Contractor"
      if (app.entryPoint == "WFT")
        name = "Supervisor"
      this.columns = [
        { text: this.trans.instant(name + ''), datafield: 'email', cellsrenderer: this.renderer },
        // {text :'Operator',datafield:'operatorName',cellsrenderer:this.renderer},
        //{ text: this.trans.instant('Password'), datafield: 'pwd', cellsrenderer: this.renderer },
        { text: this.trans.instant('Role'), datafield: 'roleName', cellsrenderer: this.renderer },
        { text: this.trans.instant('Full Name'), datafield: 'userName', cellsrenderer: this.renderer },
        // {text :'Last Name',datafield:'',cellsrenderer:this.renderer},
        { text: this.trans.instant('Address Line 1'), datafield: 'addressLine1', cellsrenderer: this.renderer },
        //{text :'Address Line 2',datafield:'addressLine2',cellsrenderer:this.renderer},
        { text: this.trans.instant('Email Address'), datafield: 'fax', cellsrenderer: this.renderer },
        { text: this.trans.instant(name + ' City'), datafield: 'addressCity', cellsrenderer: this.renderer },
        { text: this.trans.instant('Country'), datafield: 'countryName', cellsrenderer: this.renderer },
        { text: this.trans.instant('Contact Number'), datafield: 'contact', cellsrenderer: this.renderer },
        {
          text: this.trans.instant('Feature'), datafield: 'Feature', columntype: 'button',
          cellsrenderer: (): string => {
            // this.openModel() ;
            return this.trans.instant('Feature');
          },
          buttonclick: (row: number): void => {
            this.openFeatureModel();
          }
        },
        {
          text: 'Suspend',
          datafield: 'additionalInfo',
          columntype: 'button',
          cellsrenderer: (row: number): any => {
            if (res.status[row] != undefined) {
              let additionalInfoArray = res.status[row].additionalInfo;
              let suspend = additionalInfoArray == "true"
                ? `<img style="right: 27%;position: relative;width: 21px;bottom: 2px;" src="assets/lock-icon.png" alt="Lock" />`
                : `<img style="left: 5%;position: relative;width: 27px;bottom: 6px;" src="assets/unlock-icon.png" alt="Unlock" />`;

              return suspend;
            }
          }

        }
      ]
      this.myGrid.pagesizeoptions(this.pagesizeoption)
      this.myGrid.updatebounddata();
      this.myGrid.unselectrow;
    })



    this.app["logo"] = localStorage.companyLogo;

  }
}