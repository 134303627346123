import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { app, serverUrl, storageVariable } from 'src/environments/environment';
@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.page.html',
  styleUrls: ['./grid-filter.page.scss'],
})
export class GridFilterPage implements OnInit {
  @ViewChild('myTree', { static: false }) myTree: jqxTreeComponent;
  @Output() filterReturn = new EventEmitter();
  @Input() selectedRow;
  @Input() multiRow;
  dataAdapter: any;
  records: any;
  subscription: any;
  geoJsonId: any;
  Search: any;
  strogefleetmanager: any;
  map;
  geofenceGridForm = [];
  plateNo = [];
  storageVar = [];
  Operators: any = [];
  sendOperator: any = [];
  sendvehicleStatus: any = [];
  sendemployeeStatus: any = [];
  sendvehicleType: any = [];
  sendemployeeType: any = [];
  sendvehicleHeight: any = [];
  sendfleetManager: any = [];
  vehicleStatus: any = [];
  employeeStatus: any = [];
  vehicleType: any = [];
  employeeType: any = [];
  vehicleHeight: any = [];
  fleetManager: any = [];
  vinandfleetData: any = [];
  treeStructureObject: any = {};
  treeGeofenceObject: any = {};
  // dashboardJson: any[] = Object.values(storageVariable.dashboardData.liveDatas);
  dashboardJson = [];
  geofenceList = {}
  filterData: any = {};
  treeStructureJson = [];
  source = {
    datatype: 'json',
    datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
    id: 'id',
    localdata: this.treeStructureJson
  };
  entryPoint: any;
  nodata: boolean = false;
  constructor(
    public ajaxService: AjaxService,
    private commonService: CommonService,
    private modelController: ModalController,
    private platform: Platform
  ) { }
  disableTreeDrag() {
    if (this.myTree) {
      this.myTree.allowDrag(false);
    }
  }
  ngAfterViewInit(): void {
    this.myTree.expandAll();
    setTimeout(() => {
      this.disableTreeDrag();
    }, 1000);
  }

  onChangeOperator($event) {
    this.sendOperator = [];
    let Operators = $event.target.value;
    if (Operators)
      for (var i in Operators) {
        this.sendOperator.push(Operators[i])
      }
  }
  onChangevehicleStatus($event) {
    this.sendvehicleStatus = [];
    let status = $event.target.value;
    if (status) {
      for (let i in status) {
        let mappedStatus = this.mapStatus(status[i]);
        if (mappedStatus) {
          this.sendvehicleStatus.push(mappedStatus);
        }
      }
    }
  }
  mapStatus(status) {
    switch (status) {
      case "Yet to transmit":
        return "Yet_to_transmit";
      case "GSM Lost":
        return "GSMLost";
      case "Power Cut":
        return "PowerCut";
      default:
        return status;
    }
  }

  onChangeemployeeStatus($event) {
    this.sendemployeeStatus = [];
    let status = $event.target.value;
    if (status)
      for (var i in status) {
        this.sendemployeeStatus.push(status[i])
      }
  }
  onChangevehicleType($event) {
    this.sendvehicleType = [];
    let type = $event.target.value;
    if (type)
      for (var i in type) {
        this.sendvehicleType.push(type[i])
      }
  }

  onChangeemployeeType($event) {
    this.sendemployeeType = [];
    let type = $event.target.value;
    if (type)
      for (var i in type) {
        this.sendemployeeType.push(type[i])
      }
  }
  onChangevehicleHeight($event) {
    this.sendvehicleHeight = [];
    let height = $event.target.value;
    if (height)
      for (var i in height) {
        this.sendvehicleHeight.push(height[i])
      }
  }

  onChangefleetManager($event) {
    this.sendfleetManager = [];
    let fleetuser = $event.target.value;
    if (fleetuser)
      for (var i in fleetuser) {
        this.sendfleetManager.push(fleetuser[i])
      }
  }




  // renderPlateNo() {
  //   this.geoJsonId = 1;

  //   for (let i = 0; i < this.dashboardJson.length; i++) {
  //     let groupArray: any = [];
  //     groupArray = this.dashboardJson[i].group == null || this.dashboardJson[i].group == undefined ? ["None group"] : this.dashboardJson[i].group.split(",")
  //     let filterTextFormat = ""
  //     for (let j = 0; j < groupArray.length; j++) {
  //       filterTextFormat += groupArray[j]
  //       if (this.filterData[filterTextFormat] == undefined) {
  //         this.filterData[filterTextFormat] = this.geoJsonId;
  //         this.geoJsonId++;
  //       }
  //       if (groupArray.length - 1 != j)
  //         filterTextFormat += ","
  //     }
  //     groupArray = this.dashboardJson[i].group == null ? "None group" : this.dashboardJson[i].group

  //     this.treeStructureObject[this.dashboardJson[i].plateNo] = {
  //       id: this.geoJsonId.toString(),
  //       parentid: this.filterData[groupArray].toString(),
  //       text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
  //       value: this.dashboardJson[i].vin
  //     }
  //     this.treeStructureJson.push({
  //       id: this.geoJsonId.toString(),
  //       parentid: this.filterData[groupArray].toString(),
  //       text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
  //       value: this.dashboardJson[i].vin
  //     })
  //     this.geoJsonId++;
  //   }
  //   for (let i = 0; i < Object.keys(this.filterData).length; i++) {
  //     let loopedArry: any = Object.keys(this.filterData)[i].split(",").length - 1;
  //     loopedArry = Object.keys(this.filterData)[i].split(",")[loopedArry]
  //     var popData = Object.keys(this.filterData)[i].split(",")
  //     popData.pop()

  //     this.treeStructureObject[loopedArry] = {
  //       id: Object.values(this.filterData)[i].toString(),
  //       parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
  //       text: loopedArry,
  //       value: loopedArry
  //     }
  //     this.treeStructureJson.push({
  //       id: Object.values(this.filterData)[i].toString(),
  //       parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
  //       text: loopedArry,
  //       value: loopedArry
  //     })
  //   }
  //   this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
  //   this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
  // }

  renderPlateNo() {
    this.treeStructureJson.push({
      id: -1,
      parentid: null,
      text: "Select all",
      value: "Select all",
    });

    const filterDataMap = new Map<string, number>();
    filterDataMap.set("Select all", -1);

    let grpJsonId = 1;

    for (const dashboardItem of this.dashboardJson) {
      const groupArray = dashboardItem.group
        ? dashboardItem.group.split(",")
        : ["NoneGroup"];

      const filterTextFormat = groupArray.join(",");
      if (!filterDataMap.has(filterTextFormat)) {
        filterDataMap.set(filterTextFormat, grpJsonId);
        grpJsonId++;
      }

      const plateNoText =
        dashboardItem.plateNo.split("@,@").length >= 2
          ? `${dashboardItem.plateNo.split("@,@")[0]} (${dashboardItem.plateNo.split("@,@")[1]})`
          : dashboardItem.plateNo;

      this.treeStructureObject[dashboardItem.plateNo] = {
        id: grpJsonId.toString(),
        parentid: filterDataMap.get(filterTextFormat).toString(),
        text: plateNoText,
        value: dashboardItem.vin,
      };

      this.treeStructureJson.push({
        id: grpJsonId.toString(),
        parentid: filterDataMap.get(filterTextFormat).toString(),
        text: plateNoText,
        value: dashboardItem.vin,
      });

      grpJsonId++;
    }

    for (const [key, value] of filterDataMap) {
      const loopedArry = key.split(",").pop();
      const popData = key.split(",");
      popData.pop();

      this.treeStructureObject[loopedArry] = {
        id: value.toString(),
        parentid: key.split(",").length > 1 ? filterDataMap.get(popData.toString()) : "-1",
        text: loopedArry,
        value: loopedArry,
      };

      this.treeStructureJson.push({
        id: value.toString(),
        parentid: key.split(",").length > 1 ? filterDataMap.get(popData.toString()) : "-1",
        text: loopedArry,
        value: loopedArry,
      });
    }

    this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
    this.commonService.dismissLoader();
  }



  selectAndCheckGeoZone(event) {
    this.myTree.checkItem(event.args.element, !this.myTree.getItem(event.args.element).checked)
  }

  getGridviewData() {
    let gridViewData = storageVariable.dashboardData.liveDatas;
    for (let grid in gridViewData) {
      if (gridViewData[grid].operatorName)
        if (!this.Operators.includes(gridViewData[grid].operatorName))
          this.Operators.push(gridViewData[grid].operatorName);

      if (gridViewData[grid].status)
        if (!this.vehicleStatus.includes(gridViewData[grid].status))
          this.vehicleStatus.push(gridViewData[grid].status);

      if (gridViewData[grid].vehicleType)
        if (!this.vehicleType.includes(gridViewData[grid].vehicleType))
          this.vehicleType.push(gridViewData[grid].vehicleType);

      if (gridViewData[grid].height)
        if (!this.vehicleHeight.includes(gridViewData[grid].height))
          this.vehicleHeight.push(gridViewData[grid].height);

      if (gridViewData[grid].fleetManager) {
        let fleet = gridViewData[grid].fleetManager.split(",");
        if (this.fleetManager.includes(fleet))
          this.fleetManager.push(fleet);
      }
    }
    // if (this.strogefleetmanager) {
    //   let fleet = this.strogefleetmanager;
    //   if (this.fleetManager.includes(fleet))
    //     this.fleetManager.push(fleet);
    // }

    //this.fleetManager = ['Assir-Assurance', 'Bisha-Region', 'comatec-1', 'comatecfleet', 'jizan_Area', 'Lumi', 'Mobily-NOC'];
  }


  getGridviewData1() {
    let gridViewData = storageVariable.dashboardData.liveDatas;
    for (let grid in gridViewData) {
      if (gridViewData[grid].status)
        if (!this.employeeStatus.includes(gridViewData[grid].status))
          this.employeeStatus.push(gridViewData[grid].status);

      if (gridViewData[grid].vehicleModel)
        if (!this.employeeType.includes(gridViewData[grid].vehicleModel))
          this.employeeType.push(gridViewData[grid].vehicleModel);

      if (gridViewData[grid].empDetails.supervisor) {
        let fleet = gridViewData[grid].empDetails.supervisor.split(",");
        if (this.fleetManager.includes(fleet))
          this.fleetManager.push(fleet);
      }
    }
  }

  // searchgroup() {
  //   var foundItem = 0;
  //   var items = this.myTree.getItems();
  //   var searchedValue = this.Search.toLowerCase();
  //   for (var i = 0; i < items.length; i++) {
  //     if (items[i].label.toLowerCase().indexOf(searchedValue) == 0) {

  //       this.myTree.expandItem(items[i].parentElement);
  //       this.myTree.selectItem(items[i]);
  //       if (foundItem != i) {
  //         this.myTree.collapseAll();
  //       }
  //       this.myTree.expandItem(items[i].parentElement);
  //       foundItem = i;
  //       break;
  //     }
  //   }
  //   ;
  // };
  searchgroup() {
    const searchedValue = this.Search.trim().toLowerCase();

    let data;
    if (this.myTree != undefined) {
      data = this.myTree.getItems();
    } else {
      return;
    }

    let foundItem = [];
    let expandedParents = new Set();

    data.forEach(res => {
      const labelText = res.label.toLowerCase();
      const textDiv = res.titleElement[0];

      textDiv.querySelectorAll('.highlightedText').forEach(span => {
        span.outerHTML = span.innerText;
      });

      if (searchedValue !== '' && labelText.includes(searchedValue)) {
        foundItem.push(res.element);
        this.myTree.expandItem(res.parentElement);
        expandedParents.add(res.parentElement);

        textDiv.innerHTML = textDiv.innerHTML.replace(
          new RegExp(searchedValue, 'gi'),
          match => `<span class="highlightedText" style="font-weight: bold;">${match}</span>`
        );
      }
    });


    // data.forEach(res => {
    //     if (!expandedParents.has(res.parentElement) && !foundItem.includes(res.parentElement) && res.label != "Select all") {
    //             if(res.parentElement.id != -1)
    //             this.myTree.collapseItem(res.parentElement);
    //     }
    // });




    if (foundItem.length > 0) {
      if (foundItem[0].id != -1) {
        this.myTree.selectItem(foundItem[0]);
        this.myTree.ensureVisible(foundItem[0]);
      }
    }

    if (searchedValue === '') {
      for (const res of data) {
        foundItem = res;
        this.myTree.expandAll();
        this.myTree.selectItem(null);
        this.myTree.ensureVisible(res.element);
        break;
      }
    }
  }
  searchGridViewData() {
    if (app.entryPoint != "WFT") {
      var filterData = [];
      let platenumbers = this.myTree.getCheckedItems();
      let plateNo = [];

      if (platenumbers.length)
        for (let p in platenumbers) {
          plateNo.push(platenumbers[p].label);
        }

      if (this.sendOperator.length)
        var filteroperatorData = this.sendOperator;
      if (this.sendvehicleStatus.length)
        var filtervehicleStatus = this.sendvehicleStatus;
      if (this.sendvehicleType.length)
        var filtervehicleType = this.sendvehicleType;
      if (this.sendvehicleHeight.length)
        var filtervehicleHeight = this.sendvehicleHeight;
      if (this.sendfleetManager.length)
        var filterfleetManager = this.sendfleetManager;
      let filter: any = Object.values(storageVariable.upDatedJsonData.liveDatas);

      if (filteroperatorData) {
        filter.filter(function (filter: any) {
          if (filteroperatorData.includes(filter.operatorName))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });
        if (filtervehicleStatus) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (filtervehicleStatus.includes(loopdata.status))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (filtervehicleType) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (filtervehicleType.includes(loopdata.vehicleType))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (filtervehicleHeight) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (filtervehicleHeight.includes(loopdata.height))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (filterfleetManager) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            var flt = [], vinfleet = [];
            var fleetmanger = {};
            for (let v in loopdata) {
              flt.push(loopdata[v].vin);
            }
            if (flt) {
              let i = 1;
              for (let fleet in this.vinandfleetData) {
                if (flt.includes(fleet)) {
                  let fleetArray = this.vinandfleetData[fleet].split(",");
                  if (fleetArray) {
                    if (i == 1) {
                      for (let v in fleetArray) {
                        fleetmanger = {
                          "vin": fleet,
                          "fleetManeger": fleetArray[v],
                        }
                        vinfleet.push(fleetmanger);
                        i++;
                      }
                    } else {
                      for (let v in fleetArray) {
                        if (!fleetmanger['fleetManeger'].includes(fleetArray[v])) {
                          fleetmanger = {
                            "vin": fleet,
                            "fleetManeger": fleetArray[v],
                          }
                          vinfleet.push(fleetmanger);
                        }
                      }
                    }
                  }
                }
              }
            }
            vinfleet.filter(function (vinfleet: any) {
              if (filterfleetManager.includes(vinfleet.fleetManeger))
                if (!filterData.includes(vinfleet))
                  filterData.push(vinfleet);
            });

            if (filterData) {
              let loopdata = filterData;
              filterData = [];
              loopdata.filter(function (loopdata: any) {
                if (!filterData.includes(loopdata.vin))
                  filterData.push(loopdata.vin);
              });
              let loopdata1 = filterData;
              filterData = [];
              loopdata1.filter(function (loopdata1: any) {
                if (!filterData.includes(loopdata1.vin))
                  filterData.push(loopdata1);
              });
              let loopdata3 = filterData;
              filterData = [];
              filter.filter(function (filter: any) {
                if (loopdata3.includes(filter.vin))
                  if (!filterData.includes(filter))
                    filterData.push(filter);
              });
            }
          }
        }

        if (plateNo.length) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }

        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (filtervehicleStatus) {
        filter.filter(function (filter: any) {
          if (filtervehicleStatus.includes(filter.status))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });

        if (filtervehicleType) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (filtervehicleType.includes(loopdata.vehicleType))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (filtervehicleHeight) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (filtervehicleHeight.includes(loopdata.height))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (filterfleetManager) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            var flt = [], vinfleet = [];
            var fleetmanger = {};
            for (let v in loopdata) {
              flt.push(loopdata[v].vin);
            }
            if (flt) {
              let i = 1;
              for (let fleet in this.vinandfleetData) {
                if (flt.includes(fleet)) {
                  let fleetArray = this.vinandfleetData[fleet].split(",");
                  if (fleetArray) {
                    if (i == 1) {
                      for (let v in fleetArray) {
                        fleetmanger = {
                          "vin": fleet,
                          "fleetManeger": fleetArray[v],
                        }
                        vinfleet.push(fleetmanger);
                        i++;
                      }
                    } else {
                      for (let v in fleetArray) {
                        if (!fleetmanger['fleetManeger'].includes(fleetArray[v])) {
                          fleetmanger = {
                            "vin": fleet,
                            "fleetManeger": fleetArray[v],
                          }
                          vinfleet.push(fleetmanger);
                        }
                      }
                    }
                  }
                }
              }
            }
            vinfleet.filter(function (vinfleet: any) {
              if (filterfleetManager.includes(vinfleet.fleetManeger))
                if (!filterData.includes(vinfleet))
                  filterData.push(vinfleet);
            });

            if (filterData) {
              let loopdata = filterData;
              filterData = [];
              loopdata.filter(function (loopdata: any) {
                if (!filterData.includes(loopdata.vin))
                  filterData.push(loopdata.vin);
              });
              let loopdata1 = filterData;
              filterData = [];
              loopdata1.filter(function (loopdata1: any) {
                if (!filterData.includes(loopdata1.vin))
                  filterData.push(loopdata1);
              });
              let loopdata3 = filterData;
              filterData = [];
              filter.filter(function (filter: any) {
                if (loopdata3.includes(filter.vin))
                  if (!filterData.includes(filter))
                    filterData.push(filter);
              });
            }
          }
        }

        if (plateNo.length) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (filtervehicleType) {
        filter.filter(function (filter: any) {
          if (filtervehicleType.includes(filter.vehicleType))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });

        if (filtervehicleHeight) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (filtervehicleHeight.includes(loopdata.height))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (filterfleetManager) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            var flt = [], vinfleet = [];
            var fleetmanger = {};
            for (let v in loopdata) {
              flt.push(loopdata[v].vin);
            }
            if (flt) {
              let i = 1;
              for (let fleet in this.vinandfleetData) {
                if (flt.includes(fleet)) {
                  let fleetArray = this.vinandfleetData[fleet].split(",");
                  if (fleetArray) {
                    if (i == 1) {
                      for (let v in fleetArray) {
                        fleetmanger = {
                          "vin": fleet,
                          "fleetManeger": fleetArray[v],
                        }
                        vinfleet.push(fleetmanger);
                        i++;
                      }
                    } else {
                      for (let v in fleetArray) {
                        if (!fleetmanger['fleetManeger'].includes(fleetArray[v])) {
                          fleetmanger = {
                            "vin": fleet,
                            "fleetManeger": fleetArray[v],
                          }
                          vinfleet.push(fleetmanger);
                        }
                      }
                    }
                  }
                }
              }
            }
            vinfleet.filter(function (vinfleet: any) {
              if (filterfleetManager.includes(vinfleet.fleetManeger))
                if (!filterData.includes(vinfleet))
                  filterData.push(vinfleet);
            });

            if (filterData) {
              let loopdata = filterData;
              filterData = [];
              loopdata.filter(function (loopdata: any) {
                if (!filterData.includes(loopdata.vin))
                  filterData.push(loopdata.vin);
              });
              let loopdata1 = filterData;
              filterData = [];
              loopdata1.filter(function (loopdata1: any) {
                if (!filterData.includes(loopdata1.vin))
                  filterData.push(loopdata1);
              });
              let loopdata3 = filterData;
              filterData = [];
              filter.filter(function (filter: any) {
                if (loopdata3.includes(filter.vin))
                  if (!filterData.includes(filter))
                    filterData.push(filter);
              });
            }
          }
        }

        if (plateNo.length) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }


        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (filtervehicleHeight) {
        filter.filter(function (filter: any) {
          if (filtervehicleHeight.includes(filter.height))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });

        if (filterfleetManager) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            var flt = [], vinfleet = [];
            var fleetmanger = {};
            for (let v in loopdata) {
              flt.push(loopdata[v].vin);
            }
            if (flt) {
              let i = 1;
              for (let fleet in this.vinandfleetData) {
                if (flt.includes(fleet)) {
                  let fleetArray = this.vinandfleetData[fleet].split(",");
                  if (fleetArray) {
                    if (i == 1) {
                      for (let v in fleetArray) {
                        fleetmanger = {
                          "vin": fleet,
                          "fleetManeger": fleetArray[v],
                        }
                        vinfleet.push(fleetmanger);
                        i++;
                      }
                    } else {
                      for (let v in fleetArray) {
                        if (!fleetmanger['fleetManeger'].includes(fleetArray[v])) {
                          fleetmanger = {
                            "vin": fleet,
                            "fleetManeger": fleetArray[v],
                          }
                          vinfleet.push(fleetmanger);
                        }
                      }
                    }
                  }
                }
              }
            }
            vinfleet.filter(function (vinfleet: any) {
              if (filterfleetManager.includes(vinfleet.fleetManeger))
                if (!filterData.includes(vinfleet))
                  filterData.push(vinfleet);
            });

            if (filterData) {
              let loopdata = filterData;
              filterData = [];
              loopdata.filter(function (loopdata: any) {
                if (!filterData.includes(loopdata.vin))
                  filterData.push(loopdata.vin);
              });
              let loopdata1 = filterData;
              filterData = [];
              loopdata1.filter(function (loopdata1: any) {
                if (!filterData.includes(loopdata1.vin))
                  filterData.push(loopdata1);
              });
              let loopdata3 = filterData;
              filterData = [];
              filter.filter(function (filter: any) {
                if (loopdata3.includes(filter.vin))
                  if (!filterData.includes(filter))
                    filterData.push(filter);
              });
            }
          }
        }

        if (plateNo.length > 0) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (filterfleetManager) {
        filterData = [];
        vinfleet = [];
        if (filterfleetManager) {
          var fleetManeger = filterfleetManager;
          for (let fleet in this.vinandfleetData) {
            let splitfleet = this.vinandfleetData[fleet].split(",");
            splitfleet.filter(function (splitfleet: any) {
              if (fleetManeger.includes(splitfleet))
                if (!filterData.includes(fleet))
                  filterData.push(fleet);
            });
          }
        }

        let loopdata3 = filterData;
        filterData = [];
        filter.filter(function (filter: any) {
          if (loopdata3.includes(filter.vin))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });

        if (plateNo.length) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (plateNo.length) {
        filter.filter(function (filter: any) {
          if (plateNo.includes(filter.plateNo))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });


        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

        localStorage.setItem("modalFilterData", JSON.stringify(filterData))
      }
    } else {
      var filterData = [];
      let platenumbers = this.myTree.getCheckedItems();
      let plateNo = [];

      if (platenumbers.length)
        for (let p in platenumbers) {
          plateNo.push(platenumbers[p].label);
        }

      if (this.sendemployeeStatus.length)
        var filtervehicleStatus = this.sendemployeeStatus;
      if (this.sendemployeeType.length)
        var filtervehicleType = this.sendemployeeType;
      if (this.sendfleetManager.length)
        var filterfleetManager = this.sendfleetManager;

      let filter: any = Object.values(storageVariable.upDatedJsonData.liveDatas);

      if (filtervehicleType) {
        filter.filter(function (filter: any) {
          if (filtervehicleType.includes(filter.vehicleModel))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });

        if (filtervehicleStatus) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (filtervehicleStatus.includes(loopdata.status))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (filterfleetManager) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            var flt = [], vinfleet = [];
            var fleetmanger = {};
            for (let v in loopdata) {
              flt.push(loopdata[v].vin);
            }
            if (flt) {
              let i = 1;
              for (let fleet in this.vinandfleetData) {
                if (flt.includes(fleet)) {
                  let fleetArray = this.vinandfleetData[fleet].split(",");
                  if (fleetArray) {
                    if (i == 1) {
                      for (let v in fleetArray) {
                        fleetmanger = {
                          "vin": fleet,
                          "fleetManeger": fleetArray[v],
                        }
                        vinfleet.push(fleetmanger);
                        i++;
                      }
                    } else {
                      for (let v in fleetArray) {
                        if (!fleetmanger['fleetManeger'].includes(fleetArray[v])) {
                          fleetmanger = {
                            "vin": fleet,
                            "fleetManeger": fleetArray[v],
                          }
                          vinfleet.push(fleetmanger);
                        }
                      }
                    }
                  }
                }
              }
            }
            vinfleet.filter(function (vinfleet: any) {
              if (filterfleetManager.includes(vinfleet.fleetManeger))
                if (!filterData.includes(vinfleet))
                  filterData.push(vinfleet);
            });

            if (filterData) {
              let loopdata = filterData;
              filterData = [];
              loopdata.filter(function (loopdata: any) {
                if (!filterData.includes(loopdata.vin))
                  filterData.push(loopdata.vin);
              });
              let loopdata1 = filterData;
              filterData = [];
              loopdata1.filter(function (loopdata1: any) {
                if (!filterData.includes(loopdata1.vin))
                  filterData.push(loopdata1);
              });
              let loopdata3 = filterData;
              filterData = [];
              filter.filter(function (filter: any) {
                if (loopdata3.includes(filter.vin))
                  if (!filterData.includes(filter))
                    filterData.push(filter);
              });
            }
          }
        }

        if (plateNo.length) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }


        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (filtervehicleStatus) {
        filter.filter(function (filter: any) {
          if (filtervehicleStatus.includes(filter.status))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });



        if (filterfleetManager) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            var flt = [], vinfleet = [];
            var fleetmanger = {};
            for (let v in loopdata) {
              flt.push(loopdata[v].vin);
            }
            if (flt) {
              let i = 1;
              for (let fleet in this.vinandfleetData) {
                if (flt.includes(fleet)) {
                  let fleetArray = this.vinandfleetData[fleet].split(",");
                  if (fleetArray) {
                    if (i == 1) {
                      for (let v in fleetArray) {
                        fleetmanger = {
                          "vin": fleet,
                          "fleetManeger": fleetArray[v],
                        }
                        vinfleet.push(fleetmanger);
                        i++;
                      }
                    } else {
                      for (let v in fleetArray) {
                        if (!fleetmanger['fleetManeger'].includes(fleetArray[v])) {
                          fleetmanger = {
                            "vin": fleet,
                            "fleetManeger": fleetArray[v],
                          }
                          vinfleet.push(fleetmanger);
                        }
                      }
                    }
                  }
                }
              }
            }
            vinfleet.filter(function (vinfleet: any) {
              if (filterfleetManager.includes(vinfleet.fleetManeger))
                if (!filterData.includes(vinfleet))
                  filterData.push(vinfleet);
            });

            if (filterData) {
              let loopdata = filterData;
              filterData = [];
              loopdata.filter(function (loopdata: any) {
                if (!filterData.includes(loopdata.vin))
                  filterData.push(loopdata.vin);
              });
              let loopdata1 = filterData;
              filterData = [];
              loopdata1.filter(function (loopdata1: any) {
                if (!filterData.includes(loopdata1.vin))
                  filterData.push(loopdata1);
              });
              let loopdata3 = filterData;
              filterData = [];
              filter.filter(function (filter: any) {
                if (loopdata3.includes(filter.vin))
                  if (!filterData.includes(filter))
                    filterData.push(filter);
              });
            }
          }
        }

        if (plateNo.length) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (filterfleetManager) {
        filterData = [];
        vinfleet = [];
        if (filterfleetManager) {
          var fleetManeger = filterfleetManager;
          for (let fleet in this.vinandfleetData) {
            let splitfleet = this.vinandfleetData[fleet].split(",");
            splitfleet.filter(function (splitfleet: any) {
              if (fleetManeger.includes(splitfleet))
                if (!filterData.includes(fleet))
                  filterData.push(fleet);
            });
          }
        }

        let loopdata3 = filterData;
        filterData = [];
        filter.filter(function (filter: any) {
          if (loopdata3.includes(filter.vin))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });

        if (plateNo.length) {
          if (filterData) {
            let loopdata = filterData;
            filterData = [];
            loopdata.filter(function (loopdata: any) {
              if (plateNo.includes(loopdata.plateNo))
                if (!filterData.includes(loopdata))
                  filterData.push(loopdata);
            });
          }
        }

        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

      } else if (plateNo.length) {
        filter.filter(function (filter: any) {
          if (plateNo.includes(filter.plateNo))
            if (!filterData.includes(filter))
              filterData.push(filter);
        });


        if (!filterData.length) {
          this.commonService.presentAlert("No Data", "No Data Available");
          this.nodata = true
        }
        localStorage.setItem("modalFilterData", JSON.stringify(filterData));
        this.filterReturn.emit(filterData);

        localStorage.setItem("modalFilterData", JSON.stringify(filterData))
      }
      this.modelController.dismiss();
    }

  }

  getvinandfleetmanager() {
    const url = serverUrl.web + '/user/vehicleAssociatedFleetmanagers?companyId=' + localStorage.getItem('corpId') + '&branchId=' + localStorage.getItem('corpId');
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.vinandfleetData = res;
      })
  }

  closemodel() {
    this.modelController.dismiss()
    // localStorage.removeItem('modalFilterData');

  }

  getFleetManager() {
    const url = serverUrl.web + '/user/fleetmanagers?companyId=' + localStorage.getItem('corpId') + '&branchId=' + localStorage.getItem('corpId');
    this.ajaxService.ajaxGet(url)
      .subscribe(res => {
        this.fleetManager = res;
      })
  }

  ngOnInit() {
    let a: any[] = Object.values(storageVariable.dashboardData.liveDatas);
    for (let i = 0; i < a.length; i++) {
      if (!a[i]['warrantyExpiry'])
        this.dashboardJson.push(a[i]);
    }

    this.entryPoint = app.entryPoint;
    this.getvinandfleetmanager();
    this.getFleetManager();
    this.getGridviewData();
    this.renderPlateNo();

    if (app.entryPoint == "WFT")
      this.getGridviewData1();

  }
}
