import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { ExportExcelService } from '../../services/export-excel.service';
import { Platform, } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { app, languageInitializer, serverUrl } from '../../../environments/environment';
import { ModalController, AlertController } from '@ionic/angular';
import { OperatorformComponent } from '../operator/operatorform/operatorform.component';
import { Plugins, FilesystemDirectory } from '@capacitor/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { TranslateService } from '@ngx-translate/core';
const { Filesystem, Storage } = Plugins;

export const File_Key = 'files';

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss'],
})
export class OperatorComponent implements OnInit {
  app: any = { logo: 'logo.png' };
  isDeleteShow = false
  directory = languageInitializer.directory
  @ViewChild(OperatorformComponent, { static: false }) operatorformComponent: OperatorformComponent;
  titles = 'jspdf-autotable-demo';
  title = 'angular-export-to-excel'
  dataForExcel = [];
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  objValues = [];
  odj = [];
  columns: ({ text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; } | { text: string; datafield: string; cellsrenderer?: undefined; })[];
  selectedRow: any;
  editrow: number;
  tableData: any;
  getAdapter: any;
  datas = [];
  selectedRowIdx: any;
  pdfdatas = [];
  companyDetail: { branchID: string; companyID: string; userId: string; };
  detail: any;
  newDetail: any;
  localizationobj: {};
  pagesizeoption = ["5", "10", "13", "15", "20"];

  constructor(
    private modalController: ModalController,
    private ete: ExportExcelService,
    private fileOpener: FileOpener,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    public platform: Platform,
    private alertController: AlertController,
    private trans: TranslateService
  ) { }

  source: { localdata: any; };
  dataAdapter: any;
  renderer: (row: number, column: any, value: string) => string;

  head = ['Operator Name', 'Tag Id', 'Contact', 'Address Line 1', 'City', 'Plate No', 'Country', 'Email Address', 'Emergency ContactNo', 'License', 'License Expiry Date'];
  exportTitle = [];
  excelTitle = {};
  newTitle = [];
  column = [];

  //;

  exportToExcel() {
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["Operator Name"] = row.name;
      obj["Tag Id"] = row.surName;
      obj["Contact"] = row.telNo;
      obj["Address Line 1"] = row.address;
      obj["Branch Location"] = row.branchlocation;
      obj["City"] = row.address2;
      obj["Plate No"] = row.plateNo;
      obj["Country"] = row.nationality;
      obj["Email Address"] = row.eMailAddress;
      obj["Emergency ContactNo"] = row.emergencyContactNo;
      obj["License"] = row.licenseNo;
      obj["License Expiry Date"] = row.licenseExpiry;
      return obj;
    });
    let reportData = {
      title: 'Operator',
      data: this.pdfdatas,
      headers: this.head,
      data1: filteredData
    }
    this.ete.exportExcel(reportData);
    console.log("Export Excel")
  }

  async createPdf() {
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["Operator Name"] = row.name;
      obj["Tag Id"] = row.surName;
      obj["Contact"] = row.telNo;
      obj["Address Line 1"] = row.address;
      obj["Branch Location"] = row.branchlocation;
      obj["City"] = row.address2;
      obj["Plate No"] = row.plateNo;
      obj["Country"] = row.nationality;
      obj["Email Address"] = row.eMailAddress;
      obj["Emergency ContactNo"] = row.emergencyContactNo;
      obj["License"] = row.licenseNo;
      obj["License Expiry Date"] = row.licenseExpiry;
      return obj;
    });
    //For filtered rows

    this.commonService.createPdf(this.head, this.pdfdatas, filteredData, this.exportTitle, "", "Operator_List", null);
  }
  private getMimetype(name) {
    if (name.indexOf('pdf') >= 0) {
      return 'application/pdf'
    } else if (name.indexOf('png') >= 0) {
      return 'image/png'
    } else if (name.indexOf('mp4') >= 0) {
      return 'video/png'
    }
  }

  deletebtn() {
    const deleteData = { "tagID": this.selectedRow.operatorID, "compnyID": this.companyDetail.companyID, "branchID": this.companyDetail.branchID, "companyNme": this.companyDetail.userId }
    const url = serverUrl.web + '/operator/deleteOperatorInfo?operatorID=' + this.selectedRow.operatorID;
    this.ajaxService.ajaxDeleteWithBody(url, deleteData).subscribe(res => {
      //console.log(res);
      if (res.error.text == "Operator is already Associated") {
        //this.commonService.presentToast("Associated operator cannot be deleted")
        this.commonService.presentToast("Associated operator cannot be deleted")
        this.getDatas();
      } else if (res.statusText == "OK") {
        //this.commonService.presentToast("Deleted successfully")
        this.commonService.presentToast(this.trans.instant("Deleted successfully"))
        this.myGrid.clearselection();
        this.getDatas();
      }

    })
  }
  async deleteMode() {

    if (this.selectedRow) {
      const alert = await this.alertController.create({
        //header: 'delete ',
        header: this.trans.instant('delete'),
        backdropDismiss: false,
        //message: "Are you sure you want to delete?",
        message: this.trans.instant("Are you sure you want to delete?"),
        buttons: [{
          // text: 'Cancel',
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {



          }
        },
        {
          //text: 'Ok',
          text: this.trans.instant('Ok'),
          handler: data => {
            // console.log(this.selectedRow.eventId)
            const deleteData = { "tagID": this.selectedRow.operatorID, "compnyID": this.companyDetail.companyID, "branchID": this.companyDetail.branchID, "companyNme": this.companyDetail.userId }
            const url = serverUrl.web + '/operator/deleteOperatorInfo?operatorID=' + this.selectedRow.operatorID;
            this.ajaxService.ajaxDeleteWithString(url).subscribe(res => {
              //console.log(res);
              if (res.error.text == "Operator is already Associated") {
                // this.commonService.presentToast("Associated operator cannot be deleted")
                this.commonService.presentToast(this.trans.instant("Associated operator cannot be deleted"))
                this.getDatas();
              } else if (res.statusText == "OK") {
                //this.commonService.presentToast("Deleted successfully")
                this.commonService.presentToast(this.trans.instant("Deleted successfully"))
                this.myGrid.clearselection();
                this.getDatas();
              }

            })
          }
        }]
      });
      await alert.present();

    }
    else {
      //this.commonService.presentToast('Please select a row to delete');
      this.commonService.presentToast(this.trans.instant('Please select a row to delete'));
      return "";

    }

  }
  myGridOnRowSelect(event: any): void {
    this.selectedRow = event.args.row;
    //  event.isTrigger = false
    this.selectedRowIdx = event.args.rowindex;

  }
  async openModel() {
    const modal = await this.modalController.create({
      component: OperatorformComponent,
      cssClass: this.directory != 'rtl' ? 'custom-modal' : 'custom-modal-rtl'
    });
    modal.onDidDismiss().then(() => {
      // this.myGrid.clearselection();
      this.selectedRow = "";
      this.getDatas();

    })
    return await modal.present();
  }

  async editMode(selectCard) {
    if (this.selectedRow) {
      this.selectedRow["submit"] = "available";
      const modal = await this.modalController.create({
        component: OperatorformComponent,
        cssClass: this.directory != 'rtl' ? 'custom-modal' : 'custom-modal-rtl',
        componentProps: {
          value: this.selectedRow,
        }
      });
      modal.onDidDismiss().then(() => {
        this.myGrid.clearselection();
        this.selectedRow = "";
        this.getDatas();
      })

      return await modal.present();

    }
    else {
      //this.commonService.presentToast('Please select a row to edit');
      this.commonService.presentToast(this.trans.instant('Please select a row to edit'));
      return "";

    }

  }
  ngAfterViewInit() {
    this.myGrid.showloadelement();

    this.getDatas();
  }
  ngOnInit() {
    this.companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    let localMainMenu = JSON.parse(localStorage.mainMenu)
    this.isDeleteShow = localMainMenu.includes("Delete")

    //Grid Localization
    this.localizationobj = {
      pagergotopagestring: this.trans.instant("Go to page"),
      pagershowrowsstring: this.trans.instant("Show rows"),
      sortascendingstring: this.trans.instant("Sort Ascending"),
      sortdescendingstring: this.trans.instant("Sort Descending"),
      sortremovestring: this.trans.instant("Remove Sort")
    }
    this.commonService.updateLanguageDir
      .subscribe(res => {

        this.directory = res == "" ? this.directory : res
      })

  }

  getDatas() {
    const companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }

    var datas = { "companyId": companyDetail.companyID, "branchId": companyDetail.branchID, "companyName": companyDetail.userId + '' }
    var url2 = serverUrl.web + '/operator/operatorinfo?companyId=' + companyDetail.companyID + '&branchId=' + companyDetail.branchID;
    this.ajaxService.ajaxPostMethodWithoutData(url2).subscribe(res => {
      //console.log(res);
      var detail = JSON.parse(res)
      this.tableData = JSON.parse(res);
      this.pdfdatas = [];
      for (var i = 0; i < detail.length; i++) {
        this.pdfdatas.push([detail[i].name, detail[i].tagId, detail[i].telNo, detail[i].address, detail[i].address2, detail[i].plateNo, detail[i].nationality, detail[i].emailAddress, detail[i].emergencyContactNo, detail[i].licenseNo, detail[i].licenseExpiry]);
      }
      this.renderer = (row: number, column: any, value: string,) => {
        if (value == "" || null || undefined) {
          return "----"
        }
        else {
          return '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' + value + '</span>';
        }
      }
      this.source = { localdata: this.tableData };
      this.dataAdapter = new jqx.dataAdapter(this.source);
      this.columns = [
        { text: this.trans.instant('Operator Name'), datafield: 'name', cellsrenderer: this.renderer },
        { text: this.trans.instant('Tag Id'), datafield: 'surName', cellsrenderer: this.renderer },
        // {text :'Operator',datafield:'operatorName',cellsrenderer:this.renderer},
        { text: this.trans.instant('Contact'), datafield: 'telNo', cellsrenderer: this.renderer },
        // {text :'Serial No',datafield:'serialNo',cellsrenderer:this.renderer},
        { text: this.trans.instant('Address Line 1'), datafield: 'address', cellsrenderer: this.renderer },
        { text: this.trans.instant('Branch Location'), datafield: 'branchlocation', cellsrenderer: this.renderer },
        { text: this.trans.instant('City'), datafield: 'address2', cellsrenderer: this.renderer },
        { text: this.trans.instant('Plate No'), datafield: 'plateNo', cellsrenderer: this.renderer },
        { text: this.trans.instant('Country'), datafield: 'nationality', cellsrenderer: this.renderer },
        { text: this.trans.instant('Email Address'), datafield: 'eMailAddress', cellsrenderer: this.renderer },
        { text: this.trans.instant('emergencyContactNo'), datafield: 'emergencyContactNo', cellsrenderer: this.renderer },
        { text: this.trans.instant('License'), datafield: 'licenseNo', cellsrenderer: this.renderer },
        { text: this.trans.instant('License Expiry Date'), datafield: 'licenseExpiry', cellsrenderer: this.renderer },
      ]
      this.myGrid.pagesizeoptions(this.pagesizeoption)
      this.myGrid.updatebounddata();
      this.myGrid.unselectrow;
    })


    this.app["logo"] = localStorage.companyLogo;

  }

}
