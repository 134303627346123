import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControlName } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxcombobox';
import { IonicSelectableComponent } from 'ionic-selectable';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { ExportExcelService } from '../../services/export-excel.service';
import { app, companyReportData, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { WeekOdometerComponent } from 'src/app/components/week-odometer/week-odometer.component';
import { map } from 'highcharts';
import { GroupTreeStructureComponent } from '../group-tree-structure/group-tree-structure.component';
import { TranslateService } from '@ngx-translate/core';
import { ConversationTabPageModule } from 'src/app/ticket/conversation-tab/conversation-tab.module';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { VtsReportsComponent } from '../vts-reports/vts-reports.component';
// import { type } from 'os';
@Component({
  selector: 'app-vts-report-form',
  templateUrl: './vts-report-form.component.html',
  styleUrls: ['./vts-report-form.component.scss'],
})
export class VtsReportFormComponent implements OnChanges {
  titles = 'jspdf-autotable-demo';
  title = 'angular-export-to-excel';
  @ViewChild('alertTypeSelectedNew', { static: false }) alertTypeSelectedNew;
  @ViewChild('datatypeSelected', { static: false }) datatypeSelected;
  @ViewChild('modeSelected', { static: false }) modeSelected;
  @ViewChild('temperatureSensor', { static: false }) temperatureSensor;
  @ViewChild('unselectnew', { static: false }) unselectnew;
  @Input() selectReportTypes;
  startDate: Date = new Date('2024-08-01'); // Adjust start date as needed
  endDate: Date = new Date('2024-08-31');   // Adjust end date as needed
  dateList: Array<{ date: Date, day: string, selected: boolean }> = [];
  numberOptions1 = [
    { label: "0", value: "00:00:00 AM" },
    { label: "1", value: "01:00:00 AM" },
    { label: "2", value: "02:00:00 AM" },
    { label: "3", value: "03:00:00 AM" },
    { label: "4", value: "04:00:00 AM" },
    { label: "5", value: "05:00:00 AM" },
    { label: "6", value: "06:00:00 AM" },
    { label: "7", value: "07:00:00 AM" },
    { label: "8", value: "08:00:00 AM" },
    { label: "9", value: "09:00:00 AM" },
    { label: "10", value: "10:00:00 AM" },
    { label: "11", value: "11:00:00 AM" },
    { label: "12", value: "11:59:59 PM" },
  ];
  numberOptions2 = [

    // { label: "0", value: "00:00:00 PM" },
    { label: "1", value: "01:00:00 PM" },
    { label: "2", value: "02:00:00 PM" },
    { label: "3", value: "03:00:00 PM" },
    { label: "4", value: "04:00:00 PM" },
    { label: "5", value: "05:00:00 PM" },
    { label: "6", value: "06:00:00 PM" },
    { label: "7", value: "07:00:00 PM" },
    { label: "8", value: "08:00:00 PM" },
    { label: "9", value: "09:00:00 PM" },
    { label: "10", value: "10:00:00 PM" },
    { label: "11", value: "11:00:00 PM" },
    { label: "12", value: "12:00:00 PM" }
  ];
  timeConvertion = {
    "00:00:00 AM": "00:00:00 AM",
    "01:00:00 AM": "01:00:00 AM",
    "02:00:00 AM": "02:00:00 AM",
    "03:00:00 AM": "03:00:00 AM",
    "04:00:00 AM": "04:00:00 AM",
    "05:00:00 AM": "05:00:00 AM",
    "06:00:00 AM": "06:00:00 AM",
    "07:00:00 AM": "07:00:00 AM",
    "08:00:00 AM": "08:00:00 AM",
    "09:00:00 AM": "09:00:00 AM",
    "10:00:00 AM": "10:00:00 AM",
    "11:00:00 AM": "11:00:00 AM",
    "11:59:59 AM": "11:59:59 AM",
    "12:00:00 PM": "12:00:00 PM",
    "01:00:00 PM": "13:00:00 PM",
    "02:00:00 PM": "14:00:00 PM",
    "03:00:00 PM": "15:00:00 PM",
    "04:00:00 PM": "16:00:00 PM",
    "05:00:00 PM": "17:00:00 PM",
    "06:00:00 PM": "18:00:00 PM",
    "07:00:00 PM": "19:00:00 PM",
    "08:00:00 PM": "20:00:00 PM",
    "09:00:00 PM": "21:00:00 PM",
    "10:00:00 PM": "22:00:00 PM",
    "11:00:00 PM": "23:00:00 PM",
    "11:59:59 PM": "23:59:59 PM"
  };

  directory = languageInitializer.directory
  showReport: string;
  temperatureList = [
    { tempName: 'Temperature Sensor 1', value: 'TEMP1', temp: 'TEMPERATURESENSOR1' },
    { tempName: 'Temperature Sensor 2', value: 'TEMP2', temp: 'TEMPERATURESENSOR2' },
    { tempName: 'Temperature Sensor 3', value: 'TEMP3', temp: 'TEMPERATURESENSOR3' },
    { tempName: 'Temperature Sensor 4', value: 'TEMP4', temp: 'TEMPERATURESENSOR4' },
    { tempName: 'BT Temperature Sensor 1', value: 'BLEtemp1', temp: 'BTTEMPERATURESENSOR1' },
    { tempName: 'BT Temperature Sensor 2', value: 'BLEtemp2', temp: 'BTTEMPERATURESENSOR2' },
    { tempName: 'BT Temperature Sensor 3', value: 'BLEtemp3', temp: 'BTTEMPERATURESENSOR3' },
    { tempName: 'BT Temperature Sensor 4', value: 'BLEtemp4', temp: 'BTTEMPERATURESENSOR4' },
  ]
  datatype: any = []
  mode = [{ label: "Select All", value: "All" }, ...[
    'Edit',
    'Delete'
  ]]
  head = [];
  temperatureTitle: undefined;
  exportTitle = [];
  excelTitle = {};
  newTitle = [];
  column = [];
  reportKeys: any;
  reportTitle: string;
  page;
  dataForExcel = [];
  tableData: any;
  reportsData;
  objValues = [];
  odj = [];
  odjosp = [];
  odjrun = [];
  odjhb = [];
  commonData: any;
  temp = 'Temprature report';
  reportData: any;
  routetype: any;
  groupList: any = [];
  groupPlateNoList: any = {};
  selectedGroupList: any;
  recurrenceData: any;
  plateNotext: string = this.trans.instant("Click here to select!");
  entryPoint: any;
  company: any;
  boole: boolean = false;
  tempVar: number;
  reportList: any = [];
  parameter: any;
  unselect: string[];
  unselectdate: any[];
  checkboxesdate = { id: 1, value: 'all', checked: false }
  checkboxes = [
    { id: 1, value: 'all', label: this.trans.instant('Select All'), checked: false },
    { id: 2, value: 'vg', label: this.trans.instant('Group'), checked: false },
    { id: 3, value: 'voc', label: this.trans.instant('Vehicle Operation code'), checked: false },
    { id: 4, value: 'on', label: this.trans.instant('Operator'), checked: false },
    { id: 5, value: 'in', label: this.trans.instant('IMEI No'), checked: false },
    { id: 6, value: 'sn', label: this.trans.instant('SIM No'), checked: false },
    { id: 7, value: 'dm', label: this.trans.instant('Device Make'), checked: false },
    { id: 8, value: 'dmn', label: this.trans.instant('Device Model'), checked: false },
    { id: 9, value: 'dp', label: this.trans.instant('Date of Purchase'), checked: false },
    { id: 10, value: 'iu', label: this.trans.instant('Asset category'), checked: false },
    { id: 11, value: 'vm', label: this.trans.instant('Vehicle Model'), checked: false },
    { id: 12, value: 'vt', label: this.trans.instant('Vehicle type'), checked: false },
    { id: 13, value: 'vy', label: this.trans.instant('vehicle Year'), checked: false },
    { id: 14, value: 'gc', label: this.trans.instant('Color'), checked: false },
    { id: 15, value: 'dbm', label: this.trans.instant('Debugging Mode'), checked: false },
  ];
  hide: boolean;
  additioncolumn: any[];
  checkedValues: string = "";
  allChecked: any;
  show: any;
  showpdf: boolean = true;
  objfilter: any[];
  overspeed: any;
  runDur: any;
  excelTitleosp = {};
  excelTitlerun = {};
  harshbraking: any;
  excelTitlehb = {}
  harshacceleration: any;
  odjha: any[]=[];
  excelTitleha = {}
  drift: any;
  odjd: any[]=[];
  excelTitled = {}
  seatbelt: any;
  odjsb: any[]=[];
  excelTitlesb = {}
  showdate: any;
  notrnsdateis: boolean = false;
  ignoreddate: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private platform: Platform,
    private modalController: ModalController,
    private ajaxService: AjaxService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private ete: ExportExcelService,
    private trans: TranslateService
  ) {
  }
  @Input() shownContent: any;
  @Input() reportName: string;
  @Input() plateNo: any;
  @Input() allReportItem;
  @Output() reportEmitter = new EventEmitter<{ string }>();
  @ViewChild('selectComponent', { static: false }) selectComponent: IonicSelectableComponent;
  isDateFilterChecked: boolean = false;
  allReportTitleRecObj = {
    "Vehicle movement report": "Movement Report",
    "Stop report": "Stop Report",
    "Executive summary report": "Summary Report",
    "Overall summary report": "Summary By Day",
    "Alert report": "Alert Report",
    "Speed report": "Speed Report"
  };
  alertType: any;
  allReportTitle = []
  data = [];
  reports: any;
  reportsContent: any;
  reportsTitle: string;
  liveData: any;
  dashboarData: any = [];
  vin: any;
  today = new Date();
  yesterday = new Date(this.today.setDate(this.today.getDate() - 1));
  maxDate;
  segment = "";
  toggleShow: boolean = false;
  selectedDate: string = null;
  filterRows: any;
  plateText: boolean = true;
  showeditdate: boolean = false;
  firstAM = true;
  firstPM = false;
  secondAM = false;
  secondPM = true;

  AmPmChange1(event) {
    console.log("AmPmChange1,", event.target.value);
    if (event.target.value == "PM") {
      this.firstAM = false;
      this.firstPM = true;
      this.reports.patchValue({ fromTimeOnly: "01:00:00 PM" });
    } else {
      this.firstAM = true;
      this.firstPM = false;
      this.reports.patchValue({ fromTimeOnly: "00:00:00 AM" });
    }

  }
  AmPmChange2(event) {
    console.log("AmPmChange1,", event.target.value);
    if (event.target.value == "PM") {
      this.secondAM = false;
      this.secondPM = true;
      this.reports.patchValue({ toTimeOnly: "01:00:00 PM" });
    } else {
      this.secondAM = true;
      this.secondPM = false;
      this.reports.patchValue({ toTimeOnly: "00:00:00 AM" });
    }

  }
  fromTimeChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log('Selected value:', selectedValue);
    this.reports.value.fromTimeOnly = selectedValue;
  }
  toTimeChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log('Selected value:', selectedValue);
    this.reports.value.toTimeOnly = selectedValue;
  }
  toggleView() {
    this.toggleShow = !this.toggleShow
    this.checkedValues = ''
    this.checkvalues();
  }
  openeditdate() {
    this.showeditdate = true
    this.generateDateList();

  }
  saveeditdate() {
    this.showeditdate = false
    this.ignoreddate = ""
    this.dateList.forEach(res => {
      if (res.selected == false) {
        this.ignoreddate += res.date.toISOString().split('T')[0] + ','
      }
    })
    if (this.ignoreddate.endsWith(',')) {
      this.ignoreddate = this.ignoreddate.slice(0, -1);
    }
    console.log(this.ignoreddate);


  }
  canceleditdate() {
    this.ignoreddate = ""
    this.showeditdate = false
  }
  onsubmit() {
    console.log(this.reports.value.stopdur, this.reports.value.idledur);


    this.commonService.clocktime = this.reports.value.clocktime;
    this.showReport = "";
    let body: any = {};
    let ajaxCall = "post";
    let mode: string;
    let router: string;

    if ((this.vin == undefined && this.reportsTitle != "Maintenance report" || this.vin.length == 0) && this.reportsTitle != "User Activity Report" && this.reportsTitle != "SKT Alerts Report" && this.reportsTitle != "User Logs Report") {
      if (this.entryPoint === "ATM") {
        this.commonService.presentToast('Please Select ATM ID')
      }
      else
        this.commonService.presentToast('Please select the vehicle')
      return null;
    }
    if (this.plateNo !== "null") {
      this.reports.patchValue({ 'plateNo': this.plateNo });
      this.vin = JSON.parse(localStorage.selectedVin).vin;
    } else {
      if (this.reportsTitle == 'Driver behaviour report' || this.reportsTitle == 'Active status report') {
        this.vin = "All";
      }
    }
    switch (this.reportsTitle) {

      case "Overall summary report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data');
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin.toString();
          body["fromDate"] = this.reports.value.fromDate.split(' ')[0],
            body["toDate"] = this.reports.value.toDate.split(' ')[0],
            body["companyId"] = localStorage.corpId;
          body["userId"] = localStorage.userName;
          body["entryPoint"] = app.entryPoint;
          mode = "/report/executiveSummary";
          router = "overallSummaryReport";
        }
        break;

      case "Engine Hours summary report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          //if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "" || this.reports.value.fromTime == "" || this.reports.value.toTime == "") {
          this.commonService.presentToast('Please select the valid data');
          return null;
        } else {
          body["vin"] = this.vin;
          this.commonService.presentLoader();
        }
        body['address'] = this.reports.value.addressCheckbox
        body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
        body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/tripBasedSummary"
        router = "EngineHoursSummaryReport";
        break;
      case "Trip summary report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data');
          return null;
        } else {
          body["vin"] = this.vin;
          this.commonService.presentLoader();
        }
        body['address'] = this.reports.value.addressCheckbox
        body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
        body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/tripBasedSummary"
        router = "EngineHoursSummaryReport";
        break;
      case "Trip summary report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data');
          return null;
        } else {
          body["vin"] = this.vin;
          this.commonService.presentLoader();
        }
        body['address'] = this.reports.value.addressCheckbox
        body["fromDate"] = this.reports.value.fromDate.replace('T', ' ')
        body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/tripBasedSummary"
        router = "tripSummaryReport";
        break;

      case "Executive summary report":

        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else if (app.entryPoint != "ATM") {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          body["fromDate"] = this.reports.value.fromDate.split(' ')[0];
          body["toDate"] = this.reports.value.toDate.split(' ')[0];
          body["companyId"] = localStorage.corpId;
          body["userId"] = localStorage.userName;
          body["entryPoint"] = app.entryPoint;
          body['showAddress'] = this.reports.value.addressCheckbox;
          body['assetDatas'] = this.checkedValues;
          mode = "/report/executiveSummary";
          router = "executiveSummaryReport";
        } else if (app.entryPoint == "ATM") {
          ajaxCall = "get"
          this.commonService.presentLoader();
          var vins = this.commonService.reportvin
          if (vins == "all") {
            this.vin = vins
            this.commonService.reportvin = ""
          }
          body = "?vin=" + this.vin + "&fromDate=" + this.reports.value.fromDate.split(' ')[0] + "&toDate=" + this.reports.value.toDate.split(' ')[0] + "&userId=" + localStorage.userName
          mode = "/report/executiveSummaryForATM";
          router = "executiveSummaryReportatm";
        }
        break;

      case "Driver behaviour report":
        if (this.vin == undefined || this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          body["fromDate"] = this.reports.value.fromDate.split(' ')[0]
          body["toDate"] = this.reports.value.fromDate.split(' ')[0]
          body["companyId"] = localStorage.corpId;
          body["userId"] = localStorage.userName;
          body['assetDatas'] = this.checkedValues;
          mode = "/report/driverBehaviour";
          router = "driverBehavior";
        }
        break;

      case "Status summary report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "post"
          this.commonService.presentLoader();
          body["vin"] = this.vin[this.vin.length - 1].toString()
          body["fromdata"] = this.reports.value.fromDate.split(' ')[0];
          body["todate"] = this.reports.value.toDate.split(' ')[0];
          body["companyID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["showAddress"] = "false";
          body["currentDate"] = "No";
          body["multivins"] = this.vin.toString() + ',';
          //body['showAddress'] = this.reports.value.addressCheckbox;
          body['showAddress'] = "false";
          body['assetDatas'] = this.checkedValues;
          mode = '/ReportController/SummaryReport';
          router = "statusSummary";
        }
        break;
      case "Alert report":
        let array = []
        let tempAlert1 = this.alertTypeSelectedNew.getCheckedItems();
        tempAlert1.forEach(value => {
          array.push(value.originalItem)
        });
        this.reports.value.alertTypeSelected = array;
        if (this.reports.value.fromDate.replace('T', ' ') + ":00" == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else if (this.reports.value.alertTypeSelected == 0) {
          this.commonService.presentToast('Please select the alert type')
          return null;
        }
        else if (this.vin.split(",").length > 99 && ((new Date(this.reports.value.toDate.replace('T', ' ')).getTime() -
          new Date(this.reports.value.fromDate.replace('T', ' ') + ":00").getTime()) / (1000 * 3600 * 24)) > 31) {
          this.commonService.presentToast('Date range must have only one month');
          return null;
        }
        else {
          this.commonService.presentLoader();
          if (this.vin == 'All') {
            body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
            body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          }
          else {
            body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
            body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          }

          body["userId"] = localStorage.userName;
          body["companyId"] = localStorage.corpId,
            body["address"] = false;
          body["vin"] = this.vin.toString()
          body['assetDatas'] = this.checkedValues;
          mode = "/alert/mobileAlert";
          router = "alertReport";
        }
        break;
      case "Fence Report":
        let tempAlert2 = this.alertTypeSelectedNew.getCheckedItems();
        this.reports.value.alertTypeSelected = tempAlert2.map(value => value.originalItem);
        if (this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else if (this.reports.value.alertTypeSelected == 0) {
          this.commonService.presentToast('Please select the alert type')
          return null;
        }
        else if (this.vin.split(",").length > 99 && ((new Date(this.reports.value.toDate.replace('T', ' ')).getTime() -
          new Date(this.reports.value.fromDate.replace('T', ' ')).getTime()) / (1000 * 3600 * 24)) > 31) {
          this.commonService.presentToast('Date range must have only one month');
          return null;
        }
        else {
          this.commonService.presentLoader();
          if (this.vin == 'All') {
            //body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00" + " " + (this.reports.value.fromTime.split(":").length == 3 ? this.reports.value.fromTime : this.reports.value.fromTime + ":00");
            //body["toDate"] = this.reports.value.toDate.replace('T', ' ') + " " + (this.reports.value.toTime.split(":").length == 3 ? this.reports.value.toTime : this.reports.value.toTime + ":00");
            body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
            body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          }
          else {
            body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
            body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          }

          body["userId"] = localStorage.userName;
          body["companyId"] = localStorage.corpId,
            body["address"] = false;
          body["vin"] = this.vin.toString()
          body['assetDatas'] = this.checkedValues;
          mode = "/alert/mobileAlert";
          router = "alertReport";
        }
        break;
      case "Maintenance Alert":
        let tempAlert = this.alertTypeSelectedNew.getCheckedItems();
        this.reports.value.alertTypeSelected = tempAlert.map(value => value.originalItem);
        if (this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else if (this.reports.value.alertTypeSelected == 0) {
          this.commonService.presentToast('Please select the alert type')
          return null;
        }
        else if (this.vin.split(",").length > 99 && ((new Date(this.reports.value.toDate.replace('T', ' ')).getTime() -
          new Date(this.reports.value.fromDate.replace('T', ' ')).getTime()) / (1000 * 3600 * 24)) > 31) {
          this.commonService.presentToast('Date range must have only one month');
          return null;
        }
        else {


          this.commonService.presentLoader();
          if (this.vin == 'All') {
            //body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00" + " " + (this.reports.value.fromTime.split(":").length == 3 ? this.reports.value.fromTime : this.reports.value.fromTime + ":00");
            //body["toDate"] = this.reports.value.toDate.replace('T', ' ') + " " + (this.reports.value.toTime.split(":").length == 3 ? this.reports.value.toTime : this.reports.value.toTime + ":00");
            body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
            body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          }
          else {
            body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
            body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          }

          body["userId"] = localStorage.userName;
          body["companyId"] = localStorage.corpId,
            body["address"] = false;
          body["vin"] = this.vin.toString()
          body['assetDatas'] = this.checkedValues;
          mode = "/alert/mobileAlert";
          router = "alertReport";
        }
        break;
      case "Overspeed duration report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          body["fdate"] = this.reports.value.fromDate.split(' ')[0]
          body["tdate"] = this.reports.value.toDate.split(' ')[0]
          body["userID"] = localStorage.userName;
          body['assetDatas'] = this.checkedValues;
          mode = "/report/overspeed";
          router = "overspeedReport";
        }
        break;

      case "Speed report":
        if (this.vin == undefined || this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          ajaxCall = "get";
          body["vin"] = this.vin[this.vin.length - 1].toString();
          body["companyID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["fromDate"] = this.reports.value.fromDate.split(' ')[0] + " 00:00:00"
          body["toDate"] = this.reports.value.fromDate.split(' ')[0] + " 23:59:59"
          body["multivins"] = this.vin.toString()
          body['speedLimit'] = this.reports.value.speed.toString();
          //body['showAddress'] =this.reports.value.addressCheckbox;
          body['showAddress'] = "false";
          if (this.reports.value.condition == "") {
            body['speedCondition'] = '<';
          } else {
            body['speedCondition'] = this.reports.value.condition;
          }
          mode = "/ReportController/SpeedReport/";
          router = "speedReport";
        }
        break;

      case "Movement report":
        if (this.vin == undefined || this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["companyID"] = localStorage.corpId;
          body['seconds'] = this.reports.value.timeExceed;
          body['showAddress'] = "True";
          body['newreport'] = "";
          mode = "/report/movement";
          router = "movementReport";
        }
        break;

      case "Vehicle movement report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "post";
          this.commonService.presentLoader();
          body["vin"] = this.vin[this.vin.length - 1].toString();
          // body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00" + ' ' + this.reports.value.fromTime;
          // body["toDate"] = this.reports.value.toDate.replace('T', ' ') + ' ' + this.reports.value.toTime;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["companyID"] = localStorage.corpId;
          body['seconds'] = 900;
          body["multivins"] = this.vin + ",";
          body["newreport"] = "";
          // body['showAddress'] = "False";
          body['showAddress'] = this.reports.value.addressCheckbox;
          body['assetDatas'] = this.checkedValues;
          mode = "/ReportController/MovementReport";
          router = "vehicleMovementReport";
        }
        break;

      case "Idle violation report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.fromDate.split("T")[1] == "" || this.reports.value.toDate.replace('T', ' ') == "" || this.reports.value.toDate.split("T")[1] == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {

          ajaxCall = "post";
          this.commonService.presentLoader();
          body["vin"] = this.vin[this.vin.length - 1].toString();
          body["fromdata"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["todate"] = this.reports.value.toDate.replace('T', ' ')
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["companyID"] = localStorage.corpId;
          body['currentDate'] = "No";
          body['idealViolation'] = "00:" + ((this.reports.value.timeExceed / 60).toString().length == 1 ? '0' + (this.reports.value.timeExceed / 60) : (this.reports.value.timeExceed / 60)) + ":00"
          body["multivins"] = this.vin + ",";
          body["newreport"] = "";
          body['showAddress'] = `${this.reports.value.addressCheckbox}`;
          body['assetDatas'] = this.checkedValues;

          mode = "/ReportController/IdealViolationReport";
          router = "idleViolation";

        }
        break;
      case "Temperature report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body["userId"] = localStorage.userName;
          body["branchId"] = localStorage.corpId;
          body["companyId"] = localStorage.corpId;
          body["tempType"] = this.reports.value.sensorType;
          body["errorCode"] = "{}";
          body["tempSign"] = this.reports.value.condition;
          body['tempValue'] = this.reports.value.limit.toString();
          body['seconds'] = this.reports.value.timeExceed;
          body['newreport'] = "";
          // body['mode'] = app.entryPoint =='TTS'?"BASIC" : JSON.parse(localStorage.dashboardData)["liveDatas"][this.vin]["model"]
          body['mode'] = 'BASIC'
          mode = "/report/temperature";
          router = "temprature";
        }
        break;


      case "Door open report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          body["vin"] = this.vin;
          this.commonService.presentLoader();
        }
        body["companyId"] = localStorage.corpId
        body["userId"] = localStorage.userName
        body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
        body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/dooropen";
        router = "doorOpenReport";
        break;

      case "Door summary report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          body["vin"] = this.vin;
          this.commonService.presentLoader();
        }
        body["companyId"] = localStorage.corpId
        body["userId"] = localStorage.userName
        body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
        body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/dooropen";
        router = "doorSummaryReport";
        break;

      case "Door count report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = "All";
          body["companyId"] = localStorage.corpId
          body["userId"] = localStorage.userName
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          mode = "/report/dooropen/count";
          router = "doorCountReport";
        }
        break;

      case "Alarm report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          body["companyId"] = localStorage.corpId
          body["userId"] = localStorage.userName
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          mode = "/report/alarmsummary";
          router = "alarmreport";
        }
        break;

      case "Fuel consumption report":
        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          body["companyId"] = localStorage.corpId
          body["userId"] = localStorage.userName
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          mode = "/sensor/getfuelconsumptionreport";
          router = "fuelreport";
        }
        break;

      case "Odometer report":
        if (this.vin == undefined) {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          mode = "/report/weekodometer";
          router = "weekodometerreport";
        }
        break;

      case "Student Alert Report":
        if (this.reports.value.fromDate == undefined || this.reports.value.fromDate == '' || this.reports.value.toDate == undefined || this.reports.value.toDate == '') {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body["companyId"] = localStorage.corpId;
          body['routeType'] = this.reports.value.routetype;
          mode = "/report/studentAlertReport";
          router = "studentreport";
        }
        break;

      case "Attendance report":
        if (this.reports.value.date == undefined || this.reports.value.date == '') {
          this.commonService.presentToast('Please select the date')
        }
        else {
          this.commonService.presentLoader();
          body["vin"] = "All";
          body["date"] = this.reports.value.date.split("T")[0];
          body["companyId"] = localStorage.corpId;
          body["branchId"] = localStorage.corpId;
          mode = "/report/attendenceReport";
          router = "attendancereport";
        }
        break;

      case "Ac Report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data or plate no')
          return null;
        } else {
          this.commonService.presentLoader();
          body["vin"] = this.vin;
          // body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00" + ' ' + this.reports.value.fromTime;
          // body["toDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00" + ' ' + this.reports.value.toTime;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["companyID"] = localStorage.corpId;
          mode = "/sensor/getairconditionerreport";
          router = "acreport";
        }
        break;

      case "Consolidate summary report":

        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        }
        else if (this.vin.split(",").length > 99 && ((new Date(this.reports.value.toDate.replace('T', ' ')).getTime() -
          new Date(this.reports.value.fromDate.replace('T', ' ')).getTime()) / (1000 * 3600 * 24)) > 60) {
          this.commonService.presentToast('Date range must have only 45 days');
          return null;
        }

        else {
          this.commonService.presentLoader();


          ajaxCall = "post"
          body["vin"] = "All";
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["multivins"] = this.vin.toString();
          body["vehGroup"] = "NoneGroup";
          body["fromdate"] = this.reports.value.fromDate.split(' ')[0] + " 00:00:00"
          body["todate"] = this.reports.value.toDate.split(' ')[0] + " 23:59:59"
          body["reportType"] = "activereport";
          body["newreport"] = "";
          body["isArabic"] = languageInitializer.selectedLang == "Ar" || languageInitializer.selectedLang == "ar" ? true : false;
          body['showAddress'] = this.reports.value.addressCheckbox;
          body['assetDatas'] = this.checkedValues;
          router = "commonReport"
          mode = "/ReportController/CommonReport";

        }
        break;


      case "Hourly Consolidate summary report":

        if (this.vin == undefined || this.reports.value.fromDate == "" || this.reports.value.toDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        }
        else if (this.vin.split(",").length > 99 && ((new Date(this.reports.value.toDate.replace('T', ' ')).getTime() -
          new Date(this.reports.value.fromDate.replace('T', ' ')).getTime()) / (1000 * 3600 * 24)) > 60) {
          this.commonService.presentToast('Date range must have only 45 days');
          return null;
        }

        else {
          this.commonService.presentLoader();
          if (this.ignoreddate == '') {
            this.ignoreddate = "2011-08-06"
          }

          ajaxCall = "post"
          body["vin"] = "All";
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["multivins"] = this.vin.toString();
          body["vehGroup"] = "NoneGroup";
          body["fromdate"] = this.reports.value.fromDate.split(' ')[0] + " 00:00:00"
          body["todate"] = this.reports.value.toDate.split(' ')[0] + " 23:59:59"
          body["reportType"] = "activereport";
          body["newreport"] = "";
          body["isArabic"] = languageInitializer.selectedLang == "Ar" || languageInitializer.selectedLang == "ar" ? true : false;
          body['showAddress'] = this.reports.value.addressCheckbox;
          body['assetDatas'] = this.checkedValues;
          body["ignoreDates"] = this.ignoreddate;
          body["fTime"] = this.timeConvertion[this.reports.value.fromTimeOnly].split(' ')[0]
          body["tTime"] = this.timeConvertion[this.reports.value.toTimeOnly].split(' ')[0]
          router = "CommonReporthourly"
          mode = "/ReportController/CommonReporthourly";

        }
        break;





      case "Stop report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "" || this.reports.value.toDate.split("T")[1] == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "post"
          this.commonService.presentLoader();
          //body["vin"] = this.vin[this.vin.length - 1].toString();
          //body["fromDate"] = this.reports.value.fromDate.split("T")[0] + ' ' + this.reports.value.fromTime;
          // body["toDate"] = this.reports.value.toDate.split("T")[0] + ' ' + this.reports.value.toTime;
          // body["fromDate"] = this.reports.value.fromDate.split("T")[0] + " " + this.reports.value.fromDate.split("T")[1] + ":00"
          //body["toDate"] = this.reports.value.toDate;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body['seconds'] = this.reports.value.timeExceed;
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["showAddress"] = "false";
          body["multivins"] = this.vin.toString() + ",";
          body["currentDate"] = "No";
          body["newreport"] = "";
          body['showAddress'] = this.reports.value.addressCheckbox;
          body['assetDatas'] = this.checkedValues;
          mode = "/ReportController/StopReport";
          router = "stopReport";

        }
        break;
      case "No transmission report":
        if (this.vin == undefined) {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else if (this.entryPoint == "ATM") {
          ajaxCall = "post"
          this.commonService.presentLoader();
          body["report"] = "noTrans"
          body["vin"] = this.vin.toString();
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["entrypoint"] = this.entryPoint;
          if (this.notrnsdateis) {
            body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
            body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          }


          mode = "/report/NotransAndGsmLostReportForAtm";
          router = "notransmissionreport";
        }
        else {
          ajaxCall = "post"
          this.commonService.presentLoader();
          body["vin"] = this.vin.toString();
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["intervels"] = "1";
          body["companyRole"] = "";
          body["newreport"] = "";
          body['assetDatas'] = this.checkedValues;
          mode = "/ReportController/VehicleNoTransmissionReport";
          router = "notransmissionreport";

        }
        break;
      case "GSMLOST Report":
        ajaxCall = "post"
        this.commonService.presentLoader();
        body["report"] = "gsmlost"
        body["vin"] = this.vin.toString();
        body["companyID"] = localStorage.corpId;
        body["branchID"] = localStorage.corpId;
        body["userID"] = localStorage.userName;
        body["entrypoint"] = this.entryPoint;
        if (this.notrnsdateis) {
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        }
        mode = "/report/NotransAndGsmLostReportForAtm";
        router = "notransmissionreport";
        break;
      case "Fuel Consumption Report":
        ajaxCall = "post"
        this.commonService.presentLoader();
        body["vins"] = this.vin.toString();
        body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
        body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/fuelavg";
        router = "fuelavg";
        break;
      case "Utilization Report":
        // if (this.reports.value.stopdur == "" && this.reports.value.idledur == "") {
        //   this.commonService.presentToast('Please select the valid data');
        //   return null;
        // }
        // else {
        ajaxCall = "post"
        if (this.ignoreddate == '') {
          this.ignoreddate = "2011-08-06"
        }
        this.commonService.presentLoader();
        body["vins"] = this.vin.toString();
        body["options"] = "i";
        body["ignoreDates"] = this.ignoreddate;
        body["fromDate"] = this.reports.value.fromDate.split(" ")[0]
        body["toDate"] = this.reports.value.toDate.split(" ")[0]
        body["fTime"] = this.timeConvertion[this.reports.value.fromTimeOnly]
        body["tTime"] = this.timeConvertion[this.reports.value.toTimeOnly]

        mode = "/report/procticityReport";
        router = "UtilizationReport";
        // }
        break;
      case "User Activity Report":
        let selecteditem = []
        let selectedtypes = ''
        let selecteditem1 = []
        let selectedmodes = ''
        if (this.datatypeSelected != undefined)
          selecteditem = this.datatypeSelected.getCheckedItems();
        if (this.modeSelected != undefined)
          selecteditem1 = this.modeSelected.getCheckedItems();

        selectedtypes = selecteditem.filter(item => item.value !== "All").map(item => item.value).join(',');
        selectedmodes = selecteditem1.filter(item => item.value !== "All").map(item => item.value).join(',');
        if (selectedtypes == '') {
          this.commonService.presentToast('Please select any type')
          return null;
        } else if (selectedmodes == '') {
          this.commonService.presentToast('Please select any mode')
          return null;
        } else {
          ajaxCall = "post"
          this.commonService.presentLoader();
          body["name"] = selectedtypes
          body["companyID"] = localStorage.corpId;
          body["type"] = selectedmodes;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body["entrypoint"] = this.entryPoint;
          mode = "/report/historyData";
          router = "historydata";
        }
        break;
      case "SKT Alerts Report":

        ajaxCall = "post"
        this.commonService.presentLoader();
        body["compid"] = localStorage.corpId;
        body["fromdate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
        body["todate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/stureport";
        router = "stureport";

        break;
      case "User Logs Report":
        ajaxCall = "post"
        this.commonService.presentLoader();
        body["companyID"] = localStorage.corpId;
        body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
        body["toDate"] = this.reports.value.toDate.replace('T', ' ')
        mode = "/report/userLoginDetails";
        router = "LoginDetails";
        break;
      case "Alert MailReport":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          body = "?vin=" + this.vin + "&fromDate=" + this.reports.value.fromDate.replace('T', ' ') + ":00" + "&toDate=" + this.reports.value.toDate.replace('T', ' ');
          mode = "/report/AlertMailReport";
          router = "alertmailreport";
        }
        break;
      case "Movement Report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          body = "?vin=" + this.vin + "&fromDate=" + this.reports.value.fromDate.replace('T', ' ') + ":00" + "&toDate=" + this.reports.value.toDate.replace('T', ' ')
          mode = "/report/atmMovementReport";
          router = "atmMovementReport";
        }
        break;
      case "Status Summary Report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          body = "?vin=" + this.vin + "&fromDate=" + this.reports.value.fromDate.replace('T', ' ') + ":00" + "&toDate=" + this.reports.value.toDate.replace('T', ' ')
          mode = "/report/statusSummaryForATM";
          router = "atmStatusSummaryReport";
        }
        break;
      case "Active status report":
        // if (this.vin == undefined) {
        //   this.commonService.presentToast('Please select the valid data')
        //   return null;
        // } else {
        ajaxCall = "Post"
        this.commonService.presentLoader();
        // body["vin"] = this.vin.toString();
        body["companyId"] = localStorage.corpId;
        body["branchId"] = localStorage.corpId;
        body["userId"] = localStorage.userName;
        body["dcName"] = "vehicleDisplay",
          // body["intervels"] = "1";
          // body["companyRole"] = "";
          // body["newreport"] = "";
          mode = "/VehicleController/getVehicleDetails";
        router = "activeStatusReport";

        // }
        break;
      case "Work summary report":
        if (this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["vin"] = this.vin;
          body["compId"] = localStorage.corpId;
          body["brnchId"] = localStorage.corpId;
          body["userId"] = localStorage.userName;
          body["fromDate"] = this.reports.value.fromDate.split("T")[0]
          body["toDate"] = this.reports.value.fromDate.split("T")[0]
          body["alertType"] = "GEOFREE";
          body["newreport"] = "";
          mode = "/ReportController/MokafhaReport/";
          router = "workSummaryReport"
        }
        break;



      case "Fleet summary report":
        ajaxCall = "get";
        this.commonService.presentLoader();
        body["companyID"] = localStorage.corpId;
        body["branchID"] = localStorage.corpId;
        body["userID"] = localStorage.userName;
        // body["vin"] = "All";
        body["vin"] = this.vin;
        mode = "/ReportController/FleetSummaryReport/"
        router = "fleetSummary"
        break;

      case "Maintenance report":
        ajaxCall = "get";
        this.commonService.presentLoader();
        mode = "/report/maintainenceReport/"
        body = localStorage.corpId;
        router = "maintenance"
        break;

      case "Employee Day Off Report":
        if (this.reports.plateNo == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get";
          this.commonService.presentLoader();
          mode = "/report/empDateOffReport"
          body['companyId'] = localStorage.corpId;
          body['vin'] = "All"
          router = "employeeDayOffReport"
        }
        break;

      case "Employee Idle Status Report":
        if (this.vin == undefined || this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {

          ajaxCall = "get";
          this.commonService.presentLoader();
          body["vin"] = this.vin[this.vin.length - 1].toString();
          body["fromdata"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["todate"] = this.reports.value.toDate.replace('T', ' ')
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["companyID"] = localStorage.corpId;
          body['currentDate'] = "No";
          body['idealViolation'] = "00:" + ((this.reports.value.timeExceed / 60).toString().length == 1 ? '0' + (this.reports.value.timeExceed / 60) : (this.reports.value.timeExceed / 60)) + ":00"
          body["multivins"] = this.vin + ",";
          body["newreport"] = "";
          body['showAddress'] = "false";
          body['isMerge'] = this.reports.value.view;
          body['entryPoint'] = app.entryPoint
          mode = "/ReportController/IdealViolationReport/";
          router = "employeeIdleStatus";
        }
        break;

      case "Employee Productivity Report":
        if (this.vin == undefined || this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {

          ajaxCall = "get";
          this.commonService.presentLoader();
          body["vin"] = this.vin[this.vin.length - 1].toString();
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body["userID"] = localStorage.userName;
          body["branchID"] = localStorage.corpId;
          body["companyID"] = localStorage.corpId;
          body["multivins"] = this.vin + ",";
          body['entryPoint'] = app.entryPoint;
          body['isMerge'] = this.reports.value.view;
          mode = "/report/empDiscountReport";
          router = "employeeMonthlyDiscount";
        }
        break;

      case "Work Out Of Zone Report":
        if (this.vin == undefined || this.reports.value.fromDate.replace('T', ' ') == "" || this.reports.value.toDate.replace('T', ' ') == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          body["vin"] = this.vin[this.vin.length - 1].toString();
          // body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00" + ' ' + this.reports.value.fromTime;
          // body["toDate"] = this.reports.value.toDate.replace('T', ' ') + ' ' + this.reports.value.toTime;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body['seconds'] = this.reports.value.timeExceed;
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["showAddress"] = "false";
          body["multivins"] = this.vin.toString() + ",";
          body["currentDate"] = "No";
          body["newreport"] = "";
          body['isMerge'] = this.reports.value.view;
          body['entryPoint'] = app.entryPoint;
          mode = "/ReportController/StopReport/";
          router = "employeeWorkOutOffZone";
        }
        break;

      case "Employee No Transmission Report":
        if (this.vin == undefined) {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          body["vin"] = "";
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["intervels"] = "1";
          body["hours"] = "24"
          body["companyRole"] = "";
          body["newreport"] = "";
          body['entryPoint'] = app.entryPoint;
          mode = "/ReportController/VehicleNoTransmissionReport/";
          router = "employeeNoTransmission";

        }
        break;

      case "No transmission report (WFT)":
        if (this.vin == undefined) {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          body["vin"] = "All";
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userId"] = this.reports.value.slotWFT;
          body["fromDate"] = this.reports.value.fromDate.replace('T', ' ') + ":00"
          body["toDate"] = this.reports.value.toDate.replace('T', ' ')
          body['entryPoint'] = app.entryPoint;
          mode = "/report/empNoTransReport";
          router = "notransmissionreportWFT";

        }
        break;



      case "Operator Report":
        let fromdate = this.reports.value.fromDate;
        fromdate = fromdate.replace("T", " ")
        if (this.vin == undefined) {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          ajaxCall = "get"
          this.commonService.presentLoader();
          mode = "/report/operatorReport";
          body = "?vin=" + this.vin;
          router = "Operator Report";
        }
        break;

      case 'Temperature Report':

        if (this.reports.value.fromDate == "" || this.temperatureSensor.getSelectedItem() == null) {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          ajaxCall = "get"
          mode = '/report/TemperatureReport';
          body = '?vin=' + this.vin + '&fromDate=' + this.reports.value.fromDate.split(" ")[0] + " " + '00:00:00' + '&toDate=' + this.reports.value.fromDate.split(" ")[0] + " " + '23:59:59' + '&userId=' + localStorage.userName + '&type=' + this.temperatureSensor.getSelectedItem().value + '&temp=' + this.temperatureSensor.getSelectedItem().originalItem.temp;
          if (this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR1" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR2" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR3" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR4") {
            router = "Temperature ReportBT";
          } else {
            router = "Temperature Report";
          }

        }
        break;
      case 'Fuel Report':

        if (this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          this.commonService.presentLoader();
          this.showdate = this.reports.value.fromDate.split(" ")[0]
          ajaxCall = "get"
          mode = '/report/fuelreport';
          body = '?vin=' + this.vin + '&fromdate=' + this.reports.value.fromDate.split(" ")[0] + '&todate=' + this.reports.value.fromDate.split(" ")[0] + '&type=' + "FuelInLtr"
          router = "Fuel Report";
        }
        break;
      case 'Productivity Report':

        if (this.reports.value.fromDate == "") {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          let ignoredate = this.unselectdate.length > 0 ? this.unselectdate : "";
          this.commonService.presentLoader();
          ajaxCall = "get"
          mode = '/report/WorkingHourReport';
          body = '?vins=' + this.vin + '&fromDate=' + this.reports.value.fromDate.split(" ")[0] + '&toDate=' + this.reports.value.toDate.split(" ")[0] + '&ignoreDate=' + ignoredate + '&workingHours=' + "8" + '&userId=' + localStorage.userName
          router = "WorkdayEfficiencyReport";
        }
        break;

      case "Recurrence":
        var jsonMails = {};
        if (this.reports.value.allReportList == "" || this.reports.value.multipleEmail == "" || this.reports.value.formatXlPdf == "" || this.vin.length == 0 || this.reports.value.multipleEmail.split(",").length == 0) {
          this.commonService.presentToast('Please select the valid data')
          return null;
        } else {
          let date: any = (new Date().toJSON().split("T")[0].split("-").reverse().join())
          ajaxCall = "get";
          // this.commonService.presentLoader();
          body["companyID"] = localStorage.corpId;
          body["branchID"] = localStorage.corpId;
          body["userID"] = localStorage.userName;
          body["reportType"] = this.allReportTitleRecObj[this.reports.value.allReportList];
          body["format"] = this.reports.value.formatXlPdf;
          body["mailcount"] = this.reports.value.multipleEmail.split(",").length.toString();
          body["multivins"] = this.vin.toString();
          body["vin"] = this.vin[this.vin.length - 1];
          body["Date"] = date.replaceAll(",", "-")


          if (!this.reports.value.multipleEmail) {
            this.commonService.presentToast('Please Enter Email id');
            return;
          }

          let mail = this.reports.value.multipleEmail.split(",");

          for (let id = 0; id < mail.length; id++) {
            if (this.commonService.mailValidation.test(mail[id]))
              body["mailcount"] = mail.length.toString();
            else
              this.commonService.presentToast(this.commonService.mailerror)
          }

          if (mail.length == 1 && this.commonService.mailValidation.test(mail)) {
            jsonMails["email1"] = mail[0];
            body["email"] = this.reports.value.multipleEmail;
          }
          else {
            if (mail.length < 5) {
              let mails = '';
              for (var m = 0; m < mail.length; m++) {
                if (this.commonService.mailValidation.test(mail[m])) {
                  if (m == 0)
                    mails = mail[m] + ",";
                  else
                    mails += mail[m] + ","
                  jsonMails["email1"] = mails;
                  body["email"] = mails;

                } else
                  this.commonService.presentToast(this.commonService.mailerror)
              }
            } else
              this.commonService.presentToast('Plese Enter Only 4 Emails')
          }

          jsonMails["data"] = localStorage.corpId;
          let serviceData = JSON.stringify(jsonMails);

          let url: string = serverUrl.web + "/alert/emailvalidation/" + serviceData;
          this.commonService.presentLoader();
          this.ajaxService.ajaxGetWithString(url)
            .subscribe(res => {

              this.commonService.dismissLoader();
              var json = JSON.parse(res);
              if (!Object.values(json).includes(false)) {
                mode = "/ReportController/SaveRecurrence/"
                router = "recurrence"
                this.ajaxService.ajaxReportServices(mode, body, router, this.successCallback, ajaxCall);
              } else {
                this.commonService.presentToast(this.commonService.mailerror)
                return null;
              }
            })


        }
        break;
    }
    if (mode)
      this.ajaxService.ajaxReportServices(mode, body, router, this.successCallback, ajaxCall);

    // this.toggleView()
    // this.router.navigateByUrl("reports/commonReport");
    // this.showReport = "employeeMonthlyDiscount"
    // this.getBack();
  }

  async armoronOdometer() {
    this.getBack();
    const modal = await this.modalController.create({
      component: WeekOdometerComponent
    });
    return await modal.present();
  }

  async openGroupPlateNoTree() {
    await this.commonService.presentLoader();

    const modal = await this.modalController.create({
      component: GroupTreeStructureComponent,
      cssClass: "treeStructGrp",
      backdropDismiss: false,
      componentProps: {
        vin: this.vin == undefined || this.vin == 'All' ? "Add" : this.vin.split(","),

      }
    });

    modal.onDidDismiss().then(res => {
      var temp: any = [];
      if (res.data.plateNoData.length > 0) {
        this.vin = res.data.plateNoData.toString();
        temp = res.data.plateNoData;
        this.plateNotext = res.data.plateVin.toString().substring(0, 24);
      } else {
        this.plateNotext = this.trans.instant("Click here to select!");
        this.vin = "";
      }
      this.tempVar = temp.length;


    });

    await modal.present();
  }

  successCallback = (res: any, outputRouter: string) => {
    this.segment = "tableView";
    this.commonService.filter = undefined;
    let tempertureHeading; this.temperatureTitle = undefined;
    if (this.reportsTitle == "Temperature Report") {
      tempertureHeading = this.temperatureSensor.getSelectedItem().label;
      this.temperatureTitle = tempertureHeading;
    }
    if (res == undefined) {
      this.ajaxService.ajaxGetWithString(serverUrl.web + "/login/test")
        .subscribe(res => {
          if (res == '["Hi Web....!"]')
            console.log("server run")
          else {
            this.commonService.dismissLoader();
            this.commonService.presentAlert("Server maintanance error", "Sorry for the inconvenience please try after some times");
          }
        })

    } else {
      let validation: boolean = false;
      if ((typeof (res) == "object" && Object.keys(res).length > 0 && !Array.isArray(res)) && this.reportsTitle != "Consolidate summary report" && this.reportsTitle != "Utilization Report" && this.reportsTitle != "Hourly Consolidate summary report") {
        if (outputRouter == "overallSummaryReport") {
          for (let i = 0; i < res.summary.length; i++) {
            if (res.summary[i]["duration"] !== "0:00:00:00") {
              validation = true;
              break;
            }
          }
        }
        if (outputRouter == "commonReport" || outputRouter == "CommonReporthourly") {
          validation = true;
        }
        if (outputRouter == "UtilizationReport") {
          validation = true;
        }

        if (outputRouter == "workSummaryReport") {
          res = res.data;
          if (res.length > 0)
            validation = true;
          this.successCallback(res, outputRouter);
        }
      }

      else if (res.length > 0 || this.reportsTitle == "Consolidate summary report" || this.reportsTitle == "Utilization Report" || this.reportsTitle == "Hourly Consolidate summary report") {
        if (outputRouter == "recurrence") {
          this.getrecurrencedata();
        }

        if (outputRouter == 'alertReport') {
          let altered = []
          for (let i = 0; i < res.length; i++) {
            if (this.reports.value.alertTypeSelected.includes(res[i].alertTypes))
              altered.push(res[i])
          }
          res = altered;
          if (res.length == 0) {
            this.successCallback(res, outputRouter);
            return null;
          }
        }
        validation = true;
        this.objValues = res;

        if (this.objValues) {
          if (this.reportsTitle == "Executive summary report" && app.entryPoint != "ATM") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].begin;
              var splitData = timeStampValue.split(" ");
              var begindate = splitData[0];
              var begintime = splitData[1];
              this.objValues[i].begindate = begindate;
              this.objValues[i].begintime = begintime;

              var timeStampValue = this.objValues[i].end;
              var splitData = timeStampValue.split(" ");
              var enddate = splitData[0];
              var endtime = splitData[1];
              this.objValues[i].enddate = enddate;
              this.objValues[i].endtime = endtime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].begintime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].begintime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;

                timeStampValue = this.objValues[i].endtime;
                timeComponents = timeStampValue.split(":");
                hours = parseInt(timeComponents[0]);
                amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].endtime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          } else if (this.reportsTitle == "Temperature Report" && app.entryPoint != "ATM" && this.commonService.clocktime == true) {
            for (var i = 0; i < this.objValues.length; i++) {
              var beginTime = this.objValues[i].beginAt.split(" ");
              var beginHours = parseInt(beginTime[1]);
              var amOrPm = beginHours >= 12 ? "PM" : "AM";
              beginHours = beginHours % 12 || 12;
              var formattedHours = beginHours < 10 ? "0" + beginHours : beginHours;
              this.objValues[i].beginAt = beginTime[0] + " " + formattedHours + ":" + beginTime[1].split(':')[1] + ":" + "00" + amOrPm;

              var endTime = this.objValues[i].endAt.split(" ");
              var endHours = parseInt(endTime[1]);
              var pmoram = endHours >= 12 ? "PM" : "AM";
              endHours = endHours % 12 || 12;
              var formatedend = endHours < 10 ? "0" + endHours : endHours;
              this.objValues[i].endAt = endTime[0] + " " + formatedend + ":" + endTime[1].split(':')[1] + ":" + "00" + pmoram;
            }
          }
          else if (this.reportsTitle == "No transmission report" && app.entryPoint != "ATM") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].lastTransTime;
              var splitData = timeStampValue.split(" ");
              var lastTransTimedate = splitData[0];
              var lastTransTimetime = splitData[1];
              this.objValues[i].lastTransTimedate = lastTransTimedate;
              this.objValues[i].lastTransTimetime = lastTransTimetime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].lastTransTimetime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].lastTransTimetime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Stop report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].begin;
              var splitData = timeStampValue.split(" ");
              var begindate = splitData[0];
              var begintime = splitData[1];
              this.objValues[i].begindate = begindate;
              this.objValues[i].begintime = begintime;

              var timeStampValue = this.objValues[i].end;
              var splitData = timeStampValue.split(" ");
              var enddate = splitData[0];
              var endtime = splitData[1];
              this.objValues[i].enddate = enddate;
              this.objValues[i].endtime = endtime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].begintime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].begintime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;

                timeStampValue = this.objValues[i].endtime;
                timeComponents = timeStampValue.split(":");
                hours = parseInt(timeComponents[0]);
                amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].endtime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Overall summary report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].begin;
              var splitData = timeStampValue.split(" ");
              var begindate = splitData[0];
              var begintime = splitData[1];
              this.objValues[i].begindate = begindate;
              this.objValues[i].begintime = begintime;

              var timeStampValue = this.objValues[i].end;
              var splitData = timeStampValue.split(" ");
              var enddate = splitData[0];
              var endtime = splitData[1];
              this.objValues[i].enddate = enddate;
              this.objValues[i].endtime = endtime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].begintime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].begintime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;

                timeStampValue = this.objValues[i].endtime;
                timeComponents = timeStampValue.split(":");
                hours = parseInt(timeComponents[0]);
                amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].endtime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Engine Hours summary report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].startTime;
              var splitData = timeStampValue.split(" ");
              var Startdate = splitData[0];
              var Starttime = splitData[1];
              this.objValues[i].Startdate = Startdate;
              this.objValues[i].Starttime = Starttime;

              var timeStampValue = this.objValues[i].endTime;
              var splitData = timeStampValue.split(" ");
              var enddate = splitData[0];
              var endtime = splitData[1];
              this.objValues[i].enddate = enddate;
              this.objValues[i].endtime = endtime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].Starttime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].Starttime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;

                timeStampValue = this.objValues[i].endtime;
                timeComponents = timeStampValue.split(":");
                hours = parseInt(timeComponents[0]);
                amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].endtime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Speed report") {
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].time;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].time = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Status summary report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].Begin;
              var splitData = timeStampValue.split(" ");
              var begindate = splitData[0];
              var begintime = splitData[1];
              this.objValues[i].begindate = begindate;
              this.objValues[i].begintime = begintime;

              var timeStampValue = this.objValues[i].End;
              var splitData = timeStampValue.split(" ");
              var enddate = splitData[0];
              var endtime = splitData[1];
              this.objValues[i].enddate = enddate;
              this.objValues[i].endtime = endtime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].begintime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].begintime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;

                timeStampValue = this.objValues[i].endtime;
                timeComponents = timeStampValue.split(":");
                hours = parseInt(timeComponents[0]);
                amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].endtime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Alert report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].timeStamp;
              var splitData = timeStampValue.split(" ");
              var date = splitData[0];
              var time = splitData[1];
              this.objValues[i].date = date;
              this.objValues[i].time = time;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].time;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].time = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }

          else if (this.reportsTitle == "Fence Report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].timeStamp;
              var splitData = timeStampValue.split(" ");
              var date = splitData[0];
              var time = splitData[1];
              this.objValues[i].date = date;
              this.objValues[i].time = time;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].time;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].time = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Maintenance Alert") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].timeStamp;
              var splitData = timeStampValue.split(" ");
              var date = splitData[0];
              var time = splitData[1];
              this.objValues[i].date = date;
              this.objValues[i].time = time;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].time;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].time = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Idle violation report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].Begin;
              var splitData = timeStampValue.split(" ");
              var begindate = splitData[0];
              var begintime = splitData[1];
              this.objValues[i].begindate = begindate;
              this.objValues[i].begintime = begintime;

              var timeStampValue = this.objValues[i].End;
              var splitData = timeStampValue.split(" ");
              var enddate = splitData[0];
              var endtime = splitData[1];
              this.objValues[i].enddate = enddate;
              this.objValues[i].endtime = endtime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].begintime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].begintime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;

                timeStampValue = this.objValues[i].endtime;
                timeComponents = timeStampValue.split(":");
                hours = parseInt(timeComponents[0]);
                amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].endtime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Overspeed duration report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].start;
              var splitData = timeStampValue.split(" ");
              var startdate = splitData[0];
              var starttime = splitData[1];
              this.objValues[i].startdate = startdate;
              this.objValues[i].starttime = starttime;

              var timeStampValue = this.objValues[i].end;
              var splitData = timeStampValue.split(" ");
              var enddate = splitData[0];
              var endtime = splitData[1];
              this.objValues[i].enddate = enddate;
              this.objValues[i].endtime = endtime;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].starttime;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].starttime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;

                timeStampValue = this.objValues[i].endtime;
                timeComponents = timeStampValue.split(":");
                hours = parseInt(timeComponents[0]);
                amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].endtime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Vehicle movement report") {
            for (var i = 0; i < this.objValues.length; i++) {
              var timeStampValue = this.objValues[i].timeStamp;
              var splitData = timeStampValue.split(" ");
              var date = splitData[0];
              var time = splitData[1];
              this.objValues[i].date = date;
              this.objValues[i].time = time;
            }
            if (this.commonService.clocktime == true) {
              for (var i = 0; i < this.objValues.length; i++) {
                var timeStampValue = this.objValues[i].time;
                var timeComponents = timeStampValue.split(":");
                var hours = parseInt(timeComponents[0]);
                var amOrPm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                var formattedHours = hours < 10 ? "0" + hours : hours;
                this.objValues[i].time = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
              }
            }
          }
          else if (this.reportsTitle == "Operator Report") {
            for (var i = 0; i < this.objValues.length; i++) {
              if (this.objValues[i].tripStartTime != "Till Now") {
                var timeStampValue = this.objValues[i].tripStartTime;
                var splitData = timeStampValue.split(" ");
                var tripStartdate = splitData[0];
                var tripStarttime = splitData[1];
                this.objValues[i].tripStartdate = tripStartdate;
                this.objValues[i].tripStarttime = tripStarttime;
              }
              else {
                this.objValues[i].tripStartdate = this.objValues[i].tripStartTime;
                this.objValues[i].tripStarttime = this.objValues[i].tripStartTime;
              }

              var timeStampValue = this.objValues[i].tripStopTime;
              if (this.objValues[i].tripStopTime != "Till Now") {
                var splitData = timeStampValue.split(" ");
                var tripStopdate = splitData[0];
                var tripStoptime = splitData[1];
                this.objValues[i].tripStopdate = tripStopdate;
                this.objValues[i].tripStoptime = tripStoptime;
              }
              else {
                this.objValues[i].tripStopdate = this.objValues[i].tripStopTime;
                this.objValues[i].tripStoptime = this.objValues[i].tripStopTime
              }
            }

            if (this.commonService.clocktime === true) {

              for (var i = 0; i < this.objValues.length; i++) {
                var trip = this.objValues[i];

                if (trip.tripStarttime !== "Till Now") {
                  var timeComponents = trip.tripStarttime.split(":");
                  var hours = parseInt(timeComponents[0]);
                  var amOrPm = hours >= 12 ? "PM" : "AM";
                  hours = hours % 12 || 12;
                  var formattedHours = hours < 10 ? "0" + hours : hours;
                  trip.tripStarttime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
                } else {
                  trip.tripStarttime = "Till Now";
                }

                if (trip.tripStoptime !== "Till Now") {
                  var timeComponents = trip.tripStoptime.split(":");
                  var hours = parseInt(timeComponents[0]);
                  var amOrPm = hours >= 12 ? "PM" : "AM";
                  hours = hours % 12 || 12;
                  var formattedHours = hours < 10 ? "0" + hours : hours;
                  trip.tripStoptime = formattedHours + ":" + timeComponents[1] + ":" + timeComponents[2] + " " + amOrPm;
                } else {
                  trip.tripStoptime = "Till Now";
                }
              }
            }
          }

        }
        this.odj.length = 0;
        if (this.reportsTitle == "Consolidate summary report" || this.reportsTitle == "Hourly Consolidate summary report") {

          this.objValues = res.Summary
          if (res && res.hasOwnProperty('OVERSPEED')) {
            this.overspeed = res.OVERSPEED
            this.odjosp = []
            this.excelTitleosp = {
              'Plate No': 0, 'Group': 1, 'Operator': 2, 'Date': 3, 'Violation Place': 4, 'Violation Count': 5, 'Violation Speed': 6, 'Violation Time': 7, 'Max Speed': 8,
            }
            this.overspeed.map((res: any) => {
              this.odjosp.push([res['Plate No'], res.Group, res.Operator, res.Date,
              res['Violation Place'], res['Violation Count'],
              res['Violation Speed'], res['Violation Time'], res['Max Speed']])
            });
          } else {
            this.odjosp = []
          }
          if (res && res.hasOwnProperty('HARSHBRAKING')) {
            this.harshbraking = res.HARSHBRAKING
            this.odjhb = []
            this.excelTitlehb = {
              'Plate No': 0, 'Group': 1, 'Operator': 2, 'Date': 3, 'Violation Place': 4, 'Violation Count': 5, 'Violation Speed': 6, 'Violation Time': 7, 'Max Speed': 8,
            }
            this.harshbraking.map((res: any) => {
              this.odjhb.push([res['Plate No'], res.Group, res.Operator, res.Date,
              res['Violation Place'], res['Violation Count'],
              res['Violation Speed'], res['Violation Time'], res['Max Speed']])
            });
          } else {
            this.odjhb = []
          }
          if (res && res.hasOwnProperty('HARSHACCELERATION')) {
            this.harshacceleration = res.HARSHACCELERATION
            this.odjha = []
            this.excelTitleha = {
              'Plate No': 0, 'Group': 1, 'Operator': 2, 'Date': 3, 'Violation Place': 4, 'Violation Count': 5, 'Violation Speed': 6, 'Violation Time': 7, 'Max Speed': 8,
            }
            this.harshacceleration.map((res: any) => {
              this.odjha.push([res['Plate No'], res.Group, res.Operator, res.Date,
              res['Violation Place'], res['Violation Count'],
              res['Violation Speed'], res['Violation Time'], res['Max Speed']])
            });
          } else {
            this.odjha = []
          }
          if (res && res.hasOwnProperty('DRIFT')) {
            this.drift = res.DRIFT
            this.odjd = []
            this.excelTitled = {
              'Plate No': 0, 'Group': 1, 'Operator': 2, 'Date': 3, 'Violation Place': 4, 'Violation Count': 5, 'Violation Speed': 6, 'Violation Time': 7, 'Max Speed': 8,
            }
            this.drift.map((res: any) => {
              this.odjd.push([res['Plate No'], res.Group, res.Operator, res.Date,
              res['Violation Place'], res['Violation Count'],
              res['Violation Speed'], res['Violation Time'], res['Max Speed']])
            });
          } else {
            this.odjd = []
          }
          if (res && res.hasOwnProperty('SEATBELT')) {
            this.seatbelt = res.SEATBELT
            this.odjsb = []
            this.excelTitlesb = {
              'Plate No': 0, 'Group': 1, 'Operator': 2, 'Date': 3, 'Violation Place': 4, 'Violation Count': 5, 'Violation Speed': 6, 'Violation Time': 7, 'Max Speed': 8,
            }
            this.seatbelt.map((res: any) => {
              this.odjsb.push([res['Plate No'], res.Group, res.Operator, res.Date,
              res['Violation Place'], res['Violation Count'],
              res['Violation Speed'], res['Violation Time'], res['Max Speed']])
            });
          } else {
            this.odjsb = []
          }

        }
        if (this.reportsTitle == "Utilization Report") {
          let stopDur = res.stopDur ? res.stopDur : [];
          let idelDur = res.runDur ? res.runDur : [];
          this.objValues = [...stopDur, ...idelDur]
          if (res && res.hasOwnProperty('runDur')) {
            this.runDur = res.runDur
            this.odjrun = []
            this.excelTitlerun = {
              'Plate No': 0, 'Total Selected Days': 1, 'Total Hours': 2, 'Used Hours': 3, 'Unused Hours': 4, 'Used Percentage(Running&Idle)': 5, 'Unused Percentage(Stop)': 6
            }
            this.runDur.map((res: any) => {
              this.odjrun.push([res.plateno, res.totalDays, res.totalHours, res.used, res.unused, res.percentage, res.unusedpercentage])
            });
          } else {
            this.odjrun = []
          }


        }
        if (Array.isArray(res))
          this.objValues.sort((a, b) => (a.timeStamp - b.timeStamp))
        for (let i = 0; i < this.objValues.length; i++) {
          if (this.reportsTitle == "Movement report") {
            this.head = ['Plate No', 'Speed', 'Status', 'Time', 'Operator Name', 'Address'];
            this.excelTitle = {
              'Plate No': 0, 'Speed': 1, 'Status': 2, 'Time': 3, 'Operator Name': 4,
              'Address': 5
            }
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].speed,
            this.objValues[i].status, this.objValues[i].timeStamp, this.objValues[i].operatorName,
            this.objValues[i].emailAddress])

          } else if (this.reportsTitle == "Consolidate summary report") {
            this.excelTitle = {
              'Plate No': 0, 'Date': 1, 'Odometer': 2, 'Alert Count': 3, 'Max Speed': 4,
              'Running duration': 5, 'Stop duration': 6, 'Idle duration': 7, 'Towed duration': 8, 'Begin Location': 9, 'End Location': 10
            }
            this.odj.push([this.objValues[i]['Plate No'], this.objValues[i].Date,
            this.objValues[i].Odometer, this.objValues[i]['Alert Count'], this.objValues[i]['Max Speed'],
            this.objValues[i]['Running Duration'], this.objValues[i]['Stop Duration'], this.objValues[i]['Idle Duration'],
            this.objValues[i]['Towed Duration'], this.objValues[i]['Begin Location'], this.objValues[i]['End Location']])
          }else if (this.reportsTitle == "Hourly Consolidate summary report") {
            this.excelTitle = {
              'Plate No': 0, 'Date': 1, 'Odometer': 2, 'Alert Count': 3,
              'Running duration': 4, 'Stop duration': 5, 'Idle duration': 6, 'Towed duration': 7, 'Begin Location': 8, 'End Location': 9
            }
            this.odj.push([this.objValues[i]['Plate No'], this.objValues[i].Date,
            this.objValues[i].Odometer, this.objValues[i]['Alert Count'],
            this.objValues[i]['Running Duration'], this.objValues[i]['Stop Duration'], this.objValues[i]['Idle Duration'],
            this.objValues[i]['Towed Duration'], this.objValues[i]['Begin Location'], this.objValues[i]['End Location']])
          }
          else if (this.reportsTitle == "Overall summary report") {

            this.excelTitle = {
              'Plate No': 0, 'Begin Date': 1, 'Begin Time': 2, 'Begin Location': 3, 'End Date': 4, 'End Time': 5, 'End Location': 6,
              'Max Speed': 7, 'Odometer': 8, 'Running': 9, 'Stop': 10, 'Towed': 11
            }

            this.head = ['Plate No', 'Begin Date', 'Begin Time', 'Begin Location', 'End Date', 'End Time', 'End Location', 'Max Speed', 'Odometer', 'Running', 'Stop', 'Towed'];

            this.odj.push([this.objValues[i].plateNo, this.objValues[i].begindate, this.objValues[i].begintime,
            this.objValues[i].beginLocation, this.objValues[i].enddate, this.objValues[i].endtime, this.objValues[i].endLocation,
            this.objValues[i].maxSpeed, this.objValues[i].odometer, this.objValues[i].runningDuration,
            this.objValues[i].stopDuration, this.objValues[i].towedDuration])
          } else if (this.reportsTitle == "Trip summary report") {
            this.head = ['Plate no', 'Start time', 'Start location', 'End time', 'End location', 'Odometer', 'Running', 'Idle'];
            this.excelTitle = { 'Plate no': 0, 'Start time': 1, 'Start location': 2, 'End time': 3, 'End location': 4, 'Odometer': 5, 'Running': 6, 'Idle': 7 }
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].startTime, this.objValues[i].startAddress, this.objValues[i].endTime, this.objValues[i].stopAddress, this.objValues[i].odometer,
            this.objValues[i].runningDuration, this.objValues[i].idleDuration])
          } else if (this.reportsTitle == "Engine Hours summary report") {
            this.head = ['Plate no', 'Start Date', 'Start time', 'Start location', 'End Date', 'End time', 'End location', 'Odometer', 'Running', 'Idle'];
            this.excelTitle = { 'Plate no': 0, 'Start Date': 1, 'Start time': 2, 'Start location': 3, 'End Date': 4, 'End time': 5, 'End location': 6, 'Odometer': 7, 'Running': 8, 'Idle': 9 }
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].Startdate, this.objValues[i].Starttime, this.objValues[i].startAddress, this.objValues[i].enddate, this.objValues[i].endtime, this.objValues[i].stopAddress, this.objValues[i].odometer,
            this.objValues[i].runningDuration, this.objValues[i].idleDuration])
          }
          else if (this.reportsTitle == "Executive summary report") {
            if (this.entryPoint != "ATM") {
              this.excelTitle = {
                'Plate No': 0, 'Begin Date': 1, 'Begin Location': 2, 'End Date': 3, 'End Location': 4, "Alert count": 5,
                'Max Speed': 6, 'Odometer': 7, 'Running': 8, 'Stop': 9, 'Towed': 10, 'Idle': 11
              }
              this.head = ['Plate No', 'Begin Date', 'Begin Location', 'End Date', 'End Location', "Alert count", 'Max Speed', 'Odometer', 'Running', 'Stop', 'Towed', 'Idle'];
              let count = this.objValues[i].totalCount ? this.objValues[i].totalCount : "0";
              this.odj.push([this.objValues[i].plateNo, this.objValues[i].begindate,
              this.objValues[i].beginLocation, this.objValues[i].enddate, this.objValues[i].endLocation, count,
              this.objValues[i].maxSpeed, this.objValues[i].odometer, this.objValues[i].runningDuration,
              this.objValues[i].stopDuration, this.objValues[i].towedDuration, this.objValues[i].idleDuration])
            } else if (this.entryPoint == "ATM") {
              this.excelTitle = {
                'ATM Id': 0, 'Begin AT': 1, 'End At': 2, 'Begin Location': 3, 'End Location': 4, 'Alerts Count': 5, 'PowerON': 6, "PowerCut": 7,
                'Movement': 8, 'Battery Drain': 9, 'Low Battery': 10
              }
              this.head = ['ATM Id', 'Begin AT', 'End At', 'Begin Location', 'End Location', 'Alerts Count', 'PowerON', "PowerCut", 'Movement', 'Battery Drain', 'Low Battery'];
              let count = this.objValues[i].totalCount ? this.objValues[i].totalCount : "0";
              this.odj.push([this.objValues[i].ATMId, this.objValues[i].beginTime, this.objValues[i].endTime,
              this.objValues[i].beginLocation, this.objValues[i].endLocation, count, this.objValues[i].PowerONDur,
              this.objValues[i].PowerCutDur, this.objValues[i].MovementDur,
              this.objValues[i].BatteryDrainDur, this.objValues[i].LowBatteryDur])
            }
          }

          else if (this.reportsTitle == "Driver behaviour report") {

            this.selectedDate = this.reports.value.fromDate
            this.head = ['Plate No', 'Harsh Break', 'Seat Belt', 'Harsh Acceleration', 'Drift',
              'Travelled KM', 'Engine On', 'Running', 'Idle', 'AvgSpeed',
              'Top Speed', 'Over All Score'];
            this.excelTitle = {
              'Plate No': 0, 'Harsh Break': 1, 'Seat Belt': 2, 'Harsh Acceleration': 3, 'Drift': 4,
              'Travelled KM': 5, 'Engine On': 6, 'Running': 7, 'Idle': 8, 'AvgSpeed': 9,
              'Top Speed': 10, 'Over All Score': 11
            }
            this.odj.push([
              this.objValues[i].plateNo,
              this.objValues[i].HARSHBRAKING,
              this.objValues[i].SEATBELT,
              this.objValues[i].HARSHACCELERATION,
              this.objValues[i].DRIFT,
              this.objValues[i].distanceTravelled,
              this.objValues[i].engineOnDuration,
              this.objValues[i].runningDuration,
              this.objValues[i].excessiveIdling,
              this.objValues[i].avgSpeed,
              this.objValues[i].topSpeed,
              this.objValues[i].overAllScore,
            ])
          }


          else if (this.reportsTitle == "Speed report") {

            this.head = ['Plate No', 'Date', 'Time', 'Speed', 'Operator', 'Limit Exceeds'];
            this.excelTitle = { 'Plate No': 0, 'Date': 1, 'Time': 2, 'Speed': 3, 'Operator': 4, 'Limit Exceeds': 5 }
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].date,
            this.objValues[i].time, this.objValues[i].speed, this.objValues[i].operator,
            this.objValues[i].descripition])
          }
          else if (this.reportsTitle == "Productivity Report") {

            this.head = ['Plate No', 'Actual Working Hours', 'Percentage', 'Working Days', 'Scheduled WorkingHours'];
            this.excelTitle = { 'Plate No': 0, 'Actual Working Hours': 1, 'Percentage': 2, 'Working Days': 3, 'Scheduled WorkingHours': 4 }
            this.odj.push([this.objValues[i].PlateNo, this.objValues[i]['Actual WorkingHours'],
            this.objValues[i].Percentage, this.objValues[i]['scheduled WorkingDays'], this.objValues[i]['scheduled WorkingHours']])
          }
          else if (this.reportsTitle == "Status summary report") {

            this.head = ['Plate No', 'Distance', 'Start Date', 'Start Time', 'Begin Location', 'End Date', 'End Time', 'End Location', 'Stop', 'Towed', 'Running', 'Idle'];
            this.excelTitle = { 'Plate No': 0, 'Distance': 1, 'Start Date': 2, 'Start Time': 3, 'Begin Location': 4, 'End Date': 5, 'End Time': 6, 'End Location': 7, 'Stop': 8, 'Towed': 9, 'Running': 10, 'Idle': 11 }
            this.odj.push([this.objValues[i]['Plate No'], this.objValues[i].Odometer, this.objValues[i].begindate, this.objValues[i].begintime, this.objValues[i]['Begin At'],
            this.objValues[i].enddate, this.objValues[i].endtime, this.objValues[i]['End At'], this.objValues[i].Stop, this.objValues[i].Towed,
            this.objValues[i].Running, this.objValues[i].Idle])
          }
          else if (this.reportsTitle == "Alert report" || this.reportsTitle == "Fence Report" || this.reportsTitle == "Maintenance Alert") {
            if (app.entryPoint != "WFT" && app.entryPoint != "ATM") {
              this.head = ['Plate No', 'Alert Types', 'Address', 'Additional Info', 'Alert Date', 'Alert Time'];
              this.excelTitle = { 'Plate No': 0, 'Alert Types': 1, 'Address': 2, 'Additional Info': 3, 'Alert Date': 4, 'Alert Time': 5 }
              this.odj.push([this.objValues[i].plateNo,
              this.objValues[i].alertTypes, this.objValues[i].address, this.objValues[i].additionalInfo, this.objValues[i].date, this.objValues[i].time])
            } else if (app.entryPoint == "ATM") {
              this.head = ['ATM ID', 'Alert Types', 'Address', 'Additional Info', 'Date & Time'];
              this.excelTitle = { 'ATM ID': 0, 'Alert Types': 1, 'Address': 2, 'Additional Info': 3, 'Date & Time': 4 }
              this.odj.push([this.objValues[i].plateNo,
              this.objValues[i].alertTypes, this.objValues[i].address, this.objValues[i].additionalInfo, this.objValues[i].timeStamp])
            }
            else {
              this.head = ['Plate No', 'Alert Types', 'Address', 'Alert Date', 'Alert Time'];
              this.excelTitle = { 'Plate No': 0, 'Alert Types': 1, 'Address': 2, 'Alert Date': 3, 'Alert Time': 4 }
              this.odj.push([this.objValues[i].plateNo.split("@,@")[0] + `(${this.objValues[i].plateNo.split("@,@")[1]})`,
              this.objValues[i].alertTypes, this.objValues[i].address, this.objValues[i].date, this.objValues[i].time])
            }
          }

          else if (this.reportsTitle == "Idle violation report") {

            this.head = ['Plate No', 'Idle', 'Begin Date', 'Begin Time', 'Begin At', 'End Date', 'End Time', 'End At',];
            this.excelTitle = {
              'Plate No': 0, 'Idle': 1, 'Begin Date': 2, 'Begin Time': 3, 'Begin At': 4, 'End Date': 5, 'End Time': 6, 'End At': 7,
            }

            this.odj.push([this.objValues[i]["Plate No"], this.objValues[i].Idle, this.objValues[i]["begindate"], this.objValues[i]["begintime"], this.objValues[i]["Begin At"], this.objValues[i]["enddate"], this.objValues[i]["endtime"], this.objValues[i]['End At'],
            ])
          }
          else if (this.reportsTitle == "Overspeed duration report") {
            this.head = ['Plate No', 'Over Speed Duration', 'Start Date', 'Start Time', 'End Date', 'End Time', 'Min Speed', 'Max Speed', 'Avg Speed',];
            this.excelTitle = {
              'Plate No': 0, 'Over Speed Duration': 1, 'Start Date': 2, 'Start Time': 3, 'End Date': 4, 'End Time': 5, 'Min Speed': 6, 'Max Speed': 7, 'Avg Speed': 8
            }

            this.odj.push([this.objValues[i].plateNo, this.objValues[i].duration, this.objValues[i].startdate, this.objValues[i].starttime, this.objValues[i].enddate, this.objValues[i].endtime, this.objValues[i].min,
            this.objValues[i].max, this.objValues[i].avg])
          } else if (this.reportsTitle == "Temperature report") {
            this.head = [];
            this.excelTitle = {}
            if (this.objValues[i].TEMPERATURESENSOR4) {
              this.head = ['Plate No', 'Timestamp', 'Temprature Sensor 1', 'Temprature Sensor 2', 'Temprature Sensor 3', 'Temprature Sensor 4']
              this.excelTitle = {
                'Plate No': 0, 'Timestamp': 1, 'Temprature Sensor 1': 2, 'Temprature Sensor 2': 3, 'Temprature Sensor 3': 4,
                'Temprature Sensor 4': 5
              }
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].TimeStamp,
              "Name:" + this.objValues[i].TEMPERATURESENSOR1.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR1.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR1.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR1value,
              "Name:" + this.objValues[i].TEMPERATURESENSOR2.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR2.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR2.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR2value,
              "Name:" + this.objValues[i].TEMPERATURESENSOR3.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR3.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR3.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR3value,
              "Name:" + this.objValues[i].TEMPERATURESENSOR4.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR4.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR4.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR4value])
            } else if (this.objValues[i].TEMPERATURESENSOR3) {
              this.head = ['Plate No', 'Timestamp', 'Temprature Sensor 1', 'Temprature Sensor 2', 'Temprature Sensor 3']
              this.excelTitle = {
                'Plate No': 0, 'Timestamp': 1, 'Temprature Sensor 1': 2, 'Temprature Sensor 2': 3, 'Temprature Sensor 3': 4
              }
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].TimeStamp,
              "Name:" + this.objValues[i].TEMPERATURESENSOR1.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR1.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR1.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR1value,
              "Name:" + this.objValues[i].TEMPERATURESENSOR2.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR2.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR2.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR2value,
              "Name:" + this.objValues[i].TEMPERATURESENSOR3.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR3.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR3.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR3value])
            } else if (this.objValues[i].TEMPERATURESENSOR2) {
              this.excelTitle = {
                'Plate No': 0, 'Timestamp': 1, 'Temprature Sensor 1': 2, 'Temprature Sensor 2': 3
              }
              this.head = ['Plate No', 'Timestamp', 'Temprature Sensor 1', 'Temprature Sensor 2']
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].TimeStamp,
              "Name:" + this.objValues[i].TEMPERATURESENSOR1.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR1.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR1.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR1value,
              "Name:" + this.objValues[i].TEMPERATURESENSOR2.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR2.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR2.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR2value])
            } else if (this.objValues[i].TEMPERATURESENSOR1) {
              this.excelTitle = {
                'Plate No': 0, 'Timestamp': 1, 'Temprature Sensor 1': 2
              }
              this.head = ['Plate No', 'Timestamp', 'Temprature Sensor 1']
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].TimeStamp,
              "Name:" + this.objValues[i].TEMPERATURESENSOR1.name + ", Min:" + this.objValues[i].TEMPERATURESENSOR1.min + ", Max:" + this.objValues[i].TEMPERATURESENSOR1.max + ", Value:" + this.objValues[i].TEMPERATURESENSOR1value]);
            }

          } else if (this.reportsTitle == "Door open report") {
            this.head = ['Plate No', "Timestamp"];
            this.excelTitle = {
              'Plate No': 0, 'Timestamp': 1
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].timeStamp])

          }
          else if (this.reportsTitle == "Fuel Report") {
            this.head = ['Plate No', "Fuel Level", "Begin At", "End At", "Begin Location", "End Location"];
            this.excelTitle = {
              'Plate No': 0, 'Fuel Level': 1, 'Begin At': 2, "End At": 3, "Begin Location": 4, "End Location": 5
            };
            this.odj.push([this.objValues[i].plateno, this.objValues[i].feul, this.objValues[i].startat, this.objValues[i].endat, this.objValues[i].startLoc, this.objValues[i].endLoc])

          } else if (this.reportsTitle == "Door summary report") {
            this.head = ['Plate No', "Door Status", "Time Duration", "Start Time", "End Time"];
            this.excelTitle = {
              'Plate No': 0, "Door Status": 1, "Time Duration": 2, "Start Time": 3, "End Time": 4
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].DoorStatus, this.objValues[i].timeDuration,
            this.objValues[i].startTimeStamp, this.objValues[i].endTimeStamp])
          }
          else if (this.reportsTitle == "Door count report") {
            this.head = ['Plate No', "Door Open Count"];
            this.excelTitle = {
              'Plate No': 0, "Door Open Count": 1
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].count])
          }
          else if (this.reportsTitle == "Alarm report") {
            this.head = ['Plate No', "Alert Type", "Status", "Start Time", "End Time", "Time Duration"];
            this.excelTitle = {
              'Plate No': 0, "Alert Type": 1, "Status": 2, "Start Time": 3, "End Time": 4, "Time Duration": 5
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].AlertType, this.objValues[i].status,
            this.objValues[i].startTime, this.objValues[i].endTime, this.objValues[i].duration])
          }
          else if (this.reportsTitle == "Stop report") {
            this.head = ["Plate no", "Stop At Date", "Stop At Time", "Started Again At Date", "Started Again At Time", "Duration", "Location"];
            this.excelTitle = {
              'Plate no': 0, 'Stop At Date': 1, 'Stop At Time': 2, 'Started Again At Date': 3, 'Started Again At Time': 4, 'Duration': 5, 'Location': 6
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].begindate, this.objValues[i].begintime, this.objValues[i].enddate, this.objValues[i].endtime,
            this.objValues[i].stopDur, this.objValues[i].endLocation])
          }
          else if (this.reportsTitle == "Fleet summary report") {
            this.head = ["Plate no", "Operator name", "Date of purchase", "Date Of Vehicle Associate", "Location"];
            this.excelTitle = {
              'Plate no': 0, "Operator name": 1, "Date of purchase": 2, "Date Of Vehicle Associate": 3, "Location": 4
            };
            this.odj.push([this.objValues[i]["Plate No"], this.objValues[i]["Operator Name"], this.objValues[i]["Date Of Purchase"],
            this.objValues[i]["Date Of Vehicle Associate"], this.objValues[i].Location])
          }
          else if (this.reportsTitle == "No transmission report") {
            if (app.entryPoint == "ATM") {
              this.head = ["ATM ID", "Device Model", "Address", "Device IMEI No", "Sim Card Number", "Last Transmission Date", "Manufacturer", "Date Of Purchase"];
              this.excelTitle = {
                'ATM ID': 0, "Device Model": 1, "Device IMEI No": 2, "Address": 3, "Sim Card Number": 4, "Last Transmission Date": 5, "Manufacturer": 6, "Date Of Purchase": 7,
              };
              this.odj.push([this.objValues[i].AtmId, this.objValues[i].ModelName, this.objValues[i].Address, this.objValues[i].imeiNo, this.objValues[i].simNo,
              this.objValues[i].lastTransmission, this.objValues[i].ManufacturerName, this.objValues[i].DateOfpurchase,])
            } else {
              this.head = ["Plate No", "Last Transmission Date", "Last Transmission Time", "Manufacturer"];
              this.excelTitle = {
                'Plate No': 0, "Last Transmission Date": 1, "Last Transmission Time": 2, "Manufacturer": 3,
              };
              this.odj.push([this.objValues[i].plateNumber,
              this.objValues[i].lastTransTimedate, this.objValues[i].lastTransTimetime, this.objValues[i].ManufacturerName])
            }
          } else if (this.reportsTitle == "Alert MailReport") {
            if (app.entryPoint == "ATM") {
              this.head = ["ATM ID", "AlertType", "Date", "Email1", "Email1 Status", "Email2", "Email2 Status"];
              this.excelTitle = {
                'ATM ID': 0, "AlertType": 1, "Date": 2, "Email1": 3, "Email1 Status": 4, "Email2": 5, "Email2 Status": 6,
              };
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].alertType, this.objValues[i].Date, this.objValues[i].Mail1,
              this.objValues[i].Mail1Result, this.objValues[i].Mail2, this.objValues[i].Mail2Result,])
            } else {
              this.head = ["Plate No", "AlertType", "Date", "Email1", "Email1 Status", "Email2", "Email2 Status"];
              this.excelTitle = {
                'Plate No': 0, "AlertType": 1, "Date": 2, "Email1": 3, "Email1 Status": 4, "Email2": 5, "Email2 Status": 6,
              };
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].alertType, this.objValues[i].Date, this.objValues[i].Mail1,
              this.objValues[i].Mail1Result, this.objValues[i].Mail2, this.objValues[i].Mail2Result,])
            }
          }
          else if (this.reportsTitle == "Temperature Report") {
            if (this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR1" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR2" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR3" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR4") {
              this.head = ['PlateNo', tempertureHeading, 'Humidity', 'Start at', 'End at', 'Start location', 'End Location'];
              this.excelTitle = {
                'PlateNo': 0, 'Temperature value': 1, 'Humitidy': 2, 'Begin at': 3, 'End at': 4, 'Start location': 5, 'End Location': 6,
              };
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].TEMP, this.objValues[i].humidity, this.objValues[i].beginAt, this.objValues[i].endAt, this.objValues[i].beginLocation,
              this.objValues[i].endLocation])
            }
            else {
              this.head = ['PlateNo', tempertureHeading, 'Start at', 'End at', 'Start location', 'End Location'];
              this.excelTitle = {
                'PlateNo': 0, 'Temperature value': 1, 'Begin at': 2, 'End at': 3, 'Start location': 4, 'End Location': 5,
              };
              this.odj.push([this.objValues[i].PlateNo, this.objValues[i].TEMP, this.objValues[i].beginAt, this.objValues[i].endAt, this.objValues[i].beginLocation,
              this.objValues[i].endLocation])
            }


          }

          else if (this.reportsTitle == "GSMLOST Report") {

            this.head = ["ATM ID", "Device Model", "Address", "Device IMEI No", "Sim Card Number", "Last Transmission Date", "Manufacturer", "Date Of Purchase"];
            this.excelTitle = {
              'ATM ID': 0, "Device Model": 1, "Address": 2, "Device IMEI No": 3, "Sim Card Number": 4, "Last Transmission Date": 5, "Manufacturer": 6, "Date Of Purchase": 7,
            };
            this.odj.push([this.objValues[i].AtmId, this.objValues[i].ModelName, this.objValues[i].Address, this.objValues[i].imeiNo, this.objValues[i].simNo,
            this.objValues[i].lastTransmission, this.objValues[i].ManufacturerName, this.objValues[i].DateOfpurchase,])

          } else if (this.reportsTitle == "User Activity Report") {

            this.head = ["Company Id", "Name", "Type", "Old Value", "Primary Data", "MAC Ip", "Last Updated Date", "Last Updated By"];
            this.excelTitle = {
              'Company Id': 0, "Name": 1, "Type": 2, "Old Value": 3, "Primary Data": 4, "MAC Ip": 5, "Last Updated Date": 6, "Last Updated By": 7
            };
            this.odj.push([this.objValues[i].companyId, this.objValues[i].name, this.objValues[i].type, this.objValues[i].oldValue, this.objValues[i].primaryData, this.objValues[i].macIp, this.objValues[i].lastUpdatedDate,
            this.objValues[i].lastUpdatedBy])

          } else if (this.reportsTitle == "SKT Alerts Report") {

            this.head = ["Plate NO", "Student Name", "Parent Name", "Date", "Alert Type", "Description", "Address", "Location", "Operator Name"];
            this.excelTitle = {
              "Plate NO": 0, "Student Name": 1, "Parent Name": 2, "Date": 3, "Alert Type": 4, "Description": 5, "Address": 6, "Location": 7, "Operator Name": 8
            };
            this.odj.push([this.objValues[i].plateno, this.objValues[i].studentname, this.objValues[i].parentname, this.objValues[i].date, this.objValues[i].alerttype, this.objValues[i].des, this.objValues[i].address,
            this.objValues[i].location, this.objValues[i].operatorname])

          } else if (this.reportsTitle == "Fuel Consumption Report") {

            this.head = ["Plate No", "Operator Name", "Begin Date and Time", "Begin Location", "End Date and Time", "End Location", "Mileage Per Liter", "Avg Fuel Consumption", "Odometer"];
            this.excelTitle = {
              "Plate No": 0, "Operator Name": 1, "Begin Date and Time": 2, "Begin Location": 3, "End Date and Time": 4, "End Location": 5, "Mileage Per Liter": 6, "Avg Fuel Consumption": 7, "Odometer": 8
            };
            this.odj.push([this.objValues[i].plateno, this.objValues[i].operatorname, this.objValues[i].begin, this.objValues[i].beginlocation, this.objValues[i].end_time,
            this.objValues[i].endlocation, this.objValues[i].expectedmileage, this.objValues[i].avgMileage, this.objValues[i].odometer,])

          } else if (this.reportsTitle == "User Logs Report") {

            this.head = ["User Id", "Role", "Mac Ip", "Login Time", "Logout Time"];
            this.excelTitle = {
              'User Id': 0, "Role": 1, "Mac Ip": 2, "Login Time": 3, "Logout Time": 4
            };
            this.odj.push([this.objValues[i].userId, this.objValues[i].role, this.objValues[i].macIp, this.objValues[i].loginTime, this.objValues[i].logoutTime])

          } else if (this.reportsTitle == "Utilization Report") {

            this.head = ["User Id", "Role", "Mac Ip", "Login Time", "Logout Time"];
            this.excelTitle = {
              'Plate No': 0, 'Total Selected Days': 1, 'Total Hours': 2, 'Used Hours': 3, 'Unused Hours': 4, 'Used Percentage(Running&Idle)': 5, 'Unused Percentage(Stop)': 6
            };
            this.odj.push([this.objValues[i].plateno, this.objValues[i].totalDays, this.objValues[i].totalHours, this.objValues[i].used, this.objValues[i].unused, this.objValues[i].percentage, this.objValues[i].unusedpercentage])

          }
          else if (this.reportsTitle == "Movement Report") {

            this.head = ["ATM ID", "Begin At", "End At", "Begin Location", "End Location", "Duration"];
            this.excelTitle = {
              'ATM ID': 0, "Begin At": 1, "End At": 2, "Begin Location": 3, "End Location": 4, "Duration": 5
            };
            this.odj.push([this.objValues[i].atmID, this.objValues[i].begin, this.objValues[i].end, this.objValues[i].beginLocation,
            this.objValues[i].endLocation, this.objValues[i].moveDur,])

          } else if (this.reportsTitle == "Status Summary Report") {

            this.head = ["ATM ID", "Begin At", "End At", "From Date", "To Date", "Status"];
            this.excelTitle = {
              'ATM ID': 0, "Begin At": 1, "End At": 2, "From Date": 3, "To Date": 4, "Status": 5
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].begin, this.objValues[i].end, this.objValues[i].fromDate,
            this.objValues[i].toDate, this.objValues[i].status,])

          }
          else if (this.reportsTitle == "Vehicle movement report") {
            this.head = ["Plate no", "Event Date", "Event Time", "Status", "Day odometer", "Location"];
            this.excelTitle = {
              'Plate no': 0, "Event Date": 1, "Event Time": 2, "Status": 3, "Day odometer": 4, "Location": 5
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].date, this.objValues[i].time,
            this.objValues[i].status, this.objValues[i].ioEvent, this.objValues[i].address])
          }
          else if (this.reportsTitle == "Fuel consumption report") {
            this.head = ['Plate No', "Date", "Consume price", "Odometer", "Fuel type", "Price", "Consume quantity", "Mileage"];
            this.excelTitle = {
              'Plate No': 0, "Date": 1, "Consume price": 2, "Odometer": 3, "Fuel type": 4, "Price": 5, "Consume quantity": 6, "Mileage": 7
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].date, this.objValues[i].consumePrice,
            this.objValues[i].odometer, this.objValues[i].fuelType, this.objValues[i].price,
            this.objValues[i].consumeQuantity, this.objValues[i].mileage])
          } else if (this.reportsTitle == "Work summary report") {
            this.selectedDate = this.reports.value.fromDate
            this.head = ['Plate No', "Mechine Type", "Work Start Time At Zone", "Total Duration", "Lot Zone", "Neighborhood", "The End Work Time", "Odometer"];
            this.excelTitle = {
              'Plate No': 0, "Mechine Type": 1, "Work Start Time At Zone": 2, "Total Duration": 3, "Lot Zone": 4, "Neighborhood": 5, "The End Work Time": 6, "Odometer": 7
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].type, this.objValues[i].startWorkTime, this.objValues[i].totDur,
            this.objValues[i].LotData, this.objValues[i].zoneData, this.objValues[i].endWorkTime,
            this.objValues[i].totOdo])
          } else if (this.reportsTitle == "Primitive maintanance report") {
            this.head = ['Plate No', "Odometer", "Last maintanance", "Last maintenance time", "Due kilometer", "Status"];
            this.excelTitle = {
              'Plate No': 0, "Odometer": 1, "Last maintanance": 2, "Last maintenance time": 3, "Due kilometer": 4, "Status": 5
            };
            this.odj.push([this.objValues[i].plateno, this.objValues[i].currentOdometer, this.objValues[i].lastmaintenance,
            this.objValues[i].lastmaintenancetime, this.objValues[i].duekilometer, this.objValues[i].status])
          } else if (this.reportsTitle == "Student Alert Report") {
            this.head = ['Roll No', "First Name", "Class", "Section", "Status"];
            this.odj.push([this.objValues[i].plateno, this.objValues[i].currentOdometer, this.objValues[i].lastmaintenance,
            this.objValues[i].lastmaintenancetime, this.objValues[i].duekilometer, this.objValues[i].status])
          } else if (this.reportsTitle == "Attendance report") {
            this.head = ['Roll No', "First Name", "Class", "Section", "Status"];
            this.odj.push([this.objValues[i].plateno, this.objValues[i].currentOdometer, this.objValues[i].lastmaintenance,
            this.objValues[i].lastmaintenancetime, this.objValues[i].duekilometer, this.objValues[i].status])
          } else if (this.reportsTitle == "Ac Report") {
            this.head = ['Plate No', "From Time", "To Time", "Duration"];
            this.excelTitle = {
              'Plate No': 0, "From Time": 1, "To Time": 2, "Duration": 3
            };
            this.odj.push([this.objValues[i].plateNo, this.objValues[i].from, this.objValues[i].to,
            this.objValues[i].duration])
          } else if (this.reportsTitle == "Attendance report") {
            this.head = ['Roll No', "First Name", "Class", "Section", "Status"];
            this.odj.push([this.objValues[i].rollNo, this.objValues[i].firstName, this.objValues[i].classId,
            this.objValues[i].sectionId, this.objValues[i].mode])
          } else if (this.reportsTitle == "Maintenance report") {
            this.head = ['Asset Id', "Asset Type", "Current Hours(HH:MM)", "Preventive Maintenance Interval(HH:MM)", "Last PM Hours(HH:MM)", "Last PM Time"];
            this.odj.push([this.objValues[i]['Asset Id'], this.objValues[i]['Asset Type'], this.objValues[i]['Current Hours (HH:MM)'],
            this.objValues[i]['Preventive Maintenance Interval (HH:MM)'], this.objValues[i]['Last PM Hours (HH:MM)'], this.objValues[i]['Last PM Time']])
          } else if (this.reportsTitle == "Employee Day Off Report") {
            this.head = ['Employee id', "Employee name", "Imei no", "Supervisor", "Employee day off", "Work zone"];
            this.excelTitle = {
              'Employee id': 0, "Employee name": 1, "Imei no": 2, "Supervisor": 3, "Employee day off": 4, "Work zone": 5
            };
            this.odj.push([this.objValues[i].EmployeeId, this.objValues[i].EmployeeName.replaceAll('@,@', "(") + ")", this.objValues[i].Imei,
            this.objValues[i].Supervisor, this.objValues[i].RestDay, this.objValues[i].WorkZone])
          } else if (this.reportsTitle == "Employee Idle Status Report") {
            this.head = ['Employee id', "Employee name", "Supervisor", "Work zone", "Idle duration", "Begin", "Begin at", "End", "End at"];
            this.excelTitle = {
              'Employee id': 0, "Employee name": 1, "Supervisor": 2, "Work zone": 3, "Idle duration": 4, "Begin": 5, "Begin at": 6, "End": 7, "End at": 8
            };
            this.odj.push([this.objValues[i].empId, this.objValues[i].empName.replaceAll('@,@', "(") + ")", this.objValues[i].supervisor,
            this.objValues[i].zone, this.objValues[i].idleDuration, this.objValues[i].Begin, this.objValues[i]["Begin At"], this.objValues[i]["End"], this.objValues[i]["End At"]])
          } else if (this.reportsTitle == "Employee Productivity Report") {

            this.head = ['Employee id', "Employee name", "Supervisor", "Date", "Shift time", "Work zone",
              "Total work time", "Total idle time", "OutOfZone Time", "IdleOutOfZone Time", "Salary"];
            this.excelTitle = {
              'Employee id': 0, "Employee name": 1, "Supervisor": 2, "Date": 3, "Shift time": 4, "Work zone": 5, "Total work time": 6, "Total idle time": 7, "Geozone out": 8, "Time idle out of zone": 9, "Salary": 10
            };
            this.odj.push([this.objValues[i].empId, this.objValues[i].empName.replaceAll('@,@', "(") + ")", this.objValues[i].supervisor, this.objValues[i].date,
            this.objValues[i].shiftTime, this.objValues[i].workZone, this.objValues[i].totalWotkTime, this.objValues[i].totalIdleTime,
            this.objValues[i].geoZoneDuration, this.objValues[i].towedDuration, this.objValues[i].avgSalary])
          } else if (this.reportsTitle == "Work Out Of Zone Report") {
            this.head = ['Employee id', "Employee name", "Supervisor", "Work init", "Work init Location", "Outofzone Duration",
              "End", "End location", "Work zone"];
            this.excelTitle = {
              'Employee id': 0, "Employee name": 1, "Supervisor": 2, "Work init": 3, "Work init Location": 4, "Outofzone Duration": 5,
              "End": 6, "End location": 7, "Work zone": 8
            };
            this.odj.push([this.objValues[i].empId, this.objValues[i].empName.replaceAll('@,@', "(") + ")", this.objValues[i].supervisor, this.objValues[i].workInIt,
            this.objValues[i].workInItLocation, this.objValues[i].outOfZoneDuration, this.objValues[i].end, this.objValues[i].endLocation,
            this.objValues[i].zoneName])
          } else if (this.reportsTitle == "Employee No Transmission Report") {
            this.head = ['Employee id', "Employee name", "Supervisor", "IMEI no", "Sim no", "Company name",
              "Last transmission", "Work zone"];
            this.excelTitle = {
              'Employee id': 0, "Employee name": 1, "Supervisor": 2, "IMEI no": 3, "Sim no": 4, "Company name": 5,
              "Last transmission": 6, "Work zone": 7
            };
            this.odj.push([this.objValues[i].empId, this.objValues[i].empName.replaceAll('@,@', "(") + ")", this.objValues[i].supervisor, this.objValues[i].imeiNo,
            this.objValues[i].simNo, this.objValues[i].companyName, this.objValues[i].lastTransmission,
            this.objValues[i].zoneName])
          }
          else if (this.reportsTitle == "No transmission report (WFT)") {
            this.head = ['Employee id', "Employee name", "Work zone", "Date", "Slot"];
            this.excelTitle = {
              'Employee id': 0, "Employee name": 1, "Work zone": 2, "Date": 3, "Slot": 4
            };
            this.odj.push([this.objValues[i].employeeId, this.objValues[i].employeeName.replaceAll('@,@', "(") + ")",
            this.objValues[i].zoneName, this.objValues[i].date,
            this.objValues[i].userId])
          }

          else if (this.reportsTitle == "Operator Report") {
            this.head = ['Plate No', "Trip-Start Date", "Trip-Start Time", "Trip-Stop Date", "Trip-Stop Time", "Duration (Days:HH:MM:SS)", "Operator Name"];
            this.excelTitle = {
              'Plate No': 0, "Trip-Start Date": 1, "Trip-Start Time": 2, "Trip-Stop Date": 3, "Trip-Stop Time": 4, "Duration (Days:HH:MM:SS)": 5, "Operator Name": 6,
            };
            this.odj.push([this.objValues[i].PlateNo, this.objValues[i].tripStartdate, this.objValues[i].tripStarttime, this.objValues[i].tripStopdate, this.objValues[i].tripStoptime, this.objValues[i].duration,
            this.objValues[i].operatorName])
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('vg')) {
            this.head.push("Group")
            this.excelTitle["Group"] = null;
            this.odj[i].push(this.objValues[i].vg)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('voc')) {
            this.head.push("Vehicle Operation code")
            this.excelTitle["Vehicle Operation code"] = null;
            this.odj[i].push(this.objValues[i].voc)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('on')) {
            this.head.push("Operator")
            this.excelTitle["Operator"] = null;
            this.odj[i].push(this.objValues[i].on)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('in')) {
            this.head.push("IMEI No")
            this.excelTitle["IMEI No"] = null;
            this.odj[i].push(this.objValues[i].in)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('sn')) {
            this.head.push("SIM No")
            this.excelTitle["SIM No"] = null;
            this.odj[i].push(this.objValues[i].sn)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('dm')) {
            this.head.push("Device Make")
            this.excelTitle["Device Make"] = null;
            this.odj[i].push(this.objValues[i].dm)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('dmn')) {
            this.head.push("Device Model")
            this.excelTitle["Device Model"] = null;
            this.odj[i].push(this.objValues[i].dmn)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('dp')) {
            this.head.push("Date of Purchase")
            this.excelTitle["Date of Purchase"] = null;
            this.odj[i].push(this.objValues[i].dp)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('iu')) {
            this.head.push("Asset category")
            this.excelTitle["Asset category"] = null;
            this.odj[i].push(this.objValues[i].iu)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('vm')) {
            this.head.push("Vehicle Model")
            this.excelTitle["Vehicle Model"] = null;
            this.odj[i].push(this.objValues[i].vm)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('vt')) {
            this.head.push("Vehicle type")
            this.excelTitle["Vehicle type"] = null;
            this.odj[i].push(this.objValues[i].vt)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('vy')) {
            this.head.push("Vehicle Year")
            this.excelTitle["Vehicle Year"] = null;
            this.odj[i].push(this.objValues[i].vy)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('gc')) {
            this.head.push("Color")
            this.excelTitle["Color"] = null;
            this.odj[i].push(this.objValues[i].gc)
          }
          if (this.objValues[0] && this.objValues[0].hasOwnProperty('dbm')) {
            this.head.push("Debugging Mode")
            this.excelTitle["Debugging Mode"] = null;
            this.odj[i].push(this.objValues[i].dbm)
          }
          if (this.head.length > 1)
            this.showpdf = false
          else
            this.showpdf = true
        }


      }
      if (validation) {
        // localStorage.setItem('reportsData', JSON.stringify(res));
        companyReportData.reportData = res

        this.reportData = res;
        if (this.reportData[0] && this.reportData[0].hasOwnProperty('status') && this.reportsTitle == "Temperature Report") {
          this.commonService.presentToast("Kindly configure this sensor using Settings in Gridview");
        } else if (this.reportData[0] && this.reportData[0].hasOwnProperty('status') && this.reportsTitle == "Fuel Consumption Report") {
          this.commonService.presentToast("Kindly configure mileage in manage add tab in column km/liters");
        }

        this.showReport = outputRouter;

      } else {
        this.commonService.presentToast('No data available');
        this.commonService.dismissLoader();
      }
    }


  }

  getBack() {
    this.modalController.dismiss();
  }

  portChangeGroup(
    event: {
      component: IonicSelectableComponent,
      value: any
    }
  ) {

    if (event.value.length == 0) {
      document.getElementsByClassName("ionic-selectable-value")[0].innerHTML = '<span >Select group</span>'
    } else if (event.value.length > 1) {
      this.selectedGroupList = event.value
      this.dashboarData = []
      for (let i = 0; i < event.value.length; i++) {
        if (event.value[i] != "Select all")
          this.dashboarData = [...this.dashboarData, ...this.groupPlateNoList[event.value[i]]]
      }
      document.getElementsByClassName("ionic-selectable-value")[0].innerHTML = '<span >' + event.value[0] + ', ' + event.value[1] + '</span>'
    } else {
      this.selectedGroupList = event.value
      this.dashboarData = this.groupPlateNoList[event.value[0]]
      document.getElementsByClassName("ionic-selectable-value")[0].innerHTML = '<span >' + event.value[0] + '</span>'
    }
    this.dashboarData.unshift({ plateNo: "Select all", vin: "Select all" });
  }

  portChange = (event: {
    component: IonicSelectableComponent,
    value: any
  }) => {
    let vinArr = []
    event.value = event.value.filter(res => res.vin != "Select all")
    for (let i = 0; i < event.value.length; i++) {
      vinArr.push(event.value[i].vin)
    }

    this.vin = vinArr
    if (this.vin.length == 1)
      document.getElementsByClassName("ionic-selectable-value")[document.getElementsByClassName("ionic-selectable-value").length - 1].innerHTML = '<div><span ><div> ' + event.value[0].plateNo + '</div></span></div>'
    else if (this.vin.length > 1)
      document.getElementsByClassName("ionic-selectable-value")[document.getElementsByClassName("ionic-selectable-value").length - 1].innerHTML = '<div><span ><div > ' + event.value[0].plateNo + ', ' + event.value[1].plateNo.substring(0, 3) + '...' + '</div></span></div>'
    else
      document.getElementsByClassName("ionic-selectable-value")[document.getElementsByClassName("ionic-selectable-value").length - 1].innerHTML = '<div><span class="ng-star-inserted"> <div>' + "Plate Number" + '</div></span></div>'
  }

  singleKeySelect(event) {
    let loopArray = []
    this.groupList = [];
    let dashboarData: any = Object.values(this.liveData);
    for (let i = 0; i < dashboarData.length; i++) {
      dashboarData[i].group == null ? dashboarData[i].group = "NoneGroup" : dashboarData[i].group
      this.groupList.push(dashboarData[i].group)
      let loopData: any = dashboarData[i];
      if (loopData.warrantyExpiry == true) {
        loopArray.push(loopData)
      }
    }
    this.groupList = [...new Set(this.groupList)]
    this.groupList.unshift("Select all");
    if (event.item == "Select all") {
      if (event.isSelected) {
        event.component._selectedItems = this.groupList
      }
      else {
        event.component._selectedItems = []
        event.component.items = this.groupList
      }
    } else {
      if (event.component._selectedItems.length > 0)
        if (event.component._selectedItems.indexOf("Select all") != -1)
          event.component._selectedItems.splice(event.component._selectedItems.indexOf("Select all"), 1);
        else {
          if (event.component._selectedItems.length == (this.groupList.length - 1))
            event.component._selectedItems.unshift("Select all")
        }
      event.component.items = this.groupList
    }
  }
  singleKeySelectPlateNo(event) {
    this.dashboarData = []
    for (let i = 0; i < this.selectedGroupList.length; i++) {
      if (this.selectedGroupList[i] != "Select all")
        this.dashboarData = [...this.dashboarData, ...this.groupPlateNoList[this.selectedGroupList[i]]]
    }
    this.dashboarData = this.dashboarData.filter(res => res.plateNo != "Select all")
    this.dashboarData.unshift({ plateNo: "Select all", vin: "Select all" });

    if (event.item.vin == "Select all") {
      if (event.isSelected) {
        event.component._selectedItems = this.dashboarData
      }
      else {
        event.component._selectedItems = []
        event.component.items = this.dashboarData
      }
    } else {
      if (event.component._selectedItems.length > 0)
        if (event.component._selectedItems.length == this.dashboarData.length) {
          var y = event.component._selectedItems.filter(res => res.plateNo != "Select all");
          event.component._selectedItems = []
          y.unshift({ plateNo: "Select all", vin: "Select all" })
          event.component._selectedItems = y
        }


      event.component.items = this.dashboarData
    }
  }
  getrecurrencedata() {
    var logindata = {};
    logindata["companyID"] = localStorage.corpId;
    logindata["branchID"] = localStorage.corpId;
    logindata["userID"] = localStorage.userName;
    var jsondetails = JSON.stringify(logindata);
    const url = serverUrl.web + "/ReportController/getRecurrencedata/" + jsondetails;
    this.ajaxService.ajaxGetObject(url)
      .subscribe(res => {
        this.showReport = "recurrence"
        companyReportData.recurrenceData = JSON.parse(res)

        companyReportData.recurrenceData.map(re => {
          var plateNodata = ""
          this.head = ['Status', "Created On", "Type", "Plate No", "Email Address"];
          re.plateno.split(",").map(de => {
            plateNodata += storageVariable.dashboardData.liveDatas[de].plateNo + ",";
          })
          re["plateNoCon"] = plateNodata;
          this.odj.push([re.status, re.createdon, re.type, re.plateNoCon, re.emailAddress]);
        })

        this.recurrenceData = companyReportData.recurrenceData
        this.objValues = res

      });
  }


  ngOnChanges() {
    this.showReport = "";
    this.dashboarData = [];
    this.groupList = [];
    this.unselectdate = [];
    this.unselect = []
    this.checkedValues = ''
    this.checkboxes.map(item => (item.checked = false));
    this.hide = false
    this.segment = "cardView";
    if (this.plateNo == "null") {
      this.plateNotext = this.trans.instant("Click here to select!")
    }
    else {
      this.plateNotext = this.plateNo
    }
    if (this.reportName == "Recurrence") {
      this.getrecurrencedata();
    }
    this.allReportTitle = this.allReportItem;
    this.allReportTitle = this.allReportTitle.filter(element => element !== "Recurrence")
    this.vin = undefined;
    if (document.getElementsByClassName("ionic-selectable-value")[0]) {
      document.getElementsByClassName("ionic-selectable-value")[0].innerHTML = '<span class="ng-star-inserted"><div > Select group</div></span>'
      document.getElementsByClassName("ionic-selectable-value")[document.getElementsByClassName("ionic-selectable-value").length - 1].innerHTML = '<div > Plate Number </div>'
    }

    this.maxDate = this.yesterday.getFullYear() + "-";
    this.maxDate += (this.yesterday.getMonth() + 1 < 10 ? "0" + (this.yesterday.getMonth() + 1).toString() : (this.yesterday.getMonth() + 1).toString()) + "-";
    this.maxDate += this.yesterday.getDate() < 10 ? "0" + this.yesterday.getDate().toString() : this.yesterday.getDate().toString();
    // if (storageVariable.dashboardData.liveDatas != undefined) {
    //   this.liveData = storageVariable.dashboardData.liveDatas;
    //   let dashboarData: any = Object.values(this.liveData);
    //   let loopArray = []

    //   for (let i = 0; i < dashboarData.length; i++) {
    //     dashboarData[i].group == null ? dashboarData[i].group = "NoneGroup" : dashboarData[i].group
    //     // this.groupList.push("Select all")
    //     this.groupList.push(dashboarData[i].group)
    //     let loopData: any = dashboarData[i];
    //     if (loopData.warrantyExpiry == true) {
    //       loopArray.push(loopData)
    //     }
    //   }
    //   this.groupList = [...new Set(this.groupList)]
    //   this.groupPlateNoList = {}
    //   for (let i = 0; i < this.groupList.length; i++) {
    //     let newPlateNo = []
    //     for (let j = 0; j < dashboarData.length; j++) {
    //       if (this.groupList[i] == dashboarData[j].group)
    //         newPlateNo.push(dashboarData[j])
    //     }
    //     this.groupPlateNoList[this.groupList[i]] = newPlateNo
    //   }
    //   // this.groupPlateNoList["Select all"] = dashboarData
    //   this.groupList.unshift("Select all");
    //   this.data = loopArray
    // }

    this.reportsContent = {
      plateNo: false,
      plateNoAll: false,
      fromDate: false,
      fromTime: false,
      toDate: false,
      toTime: false,
      group: false,
      speed: false,
      condition: false,
      timeExceed: false,
      sensor: false,
      addressCheckbox: false,
      odometerWeek: false,
      date: false,
      routetype: false,
      allReportList: false,
      multipleEmail: false,
      formatXlPdf: false,
      isMerge: false,
      slotWFT: false,
      alertTypeSelected: false,
      clocktime: false,
    };
    this.reportsTitle = this.reportName;
    this.checkvalues();
    if (this.shownContent == undefined) {
      this.reportsTitle = "Overall summary report";
      this.shownContent = {
        fromDate: true,
        toDate: true,
      }
    }
    Object.assign(this.reportsContent, this.shownContent);
    if (this.reportsTitle == "Alarm report" || this.reportsTitle == "Temperature report" || this.reportsTitle == "Door open report" || this.reportsTitle == "Door summary report" || this.reportsTitle == "Door count report" || this.reportsTitle == "Alarm report" || this.reportsTitle == "Alert report" || this.reportsTitle == "Alert MailReport" || this.reportsTitle == "GSMLOST Report" || this.reportsTitle == "User Activity Report" || this.reportsTitle == "User Logs Report") {
      let today = new Date();
      this.maxDate = today.getFullYear() + "-";
      this.maxDate += (today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString()) + "-";
      this.maxDate += today.getDate() < 10 ? "0" + today.getDate().toString() : today.getDate().toString();
    }
    if (this.reportsTitle == "Alert report" || this.reportsTitle == "Fuel Report" || this.reportsTitle == "Fence Report" || this.reportsTitle == "Maintenance Alert" || this.reportsTitle == "Alert MailReport" || this.reportsTitle == "No transmission report" || this.reportsTitle == "GSMLOST Report" || this.reportsTitle == "User Activity Report" || this.reportsTitle == "User Logs Report") {
      let today = new Date();
      this.maxDate = today.getFullYear() + "-";
      this.maxDate += (today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString()) + "-";
      this.maxDate += today.getDate() < 10 ? "0" + today.getDate().toString() : today.getDate().toString();

    }
    if (this.reportsTitle == "Alert report") {

      this.alertType = storageVariable.comboReports.alerts[0].split(",");
      this.alertType = [{ label: "Select All", value: "All" }, ...this.alertType]

    } else if (this.reportsTitle == "Fence Report") {
      this.alertType = storageVariable.comboReports["zone"].split(",");
      this.alertType = [{ label: "Select All", value: "All" }, ...this.alertType]

    } else if (this.reportsTitle == "Maintenance Alert") {
      this.alertType = storageVariable.comboReports["maintainance"].split(",");
      this.alertType = [{ label: "Select All", value: "All" }, ...this.alertType]

    }

    if (this.reportsTitle == "No transmission report (WFT)") {
      let today = new Date();
      this.maxDate = "2022-06-30"

    }
    // newly added form control name
    this.reports = this.formBuilder.group({
      fromTimeOnly: ["00:00:00 AM"],
      toTimeOnly: ["11:59:59 PM"],
      report: this.reportName,
      group: "",
      plateNo: [''],
      fromTime: ['00:00:00'],
      fromDate: [this.maxDate + " 00:00"],
      toDate: [this.maxDate + " 23:59:59"],
      toTime: [this.yesterday.toTimeString().split(" ")[0]],
      speed: ['50'],
      condition: ['>'],
      timeExceed: ['900'],
      sensor: ["2"],
      limit: ["2"],
      sensorType: ["TEMP1"],
      addressCheckbox: [false],
      clocktime: [false],
      stopdur: [true],
      idledur: [false],
      odometerWeek: [""],
      date: [''],
      routetype: ['BUS-Z'],
      multipleEmail: [""],
      formatXlPdf: [""],
      allReportList: [""],
      view: "false",
      slotWFT: "Lot3",
      alertTypeSelected: [""]
    });
    if (this.reportsTitle == "Executive summary report" ||
      this.reportsTitle == "Fuel Report" ||
      this.reportsTitle == "Status summary report" ||
      this.reportsTitle == "Overspeed duration report" ||
      this.reportsTitle == "Speed report" ||
      this.reportsTitle == "Consolidate summary report" ||
      this.reportsTitle == "Driver behaviour report" ||
      this.reportsTitle == "Utilization Report" ||
      this.reportsTitle == "Overall summary report"
      || this.reportsTitle == "Hourly Consolidate summary report") {
      this.reports.patchValue({
        'fromDate': this.maxDate.split(" ")[0],
        'toDate': this.maxDate.split(" ")[0]
      });
    }

    if (this.plateNo !== "null") {
      this.reports.patchValue({ 'plateNo': this.plateNo });
      this.vin = JSON.parse(localStorage.selectedVin).vin;
    } else {
      if (this.reportsTitle == 'Driver behaviour report' || this.reportsTitle == 'Door count report' || this.reportsTitle == 'Fuel consumption report' || this.reportsTitle == 'Student Alert Report' || this.reportsTitle == 'Attendance report'
        || this.reportsTitle == "Employee No Transmission Report" || this.reportsTitle == "Employee Day Off Report" || this.reportsTitle == "No transmission report (WFT)") {
        this.vin = "All";
      }
    }

    this.tableData = companyReportData.reportData
    this.objValues = this.tableData;
    this.odj.length = 0;
    this.getData();
  }

  multiSelectDateNonExisiting() {
    if ((this.reportsTitle === 'Consolidate summary report' || this.reportsTitle == "Hourly Consolidate summary report") && this.tempVar > 1) {
      this.boole = true;
    }
    else {
      this.boole = false;
    }
  }

  exportToExcel() {

    this.filterRows = this.commonService.filter;
    if ((this.filterRows != undefined && this.filterRows != "null" && this.filterRows != null && this.filterRows != "") && ((this.reportsTitle != "Consolidate summary report"))) {
      const filteredData = this.filterRows.map(row => {
        this.objfilter = [];
        // if (this.reportsTitle == "Consolidate summary report") {
        //   window.location.href = serverUrl.web + "/NewVtsServlet?mode=reportDownload&url=/home/ubuntu/imagesdirectory/ConsolidateSummaryReport.xls&filename=" + encodeURI("Consolidate summary report[" + this.reports.value.fromDate.split("T")[0] + "].xls");
        // } 
        if (this.reportsTitle == "Work summary report") {
          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Mechine Type"] = row.type;
          this.objfilter["Work Start Time At Zone"] = row.startWorkTime;
          this.objfilter["Total Duration"] = row.totDur;
          this.objfilter["Lot Zone"] = row.LotData;
          this.objfilter["Neighborhood"] = row.zoneData;
          this.objfilter["The End Work Time"] = row.endWorkTime;
          this.objfilter["Odometer"] = row.totOdo;
        }
        else if (this.reportsTitle == "Temperature Report") {
          if (this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR1" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR2" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR3" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR4") {
            this.objfilter["plateNo"] = row.PlateNo;
            this.objfilter["TEMP"] = row.TEMP;
            this.objfilter["Humidity"] = row.humidity;
            this.objfilter["Start At"] = row.beginAt;
            this.objfilter["End At"] = row.endAt;
            this.objfilter["Begin location"] = row.beginLocation;
            this.objfilter["End Location"] = row.endLocation;
          } else {
            this.objfilter["plateNo"] = row.PlateNo;
            this.objfilter["TEMP"] = row.TEMP;
            this.objfilter["Start At"] = row.beginAt;
            this.objfilter["End At"] = row.endAt;
            this.objfilter["Begin location"] = row.beginLocation;
            this.objfilter["End Location"] = row.endLocation;
          }
        }

        else if (this.reportsTitle == "Executive summary report") {
          if (this.entryPoint == "ATM") {
            this.objfilter["ATM Id"] = row.ATMId;
            this.objfilter["Begin AT"] = row.beginTime;
            this.objfilter["End At"] = row.endTime;
            this.objfilter["Begin Location"] = row.beginLocation;
            this.objfilter["End Location"] = row.endLocation;
            this.objfilter["Alerts Count"] = row.totalCount;
            this.objfilter["PowerONDur"] = row.PowerONDur;
            this.objfilter["PowerCut"] = row.PowerCutDur;
            this.objfilter["Movement"] = row.MovementDur;
            this.objfilter["Battery Drain"] = row.BatteryDrainDur;
            this.objfilter["Low Battery"] = row.LowBatteryDur;
          } else {
            this.objfilter["Plate No"] = row.plateNo;
            this.objfilter["Begin Date"] = row.begindate;
            //this.objfilter["Begin Time"] = row.begintime;
            this.objfilter["Begin Location"] = row.beginLocation;
            this.objfilter["End Date"] = row.enddate;
            // this.objfilter["End Time"] = row.endtime;
            this.objfilter["End Location"] = row.endLocation;
            this.objfilter["Alert count"] = row.totalCount;
            this.objfilter["Max Speed"] = row.maxSpeed;
            this.objfilter["Odometer"] = row.odometer;
            this.objfilter["Running"] = row.runningDuration;
            this.objfilter["Stop"] = row.stopDuration;
            this.objfilter["Towed"] = row.towedDuration;
            this.objfilter["Idle"] = row.idleDuration;
          }
        } else if (this.reportsTitle == "Status summary report") {

          this.objfilter["Plate No"] = row["Plate No"];
          this.objfilter["Distance"] = row.Odometer;
          this.objfilter["Start Date"] = row.begindate;
          this.objfilter["Start Time"] = row.begintime;
          this.objfilter["Begin Location"] = row["Begin At"];
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["End Location"] = row["End At"];
          this.objfilter["Stop"] = row.Stop;
          this.objfilter["Towed"] = row.Towed;
          this.objfilter["Running"] = row.Running;
          this.objfilter["Idle"] = row.Idle;
        } else if (this.reportsTitle == "Speed report") {
          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Date"] = row.date;
          this.objfilter["Time"] = row.time;
          this.objfilter["Speed"] = row.speed;
          this.objfilter["Operator"] = row.operator;
          this.objfilter["Limit Exceeds"] = row.descripition;
        } else if (this.reportsTitle == "Fuel Report") {
          this.objfilter["Plate No"] = row.plateno;
          this.objfilter["Fuel Level"] = row.feul;
          this.objfilter["Begin At"] = row.startat;
          this.objfilter["End At"] = row.endat;
          this.objfilter["Begin Location"] = row.startLoc;
          this.objfilter["End Location"] = row.endLoc;
        } else if (this.reportsTitle == "Productivity Report") {
          this.objfilter["Plate No"] = row.PlateNo;
          this.objfilter["Actual Working Hours"] = row["Actual WorkingHours"];
          this.objfilter["Percentage"] = row.Percentage;
          this.objfilter["Working Days"] = row["scheduled WorkingDays"];
          this.objfilter["Scheduled WorkingHours"] = row["scheduled WorkingHours"];
        } else if (this.reportsTitle == "Overspeed duration report") {
          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Over Speed Duration"] = row.duration;
          this.objfilter["Start Date"] = row.startdate;
          this.objfilter["Start Time"] = row.starttime;
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["Min Speed"] = row.min;
          this.objfilter["Max Speed"] = row.max;
          this.objfilter["Avg Speed"] = row.avg;
        } else if (this.reportsTitle == "Alert report") {
          if (app.entryPoint != "WFT") {
            if (app.entryPoint == "ATM") {
              this.objfilter["ATM ID"] = row.plateNo;
              //this.objfilter["Operator Name"] = row.operatorName;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Date & Time"] = row.timeStamp;
            } else {
              this.objfilter["Plate No"] = row.plateNo;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Alert Date"] = row.date;
              this.objfilter["Alert Time"] = row.time;

            }
          } else {

            this.objfilter["Plate No"] = row.plateNo.split("@,@")[0] + `(${row.plateNo.split("@,@")[1]})`;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;
          }
        } else if (this.reportsTitle == "Fence Report") {
          if (app.entryPoint != "WFT") {

            if (app.entryPoint == "ATM") {
              this.objfilter["ATM ID"] = row.plateNo;
              //this.objfilter["Operator Name"] = row.operatorName;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Date & Time"] = row.timeStamp;

            } else {
              this.objfilter["Plate No"] = row.plateNo;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Alert Date"] = row.date;
              this.objfilter["Alert Time"] = row.time;

            }
          } else {
            this.objfilter["Plate No"] = row.plateNo.split("@,@")[0] + `(${row.plateNo.split("@,@")[1]})`;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;
          }
        } else if (this.reportsTitle == "Maintenance Alert") {
          if (app.entryPoint != "WFT") {
            this.objfilter["Plate No"] = row.plateNo;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Additional Info"] = row.additionalInfo;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;
          } else {
            this.objfilter["Plate No"] = row.plateNo.split("@,@")[0] + `(${row.plateNo.split("@,@")[1]})`;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;
          }
        }
        else if (this.reportsTitle == "Fleet summary report") {
          this.objfilter["Plate no"] = row["Plate No"];
          this.objfilter["Operator name"] = row["Operator Name"];
          this.objfilter["Date of purchase"] = row["Date Of Purchase"];
          this.objfilter["Date Of Vehicle Associate"] = row["Date Of Vehicle Associate"];
          this.objfilter["Location"] = row.Location;

        } else if (this.reportsTitle == "Overall summary report") {
          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Begin Date"] = row.begindate;
          this.objfilter["Begin Time"] = row.begintime;
          this.objfilter["Begin Location"] = row.beginLocation;
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["End Location"] = row.endLocation;
          this.objfilter["Max Speed"] = row.maxSpeed;
          this.objfilter["Odometer"] = row.odometer;
          this.objfilter["Running"] = row.runningDuration;
          this.objfilter["Stop"] = row.stopDuration;
          this.objfilter["Towed"] = row.towedDuration;
        } else if (this.reportsTitle == "Engine Hours summary report") {
          this.objfilter["Plate no"] = row.plateNo;
          this.objfilter["Start Date"] = row.Startdate;
          this.objfilter["Start time"] = row.Starttime;
          this.objfilter["Start location"] = row.startAddress;
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End time"] = row.endtime;
          this.objfilter["End location"] = row.stopAddress;
          this.objfilter["Odometer"] = row.odometer;
          this.objfilter["Running"] = row.runningDuration;
          this.objfilter["Idle"] = row.idleDuration;
        } else if (this.reportsTitle == "Maintenance report") {
          this.objfilter["Asset Id"] = row['Asset Id'];
          this.objfilter["Asset Type"] = row['Asset Type'];
          this.objfilter["Current Hours(HH:MM)"] = row['Current Hours (HH:MM)'];
          this.objfilter["Preventive Maintenance Interval(HH:MM)"] = row['Preventive Maintenance Interval (HH:MM)'];
          this.objfilter["Last PM Hours(HH:MM)"] = row['Last PM Hours (HH:MM)'];
          this.objfilter["Last PM Time"] = row['Last PM Time'];
        } else if (this.reportsTitle == "Driver behaviour report") {
          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Harsh Break"] = row.HARSHBRAKING;
          this.objfilter["Seat Belt"] = row.SEATBELT;
          this.objfilter["Harsh Acceleration"] = row.HARSHACCELERATION;
          this.objfilter["Drift"] = row.DRIFT;
          this.objfilter["Travelled KM"] = row.distanceTravelled;
          this.objfilter["Engine On"] = row.engineOnDuration;
          this.objfilter["Running"] = row.runningDuration;
          this.objfilter["Idle"] = row.excessiveIdling;
          this.objfilter["AvgSpeed"] = row.avgSpeed;
          this.objfilter["Top Speed"] = row.topSpeed;
          this.objfilter["Fuel Consumption"] = row.fuelConsumption;
          this.objfilter["Over All Score"] = row.overAllScore;
        } else if (this.reportsTitle == "Stop report") {
          this.objfilter["Plate no"] = row.plateNo;
          this.objfilter["Started Again At Date"] = row.begindate;
          this.objfilter["Started Again At Time"] = row.begintime;
          this.objfilter["Stop At Date"] = row.enddate;
          this.objfilter["Stop At Time"] = row.endtime;
          this.objfilter["Duration"] = row.stopDur;
          this.objfilter["Location"] = row.endLocation;
        } else if (this.reportsTitle == "No transmission report (WFT)") {
          this.objfilter["Employee id"] = row.employeeId;
          this.objfilter["Employee name"] = row.employeeName.replaceAll('@,@', "(") + ")";
          this.objfilter["Work zone"] = row.zoneName;
          this.objfilter["Date"] = row.date;
          this.objfilter["Slot"] = row.userId;
        }

        else if (this.reportsTitle == "No transmission report") {
          if (app.entryPoint == "ATM") {
            this.objfilter["Plate No"] = row.AtmId;
            this.objfilter["Model"] = row.ModelName;
            this.objfilter["Address"] = row.Address;
            this.objfilter["IMEI No"] = row.imeiNo;
            this.objfilter["Sim Card No"] = row.simNo;
            this.objfilter["Last Transmission"] = row.lastTransmission;
            this.objfilter["Manufacturer"] = row.ManufacturerName;
            this.objfilter["Date Of Purchase"] = row.DateOfpurchase;
          } else {
            this.objfilter["Plate No"] = row.plateNumber;
            this.objfilter["Last Transmission Date"] = row.lastTransTimedate;
            this.objfilter["Last Transmission Time"] = row.lastTransTimetime;
            this.objfilter["Manufacturer"] = row.ManufacturerName;


          }
        } else if (this.reportsTitle == "Alert MailReport") {
          if (app.entryPoint == "ATM") {
            this.objfilter["ATM ID"] = row.PlateNo;
            this.objfilter["AlertType"] = row.alertType;
            this.objfilter["Date"] = row.Date;
            this.objfilter["Email1"] = row.Mail1;
            this.objfilter["Email1 Status"] = row.Mail1Result;
            this.objfilter["Email2"] = row.Mail2;
            this.objfilter["Email2 Status"] = row.Mail2Result;
          } else {
            this.objfilter["Plate No"] = row.PlateNo;
            this.objfilter["AlertType"] = row.alertType;
            this.objfilter["Date"] = row.Date;
            this.objfilter["Email1"] = row.Mail1;
            this.objfilter["Email1 Status"] = row.Mail1Result;
            this.objfilter["Email2"] = row.Mail2;
            this.objfilter["Email2 Status"] = row.Mail2Result;
          }
        }
        else if (this.reportsTitle == "GSMLOST Report") {

          this.objfilter["ATM Id"] = row.AtmId;
          this.objfilter["Model"] = row.ModelName;
          this.objfilter["Address"] = row.Address;
          this.objfilter["IMEI No"] = row.imeiNo;
          this.objfilter["Sim Card No"] = row.simNo;
          this.objfilter["Last Transmission"] = row.lastTransmission;
          this.objfilter["Manufacturer"] = row.ManufacturerName;
          this.objfilter["Date Of Purchase"] = row.DateOfpurchase;

        } else if (this.reportsTitle == "Fuel Consumption Report") {

          this.objfilter["Plate No"] = row.plateno;
          this.objfilter["Operator Name"] = row.operatorname;
          // this.objfilter["Date"] = row.fromdate;
          this.objfilter["Begin Date and Time"] = row.begin;
          this.objfilter["Begin Location"] = row.beginlocation;
          this.objfilter["End Date and Time"] = row.end_time;
          this.objfilter["End Location"] = row.endlocation;
          this.objfilter["Mileage Per Liter"] = row.expectedmileage;
          this.objfilter["Avg Fuel Consuption"] = row.avgMileage;
          this.objfilter["Odometer"] = row.odometer;

        } else if (this.reportsTitle == "User Activity Report") {

          this.objfilter["Company Id"] = row.companyId;
          this.objfilter["Name"] = row.name;
          this.objfilter["Type"] = row.type;
          this.objfilter["Old Value"] = row.oldValue;
          this.objfilter["Primary Data"] = row.primaryData;
          this.objfilter["MAC Ip"] = row.macIp;
          this.objfilter["Last Updated Date"] = row.lastUpdatedDate;
          this.objfilter["Last Updated By"] = row.lastUpdatedBy;


        } else if (this.reportsTitle == "SKT Alerts Report") {

          this.objfilter["Plate NO"] = row.plateno;
          this.objfilter["Student Name"] = row.studentname;
          this.objfilter["Parent Name"] = row.parentname;
          this.objfilter["Date"] = row.date;
          this.objfilter["Alert Type"] = row.alerttype;
          this.objfilter["Description"] = row.des;
          this.objfilter["Address"] = row.address;
          this.objfilter["Location"] = row.location;
          this.objfilter["Oerator Name"] = row.operatorname;


        }
        else if (this.reportsTitle == "User Logs Report") {

          this.objfilter["User Id"] = row.userId;
          this.objfilter["Role"] = row.role;
          this.objfilter["MAC Ip"] = row.macIp;
          this.objfilter["Login Time"] = row.loginTime;
          this.objfilter["Logout Time"] = row.logoutTime;

        } else if (this.reportsTitle == "Utilization Report") {

          this.objfilter["Plate No"] = row.plateno;
          this.objfilter["Total Selected Days"] = row.totalDays;
          this.objfilter["Total Hours"] = row.totalHours;
          this.objfilter["Used Hours"] = row.used;
          this.objfilter["Unused Hours"] = row.unused;
          this.objfilter["Used Percentage(Running&Idle)"] = row.percentage;
          this.objfilter["Unused Percentage(Stop)"] = row.unusedpercentage;

        } else if (this.reportsTitle == "Movement Report") {

          this.objfilter["ATM ID"] = row.atmID;
          this.objfilter["Begin At"] = row.begin;
          this.objfilter["End At"] = row.end;
          this.objfilter["Begin Location"] = row.beginLocation;
          this.objfilter["End Location"] = row.endLocation;
          this.objfilter["Duration"] = row.moveDur;

        } else if (this.reportsTitle == "Status Summary Report") {

          this.objfilter["ATM ID"] = row.plateNo;
          this.objfilter["Begin At"] = row.begin;
          this.objfilter["End At"] = row.end;
          this.objfilter["From Date"] = row.fromDate;
          this.objfilter["To Date"] = row.toDate;
          this.objfilter["Status"] = row.status;

        }
        else if (this.reportsTitle == "Idle violation report") {

          this.objfilter["Plate No"] = row["Plate No"];
          this.objfilter["Begin Date"] = row["begindate"];
          this.objfilter["Begin Time"] = row["begintime"];
          this.objfilter["Begin At"] = row["Begin At"];
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["End At"] = row['End At'];
          this.objfilter["Idle"] = row.Idle;

        } else if (this.reportsTitle == "Vehicle movement report") {

          this.objfilter["Plate no"] = row.plateNo;
          this.objfilter["Event Date"] = row.date;
          this.objfilter["Event Time"] = row.time;
          this.objfilter["Status"] = row.status;
          this.objfilter["Day odometer"] = row.ioEvent;
          this.objfilter["Location"] = row.address;

        } else if (this.reportsTitle == "Recurrence") {

          this.objfilter["Status"] = row.status;
          this.objfilter["Created On Date"] = row.createdondate;
          this.objfilter["'Created On Time"] = row.createdontime;
          this.objfilter["Type"] = row.type;
          this.objfilter["Plate No"] = row.plateNoCon;
          this.objfilter["Email Address"] = row.emailAddress;

        }
        else if (this.reportsTitle == "Operator Report") {
          this.objfilter["Plate No"] = row.PlateNo;
          this.objfilter["Trip-Start Date"] = row.tripStartdate;
          this.objfilter["Trip-Start Time"] = row.tripStarttime;
          this.objfilter["Trip-Stop Date"] = row.tripStopdate;
          this.objfilter["Trip-Stop Time"] = row.tripStoptime;
          this.objfilter["Duration"] = row.duration;
          this.objfilter["Operator Name"] = row.operatorName;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vg')) {
          this.objfilter["Group"] = row.vg;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('voc')) {
          this.objfilter["Vehicle Operation code"] = row.voc;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('on')) {
          this.objfilter["Operator"] = row.on;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('in')) {

          this.objfilter["IMEI No"] = row.in;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('sn')) {
          this.objfilter["SIM No"] = row.sn;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dm')) {
          this.objfilter["Device Make"] = row.dm;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dmn')) {
          this.objfilter["Device Model"] = row.dmn;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dp')) {
          this.objfilter["Date of Purchase"] = row.dp;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('iu')) {
          this.objfilter["Asset category"] = row.iu;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vm')) {
          this.objfilter["Vehicle Model"] = row.vm;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vt')) {
          this.objfilter["Vehicle type"] = row.vt;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vy')) {
          this.objfilter["Vehicle Year"] = row.vy;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('gc')) {
          this.objfilter["Color"] = row.operatorName;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dbm')) {
          this.objfilter["Debugging Mode"] = row.dbm;
        }
        return this.objfilter;
      });
      if (this.reportsTitle == "Work summary report" || this.reportsTitle == "Driver behaviour report") {
        let reporttitle;
        reporttitle = this.reportsTitle + " (" + this.selectedDate + ")"
        let reportData = {
          title: reporttitle,
          data: Object.values(this.objfilter),
          headers: Object.keys(this.excelTitle),
          data1: filteredData,
          fromdate: this.reports.value.fromDate,
          todate: this.reports.value.toDate
        }
        this.ete.exportExcel(reportData);
      } else {
        let reportData = {
          title: this.reportsTitle,
          data: Object.values(this.objfilter),
          headers: Object.keys(this.excelTitle),
          data1: filteredData,
          fromdate: this.reports.value.fromDate,
          todate: this.reports.value.toDate
        }
        this.ete.exportExcel(reportData);
      }
    } else {
      if (this.reportsTitle == "Work summary report" || this.reportsTitle == "Driver behaviour report") {
        let reporttitle;
        reporttitle = this.reportsTitle + " (" + this.selectedDate + ")"
        let reportData = {
          title: reporttitle,
          data: Object.values(this.odj),
          headers: Object.keys(this.excelTitle),
          fromdate: this.reports.value.fromDate,
          todate: this.reports.value.toDate
        }
        this.ete.exportExcel(reportData);
      } else if (this.reportsTitle == "Consolidate summary report" || this.reportsTitle == "Hourly Consolidate summary report") {
        let reportData = {
          title: this.reportsTitle,
          data: Object.values(this.odj),
          overspeed: Object.values(this.odjosp),
          harshbraking: Object.values(this.odjhb),
          harshacceleration: Object.values(this.odjha),
          drift: Object.values(this.odjd),
          seatbelt: Object.values(this.odjsb),
          headers: Object.keys(this.excelTitle),
          headersosp: Object.keys(this.excelTitleosp),
          headershb: Object.keys(this.excelTitlehb),
          headersha: Object.keys(this.excelTitleha),
          headersd: Object.keys(this.excelTitled),
          headerssb: Object.keys(this.excelTitlesb),
          fromdate: this.reports.value.fromDate,
          todate: this.reports.value.toDate
        }
        this.ete.exportExcel(reportData);
      } else if (this.reportsTitle == "Utilization Report") {
        let reportData = {
          title: this.reportsTitle,
          data: Object.values(this.odj),
          runDur: Object.values(this.odjrun),
          headers: Object.keys(this.excelTitle),
          headersrun: Object.keys(this.excelTitlerun),
          fromdate: this.reports.value.fromDate,
          todate: this.reports.value.toDate
        }
        this.ete.exportExcel(reportData);
      }
      else {
        let reportData = {
          title: this.reportsTitle,
          data: Object.values(this.odj),
          headers: Object.keys(this.excelTitle),
          fromdate: this.reports.value.fromDate,
          todate: this.reports.value.toDate
        }
        this.ete.exportExcel(reportData);
      }
    }
  }

  createPdf() {
    this.filterRows = this.commonService.filter;
    this.selectedDate = this.reports.value.fromDate + ',' + this.reports.value.toDate
    if (this.filterRows != undefined && this.filterRows != "null" && this.filterRows != null && this.filterRows != "") {
      const filteredData = this.filterRows.map(row => {
        this.objfilter = [];
        if (this.reportsTitle == "Work summary report") {
          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Mechine Type"] = row.type;
          this.objfilter["Work Start Time At Zone"] = row.startWorkTime;
          this.objfilter["Total Duration"] = row.totDur;
          this.objfilter["Lot Zone"] = row.LotData;
          this.objfilter["Neighborhood"] = row.zoneData;
          this.objfilter["The End Work Time"] = row.endWorkTime;
          this.objfilter["Odometer"] = row.totOdo;
        }
        else if (this.reportsTitle == "Temperature Report") {

          if (this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR1" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR2" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR3" || this.temperatureSensor.getSelectedItem().originalItem.temp == "BTTEMPERATURESENSOR4") {
            this.objfilter["plateNo"] = row.PlateNo;
            this.objfilter["TEMP"] = row.TEMP;
            this.objfilter["Humidity"] = row.humidity;
            this.objfilter["Start At"] = row.beginAt;
            this.objfilter["End At"] = row.endAt;
            this.objfilter["Begin location"] = row.beginLocation;
            this.objfilter["End Location"] = row.endLocation;
          } else {
            this.objfilter["plateNo"] = row.PlateNo;
            this.objfilter["TEMP"] = row.TEMP;
            this.objfilter["Start At"] = row.beginAt;
            this.objfilter["End At"] = row.endAt;
            this.objfilter["Begin location"] = row.beginLocation;
            this.objfilter["End Location"] = row.endLocation;
          }
        }
        else if (this.reportsTitle == "Executive summary report") {
          if (this.entryPoint == "ATM") {

            this.objfilter["ATM Id"] = row.ATMId;
            this.objfilter["Begin AT"] = row.beginTime;
            this.objfilter["End At"] = row.endTime;
            this.objfilter["Begin Location"] = row.beginLocation;
            this.objfilter["End Location"] = row.endLocation;
            this.objfilter["Alerts Count"] = row.totalCount;
            this.objfilter["PowerONDur"] = row.PowerONDur;
            this.objfilter["PowerCut"] = row.PowerCutDur;
            this.objfilter["Movement"] = row.MovementDur;
            this.objfilter["Battery Drain"] = row.BatteryDrainDur;
            this.objfilter["Low Battery"] = row.LowBatteryDur;

          } else {

            this.objfilter["Plate No"] = row.plateNo;
            this.objfilter["Begin Date"] = row.begindate;
            //this.objfilter["Begin Time"] = row.begintime;
            this.objfilter["Begin Location"] = row.beginLocation;
            this.objfilter["End Date"] = row.enddate;
            //this.objfilter["End Time"] = row.endtime;
            this.objfilter["End Location"] = row.endLocation;
            this.objfilter["Alert count"] = row.totalCount;
            this.objfilter["Max Speed"] = row.maxSpeed;
            this.objfilter["Odometer"] = row.odometer;
            this.objfilter["Running"] = row.runningDuration;
            this.objfilter["Stop"] = row.stopDuration;
            this.objfilter["Towed"] = row.towedDuration;
            this.objfilter["Idle"] = row.idleDuration;

          }
        } else if (this.reportsTitle == "Status summary report") {


          this.objfilter["Plate No"] = row["Plate No"];
          this.objfilter["Distance"] = row.Odometer;
          this.objfilter["Start Date"] = row.begindate;
          this.objfilter["Start Time"] = row.begintime;
          this.objfilter["Begin Location"] = row["Begin At"];
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["End Location"] = row["End At"];
          this.objfilter["Stop"] = row.Stop;
          this.objfilter["Towed"] = row.Towed;
          this.objfilter["Running"] = row.Running;
          this.objfilter["Idle"] = row.Idle;
        } else if (this.reportsTitle == "Fuel Report") {
          this.objfilter["Plate No"] = row.plateno;
          this.objfilter["Fuel Level"] = row.feul;
          this.objfilter["Begin At"] = row.startat;
          this.objfilter["End At"] = row.endat;
          this.objfilter["Begin Location"] = row.startLoc;
          this.objfilter["End Location"] = row.endLoc;
        }
        else if (this.reportsTitle == "Speed report") {

          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Date"] = row.date;
          this.objfilter["Time"] = row.time;
          this.objfilter["Speed"] = row.speed;
          this.objfilter["Operator"] = row.operator;
          this.objfilter["Limit Exceeds"] = row.descripition;

        } else if (this.reportsTitle == "Overspeed duration report") {

          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Over Speed Duration"] = row.duration;
          this.objfilter["Start Date"] = row.startdate;
          this.objfilter["Start Time"] = row.starttime;
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["Min Speed"] = row.min;
          this.objfilter["Max Speed"] = row.max;
          this.objfilter["Avg Speed"] = row.avg;

        } else if (this.reportsTitle == "Alert report") {
          if (app.entryPoint != "WFT") {

            if (app.entryPoint == "ATM") {

              this.objfilter["ATM ID"] = row.plateNo;
              // this.objfilter["Operator Name"] = row.operatorName;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Date & Time"] = row.timeStamp;

            } else {

              this.objfilter["Plate No"] = row.plateNo;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Alert Date"] = row.date;
              this.objfilter["Alert Time"] = row.time;

            }

          } else {

            this.objfilter["Plate No"] = row.plateNo.split("@,@")[0] + `(${row.plateNo.split("@,@")[1]})`;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;

          }
        } else if (this.reportsTitle == "Fence Report") {
          if (app.entryPoint != "WFT") {

            if (app.entryPoint == "ATM") {

              this.objfilter["ATM ID"] = row.plateNo;
              //this.objfilter["Operator Name"] = row.operatorName;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Date & Time"] = row.timeStamp;

            } else {

              this.objfilter["Plate No"] = row.plateNo;
              this.objfilter["Alert Types"] = row.alertTypes;
              this.objfilter["Address"] = row.address;
              this.objfilter["Additional Info"] = row.additionalInfo;
              this.objfilter["Alert Date"] = row.date;
              this.objfilter["Alert Time"] = row.time;

            }
          } else {

            this.objfilter["Plate No"] = row.plateNo.split("@,@")[0] + `(${row.plateNo.split("@,@")[1]})`;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;

          }
        } else if (this.reportsTitle == "Maintenance Alert") {
          if (app.entryPoint != "WFT") {

            this.objfilter["Plate No"] = row.plateNo;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Additional Info"] = row.additionalInfo;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;

          } else {

            this.objfilter["Plate No"] = row.plateNo.split("@,@")[0] + `(${row.plateNo.split("@,@")[1]})`;
            this.objfilter["Alert Types"] = row.alertTypes;
            this.objfilter["Address"] = row.address;
            this.objfilter["Alert Date"] = row.date;
            this.objfilter["Alert Time"] = row.time;
            return this.objfilter;

          }
        }
        else if (this.reportsTitle == "Fleet summary report") {

          this.objfilter["Plate no"] = row["Plate No"];
          this.objfilter["Operator name"] = row["Operator Name"];
          this.objfilter["Date of purchase"] = row["Date Of Purchase"];
          this.objfilter["Date Of Vehicle Associate"] = row["Date Of Vehicle Associate"];
          this.objfilter["Location"] = row.Location;

        } else if (this.reportsTitle == "Overall summary report") {

          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Begin Date"] = row.begindate;
          this.objfilter["Begin Time"] = row.begintime;
          this.objfilter["Begin Location"] = row.beginLocation;
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["End Location"] = row.endLocation;
          this.objfilter["Max Speed"] = row.maxSpeed;
          this.objfilter["Odometer"] = row.odometer;
          this.objfilter["Running"] = row.runningDuration;
          this.objfilter["Stop"] = row.stopDuration;
          this.objfilter["Towed"] = row.towedDuration;

        } else if (this.reportsTitle == "Engine Hours summary report") {

          this.objfilter["Plate no"] = row.plateNo;
          this.objfilter["Start Date"] = row.Startdate;
          this.objfilter["Start time"] = row.Starttime;
          this.objfilter["Start location"] = row.startAddress;
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End time"] = row.endtime;
          this.objfilter["End location"] = row.stopAddress;
          this.objfilter["Odometer"] = row.odometer;
          this.objfilter["Running"] = row.runningDuration;
          this.objfilter["Idle"] = row.idleDuration;

        } else if (this.reportsTitle == "Productivity Report") {

          this.objfilter["Plate No"] = row.PlateNo;
          this.objfilter["Actual Working Hours"] = row["Actual WorkingHours"];
          this.objfilter["Percentage"] = row.Percentage;
          this.objfilter["Working Days"] = row["scheduled WorkingDays"];
          this.objfilter["Scheduled WorkingHours"] = row["scheduled WorkingHours"];

        } else if (this.reportsTitle == "Maintenance report") {

          this.objfilter["Asset Id"] = row['Asset Id'];
          this.objfilter["Asset Type"] = row['Asset Type'];
          this.objfilter["Current Hours(HH:MM)"] = row['Current Hours (HH:MM)'];
          this.objfilter["Preventive Maintenance Interval(HH:MM)"] = row['Preventive Maintenance Interval (HH:MM)'];
          this.objfilter["Last PM Hours(HH:MM)"] = row['Last PM Hours (HH:MM)'];
          this.objfilter["Last PM Time"] = row['Last PM Time'];

        } else if (this.reportsTitle == "Driver behaviour report") {

          this.objfilter["Plate No"] = row.plateNo;
          this.objfilter["Harsh Break"] = row.HARSHBRAKING;
          this.objfilter["Seat Belt"] = row.SEATBELT;
          this.objfilter["Harsh Acceleration"] = row.HARSHACCELERATION;
          this.objfilter["Drift"] = row.DRIFT;
          this.objfilter["Travelled KM"] = row.distanceTravelled;
          this.objfilter["Engine On"] = row.engineOnDuration;
          this.objfilter["Running"] = row.runningDuration;
          this.objfilter["Idle"] = row.excessiveIdling;
          this.objfilter["AvgSpeed"] = row.avgSpeed;
          this.objfilter["Top Speed"] = row.topSpeed;
          this.objfilter["Fuel Consumption"] = row.fuelConsumption;
          this.objfilter["Over All Score"] = row.overAllScore;

        } else if (this.reportsTitle == "Stop report") {

          this.objfilter["Plate no"] = row.plateNo;
          this.objfilter["Started Again At Date"] = row.begindate;
          this.objfilter["Started Again At Time"] = row.begintime;
          this.objfilter["Stop At Date"] = row.enddate;
          this.objfilter["Stop At Time"] = row.endtime;
          this.objfilter["Duration"] = row.stopDur;
          this.objfilter["Location"] = row.endLocation;

        } else if (this.reportsTitle == "No transmission report (WFT)") {

          this.objfilter["Employee id"] = row.employeeId;
          this.objfilter["Employee name"] = row.employeeName.replaceAll('@,@', "(") + ")";
          this.objfilter["Work zone"] = row.zoneName;
          this.objfilter["Date"] = row.date;
          this.objfilter["Slot"] = row.userId;


        }
        else if (this.reportsTitle == "No transmission report") {

          if (app.entryPoint == "ATM") {

            this.objfilter["Plate No"] = row.AtmId;
            this.objfilter["Model"] = row.ModelName;
            this.objfilter["Address"] = row.Address;
            this.objfilter["IMEI No"] = row.imeiNo;
            this.objfilter["Sim Card No"] = row.simNo;
            this.objfilter["Last Transmission"] = row.lastTransmission;
            this.objfilter["Manufacturer"] = row.ManufacturerName;
            this.objfilter["Date Of Purchase"] = row.DateOfpurchase;

          } else {

            this.objfilter["Plate No"] = row.plateNumber;
            this.objfilter["Last Transmission Date"] = row.lastTransTimedate;
            this.objfilter["Last Transmission Time"] = row.lastTransTimetime;
            this.objfilter["Manufacturer"] = row.ManufacturerName;

          }

        } else if (this.reportsTitle == "Alert MailReport") {

          if (app.entryPoint == "ATM") {

            this.objfilter["ATM ID"] = row.PlateNo;
            this.objfilter["AlertType"] = row.alertType;
            this.objfilter["Date"] = row.Date;
            this.objfilter["Email1"] = row.Mail1;
            this.objfilter["Email1 Status"] = row.Mail1Result;
            this.objfilter["Email2"] = row.Mail2;
            this.objfilter["Email2 Status"] = row.Mail2Result;

          } else {

            this.objfilter["Plate No"] = row.PlateNo;
            this.objfilter["AlertType"] = row.alertType;
            this.objfilter["Date"] = row.Date;
            this.objfilter["Email1"] = row.Mail1;
            this.objfilter["Email1 Status"] = row.Mail1Result;
            this.objfilter["Email2"] = row.Mail2;
            this.objfilter["Email2 Status"] = row.Mail2Result;

          }
        } else if (this.reportsTitle == "Fuel Consumption Report") {

          this.objfilter["Plate No"] = row.plateno;
          this.objfilter["Operator Name"] = row.operatorname;
          // this.objfilter["Date"] = row.fromdate;
          this.objfilter["Begin Date and Time"] = row.begin;
          this.objfilter["Begin Location"] = row.beginlocation;
          this.objfilter["End Date and Time"] = row.end_time;
          this.objfilter["End Location"] = row.endlocation;
          this.objfilter["Mileage Per Liter"] = row.expectedmileage;
          this.objfilter["Avg Fuel Consuption"] = row.avgMileage;
          this.objfilter["Odometer"] = row.odometer;

        }
        else if (this.reportsTitle == "GSMLOST Report") {

          this.objfilter["Plate No"] = row.AtmId;
          this.objfilter["Model"] = row.ModelName;
          this.objfilter["Address"] = row.Address;
          this.objfilter["IMEI No"] = row.imeiNo;
          this.objfilter["Sim Card No"] = row.simNo;
          this.objfilter["Last Transmission"] = row.lastTransmission;
          this.objfilter["Manufacturer"] = row.ManufacturerName;
          this.objfilter["Date Of Purchase"] = row.DateOfpurchase;

        } else if (this.reportsTitle == "User Activity Report") {

          this.objfilter["Company Id"] = row.companyId;
          this.objfilter["Name"] = row.name;
          this.objfilter["Type"] = row.type;
          this.objfilter["Old Value"] = row.oldValue;
          this.objfilter["Primary Data"] = row.primaryData;
          this.objfilter["MAC Ip"] = row.macIp;
          this.objfilter["Last Updated Date"] = row.lastUpdatedDate;
          this.objfilter["Last Updated By"] = row.lastUpdatedBy;


        } else if (this.reportsTitle == "SKT Alerts Report") {

          this.objfilter["Plate NO"] = row.plateno;
          this.objfilter["Student Name"] = row.studentname;
          this.objfilter["Parent Name"] = row.parentname;
          this.objfilter["Date"] = row.date;
          this.objfilter["Alert Type"] = row.alerttype;
          this.objfilter["Description"] = row.des;
          this.objfilter["Address"] = row.address;
          this.objfilter["Location"] = row.location;
          this.objfilter["Oerator Name"] = row.operatorname;


        } else if (this.reportsTitle == "User Logs Report") {

          this.objfilter["User Id"] = row.userId;
          this.objfilter["Role"] = row.role;
          this.objfilter["MAC Ip"] = row.macIp;
          this.objfilter["Login Time"] = row.loginTime;
          this.objfilter["Logout Time"] = row.logoutTime;

        } else if (this.reportsTitle == "Utilization Report") {

          this.objfilter["Plate No"] = row.plateno;
          this.objfilter["Total Selected Days"] = row.totalDays;
          this.objfilter["Total Hours"] = row.totalHours;
          this.objfilter["Used Hours"] = row.used;
          this.objfilter["Unused Hours"] = row.unused;
          this.objfilter["Used Percentage(Running&Idle)"] = row.percentage;
          this.objfilter["Unused Percentage(Stop)"] = row.unusedpercentage;

        } else if (this.reportsTitle == "Status Summary Report") {

          this.objfilter["ATM ID"] = row.plateNo;
          this.objfilter["Begin At"] = row.begin;
          this.objfilter["End At"] = row.end;
          this.objfilter["From Date"] = row.fromDate;
          this.objfilter["To Date"] = row.toDate;
          this.objfilter["Status"] = row.status;

        } else if (this.reportsTitle == "Movement Report") {

          this.objfilter["ATM ID"] = row.atmID;
          this.objfilter["Begin At"] = row.begin;
          this.objfilter["End At"] = row.end;
          this.objfilter["Begin Location"] = row.beginLocation;
          this.objfilter["End Location"] = row.endLocation;
          this.objfilter["Duration"] = row.moveDur;

        }
        else if (this.reportsTitle == "Idle violation report") {

          this.objfilter["Plate No"] = row["Plate No"];
          this.objfilter["Begin Date"] = row["begindate"];
          this.objfilter["Begin Time"] = row["begintime"];
          this.objfilter["Begin At"] = row["Begin At"];
          this.objfilter["End Date"] = row.enddate;
          this.objfilter["End Time"] = row.endtime;
          this.objfilter["End At"] = row['End At'];
          this.objfilter["Idle"] = row.Idle;

        } else if (this.reportsTitle == "Vehicle movement report") {

          this.objfilter["Plate no"] = row.plateNo;
          this.objfilter["Event Date"] = row.date;
          this.objfilter["Event Time"] = row.time;
          this.objfilter["Status"] = row.status;
          this.objfilter["Day odometer"] = row.ioEvent;
          this.objfilter["Location"] = row.address;

        } else if (this.reportsTitle == "Recurrence") {

          this.objfilter["Status"] = row.status;
          this.objfilter["Created On Date"] = row.createdondate;
          this.objfilter["'Created On Time"] = row.createdontime;
          this.objfilter["Type"] = row.type;
          this.objfilter["Plate No"] = row.plateNoCon;
          this.objfilter["Email Address"] = row.emailAddress;

        }

        else if (this.reportsTitle == "Operator Report") {

          this.objfilter["Plate No"] = row.PlateNo;
          this.objfilter["Trip-Start Date"] = row.tripStartdate;
          this.objfilter["Trip-Start Time"] = row.tripStarttime;
          this.objfilter["Trip-Stop Date"] = row.tripStopdate;
          this.objfilter["Trip-Stop Time"] = row.tripStoptime;
          this.objfilter["Duration"] = row.duration;
          this.objfilter["Operator Name"] = row.operatorName;

        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vg')) {
          this.objfilter["Group"] = row.vg;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('voc')) {
          this.objfilter["Vehicle Operation code"] = row.voc;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('on')) {
          this.objfilter["Operator"] = row.on;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('in')) {

          this.objfilter["IMEI No"] = row.in;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('sn')) {
          this.objfilter["SIM No"] = row.sn;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dm')) {
          this.objfilter["Device Make"] = row.dm;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dmn')) {
          this.objfilter["Device Model"] = row.dmn;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dp')) {
          this.objfilter["Date of Purchase"] = row.dp;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('iu')) {
          this.objfilter["Asset category"] = row.iu;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vm')) {
          this.objfilter["Vehicle Model"] = row.vm;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vt')) {
          this.objfilter["Vehicle type"] = row.vt;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('vy')) {
          this.objfilter["Vehicle Year"] = row.vy;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('gc')) {
          this.objfilter["Color"] = row.operatorName;
        }
        if (this.filterRows[0] && this.filterRows[0].hasOwnProperty('dbm')) {
          this.objfilter["Debugging Mode"] = row.dbm;
        }
        return this.objfilter;
      });
      if (this.reportsTitle == "Work summary report" || this.reportsTitle == "Driver behaviour report") {
        this.commonService.createPdf(this.head, this.objfilter, filteredData, this.reportsTitle, "", this.reportsTitle, this.selectedDate);
      } else {
        this.commonService.createPdf(this.head, this.objfilter, filteredData, this.reportsTitle, "", this.reportsTitle, this.selectedDate);
      }
    } else {
      if (this.reportsTitle == "Work summary report" || this.reportsTitle == "Driver behaviour report") {
        this.commonService.createPdf(this.head, this.odj, null, this.reportsTitle, "", this.reportsTitle, this.selectedDate);
      } else {
        this.commonService.createPdf(this.head, this.odj, null, this.reportsTitle, "", this.reportsTitle, this.selectedDate);
      }
    }

  }
  ngAfterViewInit() {

  }
  getData() {
    this.tableData = companyReportData.reportData
    this.tableData = this.objValues;
  }

  getRoutetype() {
    const companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    var url = serverUrl.web + `/routetrip/getRoutename?compId=${companyDetail.companyID}&branchId=${companyDetail.branchID}`;
    this.ajaxService.ajaxGet(url).subscribe(res => {
      this.routetype = res;
    })

  }
  deselectfrista(event) {
    if (event.detail.checked) {
      this.dateList.forEach(res => {
        if (res.day == 'Friday' || res.day == 'Saturday')
          res.selected = false
      })
    } else {
      this.dateList.forEach(res => {
        if (res.day == 'Friday' || res.day == 'Saturday')
          res.selected = true
      })
    }
  }
  generateDateList() {
    this.dateList = [];
    let currentDate = new Date(this.reports.value.fromDate.split(" ")[0]);
    while (currentDate <= new Date(this.reports.value.toDate.split(" ")[0])) {
      this.dateList.push({
        date: new Date(currentDate),
        day: this.getDayName(currentDate),
        selected: true
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  getDayName(date: Date): string {
    const options = { weekday: 'long' } as const;
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  ngOnInit() {
    if (window.location.hash == "#/reports/null") {
      this.plateText = false;
    }
    this.reportList = this.allReportItem;
    // this.reportList = ["Utilization Report"];
    console.log("this.reportList", this.reportList);

    this.entryPoint = app.entryPoint;
    this.odj = [];
    this.company = localStorage.corpId
    this.getData();
    this.page = localStorage.getItem('pageSelector');

    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res;
      });

  }

  reportChange(x) {
    this.parameter = x.target.value;
    this.reportEmitter.emit(this.parameter);
    this.commonService.filter = undefined;
    this.notrnsdateis = false
    this.reports.value.stopdur = true;
    this.reports.value.idledur = false;
    if (this.parameter == "No transmission report" || this.parameter == "GSMLOST Report") {
      if (this.entryPoint == "ATM") {
        setTimeout(() => {
          this.shownContent.datefilter = true
          this.reportsContent.datefilter = true
        }, 10);

      }
    }
    if (this.parameter == "User Activity Report") {
      if (this.entryPoint == 'ATM') {
        this.datatype = [{ label: "Select All", value: "All" }, ...[
          { label: "ATM", value: "Vehicle" },
          'Device',
          'SimCard',
          'Geo-Configuration',
          'Geo-Association',
          'User',
          'Alert',
          'Profile',
          'UserFeatures',
          'initialpage',
          'Theme',
        ]]
      } else {
        this.datatype = [{ label: "Select All", value: "All" }, ...[
          { label: "Vehicle", value: "Vehicle" },
          'Device',
          'SimCard',
          'Operator',
          'Geo-Configuration',
          'Geo-Association',
          'User',
          'Alert',
          'Profile',
          'UserFeatures',
          'Immobilize',
          'HourMeterReset',
          'OdometerReset',
          'initialpage',
          'Theme',
        ]]
      }


    }

  }


  renderedItem(event, items, list) {
    this.commonService.renderedItem(event, items, list);
  }
  toggle() {
    this.hide = !this.hide
    this.checkboxes.map((res: any) => {
      (this.checkedValues.includes(res.value)) ? res.checked = true : res.checked = false;
    });
  }

  check(event: any, checkbox: any) {
    if (checkbox.value === 'all') {
      if (event.detail.checked) {
        this.checkedValues = this.checkboxes
          .filter(item => item.value !== 'all')
          .map(item => item.value)
          .join(',');
        this.checkboxes.map(item => (item.checked = true));
      } else {
        this.checkedValues = '';
        this.checkboxes.map(item => (item.checked = false));
      }
    } else {
      if (event.detail.checked) {
        const values = this.checkedValues.split(',');
        if (!values.includes(checkbox.value)) {
          if (this.checkedValues === '') {
            this.checkedValues = checkbox.value;
          } else {
            this.checkedValues += ',' + checkbox.value;
          }
        }
      } else {
        const valueToRemove = checkbox.value;
        this.checkedValues = this.checkedValues
          .split(',')
          .filter(item => item !== valueToRemove)
          .join(',');
      }
    }
  }
  checkdate(event) {
    if (event.detail.checked) {
      this.notrnsdateis = true
      this.shownContent.fromDate = true
      this.shownContent.toDate = true
      this.shownContent.fromTime = true
      this.shownContent.toTime = true
      this.reportsContent.fromDate = true
      this.reportsContent.toDate = true
      this.reportsContent.fromTime = true
      this.reportsContent.toTime = true
    } else {
      this.notrnsdateis = false
      this.reportsContent.fromDate = false
      this.reportsContent.toDate = false
      this.reportsContent.fromTime = false
      this.reportsContent.toTime = false
      this.shownContent.fromDate = false
      this.shownContent.toDate = false
      this.shownContent.fromTime = false
      this.shownContent.toTime = false
    }
  }
  checkvalues() {
    if (this.entryPoint != "ATM") {
      if (this.reportsTitle == "No transmission report") {
        this.checkboxes.map((res: any) => {
          if (res.id == 4 || res.id == 5 || res.id == 6 || res.id == 7 || res.id == 8 || res.id == 9) {
            res.checked = true
            this.checkedValues += this.checkedValues.length === 0 ? res.value : ',' + res.value;
          } else {
            res.checked = false
          }
        });
      }
      else if (this.reportsTitle == "Alert report" || this.reportsTitle == "Fence Report" || this.reportsTitle == "Maintenance Alert" || this.reportsTitle == "Vehicle movement report") {
        this.checkboxes.map((res: any) => {
          if (res.id == 4) {
            res.checked = true
            this.checkedValues += this.checkedValues.length === 0 ? res.value : ',' + res.value;
          } else {
            res.checked = false
          }
        });
      } else if (this.reportsTitle == "Status summary report") {
        this.checkboxes.map((res: any) => {
          if (res.id == 2) {
            res.checked = true
            this.checkedValues += this.checkedValues.length === 0 ? res.value : ',' + res.value;
          } else {
            res.checked = false
          }
        });
      } else if (this.reportsTitle == "Consolidate summary report" || this.reportsTitle == "Consolidate summary report" || this.reportsTitle == "Driver behaviour report") {
        this.checkboxes.map((res: any) => {
          if (res.id == 2 || res.id == 4) {
            res.checked = true
            this.checkedValues += this.checkedValues.length === 0 ? res.value : ',' + res.value;
          } else {
            res.checked = false
          }
        });
      }
      else {
        this.checkboxes.map((res: any) => {
          res.checked = false
        });
      }
    }
  }

  datereport() {
    this.unselectdate = []
    let data = this.unselectnew.getCheckedItems()
    data.map(res => {
      this.unselectdate.push(res.originalItem)
    })


  }
  rendereddate() {
    if (this.reportsTitle == "Productivity Report") {
      const fromDate = new Date(this.reports.value.fromDate);
      const toDate = new Date(this.reports.value.toDate);
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);
      const dateList: string[] = [];
      this.unselectdate = [];
      startDate.setDate(startDate.getDate() + 1);
      while (startDate < endDate) {
        const day = startDate.getDate().toString().padStart(2, '0');
        const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
        const year = startDate.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        dateList.push(formattedDate);
        startDate.setDate(startDate.getDate() + 1);
      }
      this.unselect = [...dateList];
      return dateList;
    }
  }

}
